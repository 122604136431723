import { types } from 'mobx-state-tree';
import { Model } from '../utils/mobx-model-helper';
import { getBanners } from '../api/home';

const TYPES = {
  GET_BANNERS: 1,
};

const Banner = types.model('Banner').props({
  id: types.maybeNull(types.number),
  backgroundImage: types.maybeNull(types.string),
  mobileBackground: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
});

const BannersStore = Model.named('BannersStore')
  .props({
    banners: types.maybeNull(types.array(Banner)),
  })
  .actions(self => ({
    getBanners(payload) {
      return self.request({
        type: TYPES.GET_BANNERS,
        api: getBanners,
        payload,
        onSuccess: result => {
          self.banners = result?.result;
        },
      });
    },
  }));

export { TYPES };
export default BannersStore.create({
  banners: [],
});
