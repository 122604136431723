import Storage from './storage';
import Request from './request';
import authStore from '../store/auth';
import { history } from '../store';
import Web3 from './web3other';
import Misc from './misc';
import { CHAIN_LIST } from '../constants/chains';

export const logout = async () => {
  if (!localStorage.getItem('Web3Auth-cachedAdapter')) {
    if (Misc.isMobile) {
      Web3.removeWeb3Instance();
    }
  }

  Storage.remove('ACCESS_TOKEN');
  Storage.remove('USER_ID');
  Storage.remove('PUBLIC_ADDRESS');
  Storage.remove('HIDE_BANNER');
  Storage.remove('walletconnect');
  Storage.remove('WEB3_CONNECT_CACHED_PROVIDER');
  Storage.remove('Web3Auth-cachedAdapter');
  Storage.remove('openlogin_store');
  Request.removeAccessToken();

  authStore.logout();
  history.replace('/');
  if (Storage.get('LONGIN_BY') === 'METAMASK') {
    window.location.reload()
  }
};

export const getNetworksEnv = env => Object.values(CHAIN_LIST).find(item => item.env?.includes(env))?.env;

export const getNetworks = env => Object.values(CHAIN_LIST).filter(item => item.env?.includes(env));

export const getNetworkSelected = (env, chainIdSelected) => {
  const networks = getNetworks(env);
  
  return chainIdSelected ? networks.find(item => item.chainId === +chainIdSelected) || networks[0] : networks[0];
};
