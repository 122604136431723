import React, { useContext } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import { MobXProviderContext } from 'mobx-react';
import Thumbnail from '@/components/thumbnail';
import { Images } from '@/theme';
import { useHistory } from 'react-router-dom';

const ModalNoti = styled.div`
  background-color: white;
  position: absolute;
  top: 50px;
  max-width: 420px;
  width: 370px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  right: -20px;
  padding: 10px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  .block_contain {
    display: flex;
    flex-direction: column;
    z-index: 1;

    .title {
      font-size: 22px;
    }

    .block_noti {
      display: flex;
      cursor: pointer;
      border-radius: 10px;
      transition: 0.3s;
      .avatar {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .content {
        display: flex;
        flex-direction: column;
        .content_title {
          font-size: 18px;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

const ModalNotification = props => {
  const { hanldleRedirectPage } = props;
  const history = useHistory()
  
  const [loading, setLoading] = React.useState(false);

  const { notifications: notificationsStore } = useContext(MobXProviderContext);

  const { notifications } = notificationsStore;

  React.useEffect(() => {
    (async () => {
      setLoading(true);

      setTimeout(async () => {
        await notificationsStore.getListNotifications();
        setLoading(false);
      }, 1000);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoDetailNotification = async (id) => {
    await hanldleRedirectPage()
    
    await notificationsStore.getNotificationDetail(id)
    history.push(`/notification/notification-tab/${id}`)
  }
  
  const renderListNotification = () => {
    return notifications.items.map(item => {
      return (
        <div className="block_noti" onClick={() => gotoDetailNotification(item.id)} key={item.id}>
          <div className="avatar">
            <Thumbnail rounded placeholderUrl={Images.USER_PLACEHOLDER} />
            <div className="type_category">{item.category}</div>
          </div>
          <div className="content">
            <div className="content_title">{item.title}</div>
            <div>def def def def defdefdefdef defdefdef</div>
          </div>
        </div>
      );
    });
  };
  const renderSkeleton = () => {
    const listSkeleton = [1, 2, 3, 4, 5, 6];
    return listSkeleton.map((item) => (
      <Skeleton
        key={item}
        avatar
        active 
        paragraph={{
          rows: 1,
        }}
      />
    ));
  };

  return (
    <ModalNoti>
      <div className="block_contain">
        <div className="title">Notification</div>
        {!loading ? renderListNotification() : renderSkeleton()}
      </div>
    </ModalNoti>
  );
};

export default ModalNotification;
