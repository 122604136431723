import lodash from 'lodash';
import copy from 'copy-to-clipboard';

// import Configs from '../configs';
import Toast from '../components/toast';

let isMobile = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  isMobile = true;
}

function isFetchError(error) {
  return !!error && lodash.hasIn(error, 'status') && lodash.isFunction(error.json);
}

async function getFetchError(error) {
  try {
    return await error.json();
  } catch (e) {
    return null;
  }
}

export default class Misc {
  static trimObjectProperties = (obj, properties) => {
    const data = lodash.cloneDeep(obj);

    if (lodash.isArray(properties)) {
      properties.forEach(property => {
        data[property] = data[property]?.trim();
      });
    } else {
      lodash.keys(obj).forEach(key => {
        data[key] = data[key]?.trim();
      });
    }

    return data;
  };

  static getImageURL = name => name && `${process.env.REACT_APP_API_URL}/${name}`;

  static getErrorJsonBody = async error => {
    if (isFetchError(error)) {
      error = await getFetchError(error);
    }

    return error;
  };

  static insert = (array, index, value) => array.slice(0, index).concat(value).concat(array.slice(index, array.length));

  static trimPublicAddress = (string, numberOfCharacter) =>
    string &&
    `${string.slice(0, numberOfCharacter)} . . . ${string.slice(string.length - numberOfCharacter, string.length)}`;

  static trimString = (string, numberOfCharacter) => {
    return string?.length > numberOfCharacter ? `${string.slice(0, numberOfCharacter)} . . . ` : string;
  };

  static copyToClipboard = (value, message) => {
    copy(value);

    Toast.show(message);
  };

  static isMobile = isMobile;

  static IsSafari = () => {
    var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    return is_safari;
  }

  static checkNotMetamaskBrowser = () => {
    // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isOpera = false;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    const isChrome = !!window.chrome;

    // Blink engine detection

    if (isOpera || isFirefox || isSafari || isIE || isEdge || isChrome) {
      return true;
    }

    return false;
  };

  static getUrlRakuza = () => {
    let url = ''
    switch (process.env.REACT_APP_ENV) {
      case 'development':
        url = 'https://dev.rakuza.io/ja/'
        break;
      case 'staging':
        url = 'https://stg.rakuza.io/ja/'
        break;
      default:
        url = 'https://rakuza.io/ja/'
    }
    return url
  };
}

