import { MainApi, MainApiRakuza } from './endpoint'

export function getListCreator(payload) {
  return MainApi.get('/user/creator/top', payload)
}

export function getCreatorDetail({ creatorId, ...payload }) {
  return MainApi.get(`/user/creator/${creatorId}`, payload)
}

export function getListCreatorsRakuza(payload) {
  return MainApiRakuza.get('/user/creators', payload)
}
