import React from 'react';
import { ButtonStyled, CheckboxStyled, PolicyStyled, Step1Styled, TopTextStyled, WrapDivStyled } from './styled';
import { Form } from 'antd';
import About from './utils/about';

export default function Step1({ formik }) {
  // const valueStep1 = {
  //   isChecked: false,
  // };
  const { values, setFieldValue } = formik;

  return (
    <Step1Styled>
      <TopTextStyled className="normal">楽座DAOの利用規約・会則をご確認ください。</TopTextStyled>

      <PolicyStyled>
        <About />
      </PolicyStyled>

      <WrapDivStyled>
        <Form.Item wrapperCol={{ span: 24 }} label="" name="isChecked">
          <CheckboxStyled checked={!!values.isChecked} onChange={e => setFieldValue('isChecked', e.target.checked)}>
            利用規約に同意する
          </CheckboxStyled>
        </Form.Item>
      </WrapDivStyled>

      <WrapDivStyled>
        <ButtonStyled disabled={!values.isChecked} onClick={() => setFieldValue('step', values.step + 1)}>
          情報登録へ
        </ButtonStyled>
      </WrapDivStyled>
    </Step1Styled>
  );
}
