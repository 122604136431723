import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import {
  getUserNonce,
  verifySignature,
  login,
  getInitialData,
  updateAccelerationRate,
  fetchPrefecture,
  web3authlogin,
  postMemberRegister,
  getBalanceNftByTokenId,
  getMember,
  updateUserMember,
  deleteMember
} from '../api/auth';

const TYPES = {
  GET_USER_NONCE: 1,
  VERIFY_SIGNATURE: 2,
  LOGIN: 3,
  GET_INITIAL_DATA: 4,
  UPDATE_ACCELERATION_RATE: 5,
  WEB3AUTH_LOGIN: 6,
  FETCH_PREFECTURE: 7,
  POST_REGISTER_MEMBER: 8,
  GET_BALANCE_NFT_BY_TOKEN_ID: 9,
  UPDATE_USER_MEMBER: 11,
  DELETE_USER_MEMBER: 12,
};

const Creator = types.model('Creator').props({
  id: types.maybeNull(types.number),
  nftContractAddress: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  featuredProductNumber: types.maybeNull(types.number),
  accelerationRate: types.maybeNull(types.number),
  accelerationRateCreator: types.maybeNull(types.number),
});

const UserProducts = types.model('UserProducts').props({
  id: types.maybeNull(types.number),
  productId: types.maybeNull(types.number),
  quantity: types.maybeNull(types.number),
  userId: types.maybeNull(types.number),
});

const Member = types.model('Member').props({
  age: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  nickname: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  prefecture: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  prefectureId: types.maybeNull(types.number),
  code: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const Product = types.model('Product').props({
  productId: types.maybeNull(types.number),
  buyerId: types.maybeNull(types.number),
  sellerId: types.maybeNull(types.number),
  transactionHash: types.maybeNull(types.string),
  contractTransactionHash: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  transactionFee: types.maybeNull(types.string),
  transactionData: types.maybeNull(types.string),
  publicKey: types.maybeNull(types.string),
  errrorMessage: types.maybeNull(types.string),
  transactionTokenHash: types.maybeNull(types.string),
  productPrice: types.maybeNull(types.number),
  errorTokenMessage: types.maybeNull(types.string),
  lockFlg: types.maybeNull(types.boolean),
  type: types.maybeNull(types.string),
  tokenTransactionFee: types.maybeNull(types.string),
  tokenGasPrice: types.maybeNull(types.string),
  productYenFrice: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  commissionFee: types.maybeNull(types.string),
  yenCommissionFee: types.maybeNull(types.string),
  sendMoneyTransactionHash: types.maybeNull(types.string),
  artistCommissionFee: types.maybeNull(types.string),
  artistCommissionRate: types.maybeNull(types.string),
  nonce: types.maybeNull(types.string),
  blockNumber: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number)
});

const InitialData = types.model('InitialData').props({
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  publicAddress: types.maybeNull(types.string),
  ethBalance: types.maybeNull(types.number),
  wethBalance: types.maybeNull(types.number),
  usdtBalance: types.maybeNull(types.number),
  usdToJpy: types.maybeNull(types.number),
  wishCount: types.maybeNull(types.number),
  viewPrivateProduct: types.maybeNull(types.boolean),
  userImage: types.maybeNull(types.string),
  creator: types.maybeNull(Creator),
  nftOwnershipFlg: types.maybeNull(types.boolean),
  member: types.maybeNull(Member),
  infoReqFlg: types.maybeNull(types.boolean),
  heatCount: types.maybeNull(types.number),
  memberFlg: types.maybeNull(types.boolean),
  userProducts: types.maybeNull(types.array(UserProducts)),
  balanceTokenIdRkzDao: types.maybeNull(types.number),
  balanceTokenIdPartner: types.maybeNull(types.number),
  buyingProduct: types.maybeNull(Product),
})
.actions((self) => ({
  setDataBalance(data) {
    Object.keys(data).forEach((key) => {
      self[key] = data[key]
    })
  },

  setBuyingProduct(data) {
    self.buyingProduct = {...data}
  }

}))

const Prefecture = types.model('Prefecture').props({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  nameEn: types.maybeNull(types.string),
});

const AuthStore = Model.named('AuthStore')
  .props({
    loggedIn: types.boolean,
    currency: types.string,
    initialData: InitialData,
    prefecture: types.maybeNull(types.array(Prefecture)),
  })
  .actions(self => ({
    setLoggedIn(value) {
      self.loggedIn = value;
    },

    setCurrency(value) {
      self.currency = value;
    },

    setInitialData(data) {
      Object.keys(data).forEach(key => {
        self.initialData[key] = data[key];
      });
    },

    setNbngBalance(value) {
      self.initialData = {
        ...self.initialData,
        nbngBalance: value,
      };
    },

    setBalance({ eth, usdt, weth }) {
      self.initialData = {
        ...self.initialData,
        ethBalance: eth,
        usdtBalance: usdt,
        wethBalance: weth
      };
    },

    setWishCount(value) {
      self.initialData = {
        ...self.initialData,
        wishCount: self.initialData.wishCount + value,
      };
    },

    logout() {
      self.loggedIn = false;
      self.initialData = {
        ...self.initialData,
        userId: null,
        publicAddress: null,
        nbngBalance: 0,
      };
    },

    getUserNonce(payload) {
      return self.request({
        type: TYPES.GET_USER_NONCE,
        api: getUserNonce,
        payload,
      });
    },

    verifySignature(payload) {
      return self.request({
        type: TYPES.VERIFY_SIGNATURE,
        api: verifySignature,
        payload,
      });
    },

    login(payload) {
      return self.request({
        type: TYPES.LOGIN,
        api: login,
        payload,
      });
    },

    web3authlogin(payload) {
      return self.request({
        type: TYPES.WEB3AUTH_LOGIN,
        api: web3authlogin,
        payload,
      });
    },

    getInitialData(payload) {
      return self.request({
        type: TYPES.GET_INITIAL_DATA,
        api: getInitialData,
        payload,
        onSuccess: result => {
          if (!result) return;
          self.initialData = {
            ...self.initialData,
            ...result
            // ,
            // creator: result.creator
          };
          if ( result.buyingProduct ) {
            self.initialData.setBuyingProduct(result.buyingProduct)
          }
        },
      });
    },

    updateAccelerationRate(payload) {
      return self.request({
        type: TYPES.UPDATE_ACCELERATION_RATE,
        api: updateAccelerationRate,
        payload,
      });
    },

    fetchPrefecture(payload) {
      return self.request({
        type: TYPES.FETCH_PREFECTURE,
        api: fetchPrefecture,
        payload,
        onSuccess: result => {
          if (!result) return;
          self.prefecture = result;
        },
      });
    },

    registerMember(payload) {
      return self.request({
        type: TYPES.POST_REGISTER_MEMBER,
        api: postMemberRegister,
        payload
      });
    },

    registerMemberDraft(payload) {
      return self.request({
        type: TYPES.POST_REGISTER_MEMBER,
        api: postMemberRegister,
        payload
      });
    },

    getBalanceNftByTokenId() {
      return self.request({
        type: TYPES.GET_BALANCE_NFT_BY_TOKEN_ID,
        api: getBalanceNftByTokenId,
        onSuccess: result => {
          self.initialData = {
            ...self.initialData,
            balanceTokenIdRkzDao: result.balance.SWAP_RAKUZA_DAO,
            balanceTokenIdPartner: result.balance.SWAP_RAKUZA_OTHER
          }
        }
      })
    },

    getMember(payload) {
      return self.request({
        type: TYPES.GET_MEMBER,
        api: getMember,
        payload,
        onSuccess: result => {
          self.memberInfor = {
            ...result,
          }
        }
      });
    },

    updateUserMember(payload) {
      return self.request({
        type: TYPES.UPDATE_USER_MEMBER,
        api: updateUserMember,
        payload,
      });
    },

    deleteUserMember(payload) {
      return self.request({
        type: TYPES.DELETE_USER_MEMBER,
        api: deleteMember,
        payload,
      });
    },
  }));

export { TYPES };
export default AuthStore.create({
  loggedIn: false,
  currency: 'ETH',
  initialData: {
    ethBalance: 0,
    usdToJpy: 0,
    wishCount: 0,
    viewPrivateProduct: false,
    creator: null,
    balanceTokenIdRkzDao: 0,
    balanceTokenIdPartner: 0,
    memberFlg: false,
    member: null,
    buyingProduct: null
  },
  memberInfor: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },
  prefecture: []
});