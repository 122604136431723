import React from 'react';
import styled from 'styled-components';
const WrapDivStyled = styled.div``;
export default function About() {
  const text = `${`
  楽座DAO利用規約

      RAKUZA株式会社（以下「当社」といいます。）は、当社が提供する「楽座DAO」（以下「本サービス」といいます。）をご利用いただくにあたって楽座DAO利用規約（以下「本規約」といいます。) を以下のとおり定めます。本規約は、本サービスをご利用になるすべての方に適用されます。

      第１条（目的）
      １．楽座DAOは、価値創出の新たな経済圏の実現とともにRAKUZA NFTマーケットプレイス v2プロジェクトをはじめとする、RAKUZAのすべてのプロジェクトと連動し、楽座のさらなる発展を目的とします。
      ２．本規約は、本会を準備・運営していくにあたり、必要な基本的事項について記載しています。

      第2条（定義）
      １．本規約において使用する用語は、以下の意味を有するものとします。
      (1) 「アドレス」とは、会員権利 又はETHその他の暗号資産を管理又は保管するためのアドレスをいいます。
      (2) 「基準ETHレート」とは、個別契約に基づく本NFTの付与を開始する日の前日の午前0時00分における、当社が指定するマーケットにおけるETHと日本円との交換レートを意味します。
      (3) 「個別契約」とは、第6条第1項に規定される当社とユーザーの間で締結される売買契約をいいます。
      (4) 「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権、肖像権その他の知的財産権（これらの権利を取得し、又はこれらの権利につき登録等を出願する権利を含みます。）をいいます。
      (5) 「当社アドレス」とは、当社が、会員権の対価であるETHを受け取るために利用するアドレスをいいます。
      (6) 「当社ウェブサイト」とは、そのドメインが「rakuza.io」である当社が運営するウェブサイト（サブドメインを含み、また、理由を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）をいいます。
      (7) 「本規約等」とは、本規約、個別契約、当社が定めるプライバシーポリシーその他本サービスに関連して適用があるものとして当社が当社ウェブサイトで公表する又はユーザーに対して通知する規約、購入ガイド、利用ルール、FAQ等の総称をいいます。
      (8) 「本サービス」とは、当社が会員権を発行し、ユーザーに対してそれを販売するサービス（以下「購入サービス」といいます。）及びユーザーによる会員権及びマーケットプレイスの利用に関するサービス（以下「NFTサービス」といいます。）を総称したものをいいます。
      (9) 「ユーザー」とは、本サービスの利用者をいいます。
      (10) 「Airdrop」とは、会員権を保有するユーザーに対して、トークンや商品を無償で付与することをいいます。
      (11) 「ETH」とは、暗号資産であるイーサ（Ether）、又はその単位をいいます。
      (12)「楽座DAOメンバーシップ」とは楽座DAOメンバーとしての活動契約を表章するブロックチェーン上で発行される代替性のないトークン（Non-Fungible Token。以下「NFT」といいます。）をいいます。
      (13) 「楽座DAO MEMBER」とは、当社が定める償却期限内に当社が指定するサービスを利用することができる会員権及び楽座DAOメンバーシップ（NFT）を所有するユーザーをいいます。
      (14) 「登録料」とは、購入サービスに対する対価をいいます。
      (15) 「Mint」とは、個別契約が成立して当社がユーザーから対価を受領した後、当社が所定の楽座DAOメンバーシップを生成することをいいます。
      (16)「利用契約」とは、第３条の規定に基づき当社とユーザーの間で締結される契約を意味します。
      (17)「利用契約等」とは、利用契約及び個別契約の総称を意味します。

      第３条（本サービスの利用）
      ユーザーは、本規約等に従って、当社の定める方法に従い、本サービスを利用することができます。また、本サービスの利用の開始をもって、ユーザーの皆様は本規約等の全てに同意されたものとみなします。

      第４条（本サービスの内容等）
      １．ユーザーは、第6条に定める楽座DAOメンバーシップ（NFT）の個別契約後、本規約への同意とともに、楽座DAOへの入会が認められます。
      ２．当社は、楽座DAOメンバーシップ（NFT）の購入を希望するユーザーが、第14条各号のいずれかに該当し、又は該当するおそれがあると当社が判断する場合その他当社が楽座DAOメンバーシップ（NFT）の販売を拒否することが合理的であると判断する場合は、楽座DAOメンバーシップ（NFT）の送付を拒否することができます。
      ３．楽座DAOメンバーシップ（NFT） が万が一、移転が生じた場合、当社として一切の責任を負わないものとします。ただし、当社に故意又は重大な過失がある場合を除きます。
      ４．当社は楽座DAOメンバーシップ（NFT）の販売を行います。楽座DAOメンバーシップ（NFT）の販売は不定期に行われ一定期間で販売が終了します。ユーザーは購入したい楽座DAOメンバーシップ（NFT）販売に参加し、対価を支払うことで楽座DAOメンバーシップ（NFT）を購入することができます。なお、楽座DAOメンバーシップ（NFT）販売で購入した楽座DAOメンバーシップ（NFT）には、当社が別途定める売却制限期間があります。
      ５．楽座DAOメンバーシップ（NFT）の種別に応じ、販売価格と配布時期が異なります。
      ６．楽座DAOメンバーシップ（NFT）は本サービス内で譲渡することができます。
      ７．楽座DAOメンバーシップ（NFT）を譲渡したユーザーは、当該楽座DAOメンバーシップを譲受人に移転した時点で、当該楽座DAOメンバーシップが表章する権利を失うものとします。ただし、楽座DAOメンバーシップの譲渡時に、当該ユーザーが楽座DAOメンバーシップのAirdropにて、獲得済み及び既に保有している商品については、この限りではありません。
      ８．楽座DAOメンバーシップ（NFT）が表章する権利は、楽座DAOメンバーシップ（NFT）と切り離して譲渡することはできません。ユーザーが、楽座DAOメンバーシップ（NFT）が表章する権利を、楽座DAOメンバーシップ（NFT）と切り離して譲渡しようとした場合、その時点で当該楽座DAOメンバーシップ（NFT）は無効となり、その表章する権利は消滅するものとします。
      ９．楽座DAOメンバーシップ（NFT）の譲渡に関して、法令又は本規約等に違反する行為があった場合又は著しく不適切な行為があったと当社が判断した場合、当社は、当該楽座DAOメンバーシップ（NFT）及びその表章する権利を停止、制限又は消滅させることがあります。また、本項に基づく措置によって譲渡人又は譲受人に生じる損害等について、当社の故意又は重大な過失に基づく場合を除き、当社は一切責任を負わないものとします。
      10．楽座DAOメンバーシップ（NFT）の売買は現行の法令又は将来の法改正等によって課税対象となる可能性があります。譲渡人及び譲受人はこのことを十分に理解し、自己の責任と負担において楽座DAOメンバーシップ（NFT）の売買を行うものとします。なお、当社は、課税対象となるか否かを含む課税に関する事項についてアドバイスの提供等一切の行為を行っておりません。課税に関する疑義が発生した場合は、譲渡人及び譲受人は自らの責任と負担においてご自身で決定していただくか又は専門家に判断を仰いでいただきますようお願いいたします。
      11．楽座DAOメンバーシップ（NFT）はこれを保有するユーザーに対して何らの権利を付与・保証するものではなく、楽座DAOメンバーシップ（NFT）を当社に提示等することによって何らかのサービス提供を受けることができるものでもありません。楽座DAOメンバーシップ（NFT）は、あくまで当社とユーザーの間のコミュニケーションを促進するための本サービスの機能を利用することができることを示す証に過ぎず、特典が提供される場合においても、当社の事業から生ずる収益の配当または財産の分配を楽座DAOメンバーシップ（NFT）を保有するユーザーに対して行うものではなく、当社からの感謝の気持ちを表す行為・グッズ・サービス等を提供するにとどまります。
      12．当社は、あらかじめ設定した数の楽座DAOメンバーシップ（NFT）を保有するユーザーに対する特典を設定することができます。ただし、特典は楽座DAOメンバーシップ（NFT）購入の対価ではなくあくまでサービスの一環です。特典の内容については必ずしも当該設定どおりに提供されない場合があります。
      13．ユーザーは楽座DAOメンバーシップ（NFT）の種類等に応じて当社が設定するプロジェクトに参加することができます。プロジェクトに参加した対価等については、別途当社が定めるところによるものとします。
      14．楽座DAOメンバーシップ（NFT）は、株式を含む有価証券、前払式支払手段、法定通貨または暗号資産（仮想通貨）いずれでもありません。そのため、本規約等に基づいて本サービスにおいて利用する以外には、一切ご利用いただけません。
      15．ユーザーは、楽座DAOメンバーシップ（NFT）を返還することで退会が可能です。しかし、ユーザーが楽座DAOメンバーシップ（NFT）の対価として当社に送付したETH又は支払った金銭の返還を求めることはできません。

      第５条（特典）
      １．楽座DAOメンバーシップ（NFT）の販売時、特典が提供される場合があります。ただし、特典の有無やその内容、特典を受ける条件等は事後的に変更される可能性があることにご留意ください。
      ２．楽座DAOメンバーシップ（NFT）の保有数等を基準としたユーザーに対する特典が設定されている場合があります。ただし、特典の有無やその内容、特典を受ける条件等は事後的に変更される可能性があることにご留意ください。

      第６条（アカウント情報の管理等）
      1. ユーザーは、自己の責任において、本サービスにおけるウォレットに関する情報（以下「ウォレット情報」といいます。）を管理及び保管するものとし、当社が定める場合を除き、これらを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
      2. ウォレット情報の管理不十分（ウォレット情報の紛失又は失念等を含みますがこれらに限られません。）、使用上の過誤、第三者の使用、ユーザーの管理するコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等に対する第三者による攻撃（マルウェア、スパイウェア、ウィルス、ハッキング、クラッキング等を含みますがこれらに限られません。）等による損害の責任はユーザーが負うものとし、当社は一切の責任を負いません。
      ３．本サービスの利用には、NFTの保管機能を提供するウォレットなど、当社以外の事業者が提供するサービスの利用が必要となります。これらのサービスについて、当社がユーザーのために参考情報として紹介する場合がありますが、当社は、当社以外の事業者により提供されるサービスについて、一切の責任を負わないものとします。ただし、当社に故意又は重大な過失がある場合は、この限りではないものとします。

      第７条（当社の義務）
      当社の利用契約等上の義務は、ユーザーに対して楽座DAOメンバーシップ（NFT）を付与すること、すなわちユーザーが楽座DAOメンバーシップ（NFT）を保有することに関して、ブロックチェーン上に一定の記録を行うことのみであり、かかる義務以外に、何らの義務を負うものではありません。また、当社は、ユーザーに対し、楽座DAOメンバーシップ（NFT）を付与することを除き、所有権、持分、株式、一定の収益を受ける権利その他のこれらに類する権利、知的財産権、その他の当社に対するいかなる種類の権利についても、付与するものではありません。

      第８条（個別契約の成立及び本NFTの付与）
      1. ユーザーは、楽座DAOメンバーシップ（NFT）の購入を申し込むに当たり、自らの費用と責任により、楽座DAOメンバーシップ（NFT）の受領用アドレス、返却用アドレス及び送付用アドレスを準備し、保有するものとします。
      2. ユーザーは、楽座DAOメンバーシップ（NFT）の購入を申し込む場合、当社が指定する方法で、購入を希望する楽座DAOメンバーシップ（NFT）について当社が指定する情報を当社に通知するものとし、当社が当該申込みを承諾することによって、本規約に基づく当該楽座DAOメンバーシップ（NFT）の付与に関する契約（以下「個別契約」といいます。）が成立するものとします。
      3. ユーザーは、個別契約の成立後、当社が別途定める日までに、当社が指定する方法で、当社が楽座DAOメンバーシップ（NFT）の対価として定める数のETHを当社に送付するものとします。当該ETHの送付のために生じる費用については、ユーザーが負担するものとします。
      4. 当社は、前項に定めるETHの送付があった場合、次条に定める条件を前提条件として、ユーザーが購入する楽座DAOメンバーシップ（NFT）を受領用アドレスに送付するものとします。
      5. システム上楽座DAOメンバーシップ（NFT）の付与には一定の時間がかかる場合があるため、前項に基づく楽座DAOメンバーシップ（NFT）の付与が完了するまでに、第1項の申込みから期間を要する場合があることについて、ユーザーは同意するものとします。
      6. 未成年者、成年被後見人、被保佐人又は被補助人のユーザーは、楽座DAOメンバーシップ（NFT）の購入を申し込むに当たり、法定代理人、後見人又は補助人の同意を得るものとします。

      第９条（ＮＦＴの付与の前提条件）
      前条に定める楽座DAOメンバーシップ（NFT）の付与は、当社が書面により放棄しない限り、以下の全ての条件が充足されることを条件とします。
      (1) 当社がユーザーに対し楽座DAOメンバーシップ（NFT）の付与を行う旨の判断をしたこと。
      (2) ユーザーが第1２条に定めるリスクを理解し、承認していること。
      (3) 前各号に定める他、ユーザーが本規約に違反していないこと。

      第10条（セキュリティ）
      1. 利用契約等に関して必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。
      2. ユーザーは、アドレス及びNFTの管理に関して、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。

      第１１条（表明及び保証）
      ユーザーは、個別契約の各締結日において、当社に対して、以下の事実が真実であることを表明し保証するものとします。
      (1) 本規約（本サービスに掲載するNFT及び本サービスに関する諸規定等を含みます。）全体を閲読し、その内容を十分理解していること。
      (2) ユーザーは、個別契約を締結し、個別契約に従ってその義務を履行する完全な権限を有しており、また、個別契約の締結に必要な全ての法令等に従った手続を全て履行しており、必要な許認可、届出等の手続が完了しており、何らかの条件が付されている場合にはかかる条件に違反していないこと｡個別契約は、ユーザーの適法、有効かつ法的拘束力のある義務を構成し、ユーザーに対し、その条項に従った強制執行が可能であること。
      (3)  楽座DAOメンバーシップ（NFT）についての情報を十分取得した上で、個別契約の締結を決定したこと。
      (4) 楽座DAOメンバーシップ（NFT）の購入はNFTの付与を受けることのみを意味し、楽座DAOメンバーシップ（NFT）の購入によりユーザーは当社又は当社の関連会社に関する何らかの権利を付与されるものではないことをユーザーが理解していること。
      (5) ユーザーは、法令等によりNFTの購入が禁止、制限その他規制されている国又は地域の国民、住人、居住者若しくは滞在者ではないこと。

      第1２条（リスク）
      ユーザーは、当社に対して、次の各号に定める楽座DAOメンバーシップ（NFT）購入に係るリスクを確認し、かかるリスクを了承するものとします。
      (1) 価格変動リスク ：NFTの価格は、NFT等の販売に関わる市場の動向、天災地変、戦争、政変、規制強化その他の将来予期せぬ事象や特殊な事象等による影響を受ける可能性があります。
      (2) 情報の不確実性：当社は、ホームページ、SNS上等にて、本NFTに関連する何らかの情報を発信することがありますが、かかる情報発信は、計画としての情報が提供されるものにすぎず、かかる情報の内容の真実性、正確性及び実現可能性について一切保証するものではありません。
      (3) NFTに内在するリスク：NFTは、法定通貨ではないため、特定の者によりその価値を保証されているものではなく、また、プログラムのバグ等、NFT自体にリスクが内在している可能性があります。
      (4) 流動性リスク：当社は、NFTについて、P2Pでの取引及びETHその他暗号資産等への交換の可能性について一切保証するものではありません。
      (5) ハードフォークによるリスク：NFTは、ETHのブロックチェーンを利用して発行されており、かかるブロックチェーンのハードフォークにより、互換性がなくなる等のリスクがあります。
      (6) サイバー攻撃リスク：サイバー攻撃によりアドレスの情報等が漏洩し、ユーザーが保有するNFTが無断で第三者に送付される等のリスクがあります。
      (7) ネットワークによるリスク：NFTの取引は、ブロックチェーンの仕組みを利用して行われるため、NFTの付与に一定の期間を要する可能性があり、NFTの付与がアドレスへ反映されない可能性や本NFTの付与がキャンセルされる可能性があり、また、NFTは電子的に記録され、その移転は、ネットワーク上で行われるため、消失のおそれがあります。
      (8) アドレスに関するリスク：ユーザーは、NFTを管理又は保管するためのアドレスにアクセスするために必要となる秘密鍵を喪失した場合、NFTを利用することができなくなるおそれがあります。
      (9) 法令・税制変更リスク：NFTに関する法令及び税制が流動的であり、将来において、法令、税制又は政策等の変更により、NFTの付与が禁止、制限又は課税の強化等がなされ、NFTの保有や取引が制限され、又は現状より不利な取り扱いとなる可能性があり、これらに起因して、ユーザーに予期しない損失が生じる可能性があります。
      (10) NFTの種類及び個数についてのリスク：本サービスにおいて当社が販売するNFTの種類及び個数には限りがあるため、ユーザーは、希望するNFTを購入できない可能性があります。
      (11) その他のリスク：NFTを購入する際の払込において、第三者が当社になり済まして、不正なアドレスを表示して、ユーザーが送付するETHを詐取する可能性があります。

      第1３条（公租公課）
      楽座DAOメンバーシップ（NFT）の購入に関連してユーザーに課される公租公課については、ユーザーが負担するものとします。また、ユーザーに課される公租公課の種類や金額については、ユーザーの責任で確認するものとします。

      第1４条（禁止行為）
      ユーザーは、本サービスの利用及びNFTの購入にあたり、以下の各号のいずれかに該当する行為をしてはならないものとします。
      (1) 当社、他のユーザー、その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
      (2) NFTにかかるデータを、ブロックチェーン上以外において、自己使用以外の目的で利用する行為（当該データをブロックチェーン上以外で複製、第三者提供等する行為、当該データを元に製品を作る行為等を含みます。）
      (3) NFTを、決済手段として利用する行為
      (4) 犯罪収益と関連性のある暗号資産を利用してNFTの購入を行う行為
      (5) 犯罪行為に関連する行為又は公序良俗に反する行為
      (6) 法令又は当社若しくはプレイヤーが所属する業界団体の内部規則に違反する行為
      (7) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
      (8) 本サービス及びNFTに関し利用しうる情報を改ざんする行為
      (9) 当社が指定する以外の方法によって、NFTを第三者に対し譲渡し、又は譲り受ける行為
      (10) 他者になりすまし、又は他人のアカウントを使って本サービスを利用する行為
      (11) 複数のアカウントを作成する行為
      (12) 本サービスのソフトウェアと相互に作用し、当社が利用することを認めていないプログラムを用いる行為
      (13) 本サービス又はNFTの提供のために使用されているソフトウェアの解読、逆コンパイル、分解又はリバースエンジニアリング等を行う行為
      (14) 本サービス又はNFTを改変する行為
      (15) 当社による本サービスの運営を妨害するおそれのある行為
      (16) その他、当社が不適切と合理的に判断する行為

      第1５条（本サービスの停止等）
      1. 当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
      (1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
      (2) コンピューター、通信回線等が事故により停止した場合 
      (3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合 
      (4) GASの高騰、トランザクション詰まり等本NFTの取扱いについてネットワーク上の問題が生じた場合 
      (5) その他、当社が停止又は中断を必要と合理的に判断した場合
      2. 当社は、当社の都合により、本サービスの提供を終了すること、または本サービスの仕様を変更することができます。本サービスの仕様の変更は、変更前と同等の機能及びサービス内容が維持されることを保証しません。
      3. 当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。

      第1６条（設備の負担等）
      1. 本サービスを利用するために必要な、スマートフォン、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。
      2. ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
      3. ユーザーは、本サービスの利用に関連して、ソフトウェアのインストール又はデータのダウンロード等を行う場合には、ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はに発生したかかる損害について一切責任を負わないものとします。

      第1７条（保証の否認及び免責）
      1. NFTは現状有姿で、かつ提供可能な範囲で提供されるものであり、当社は、NFTについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証をしないものとします。
      2. 当社は、NFTそれ自体又はNFTの保有及び送付がウイルスによる攻撃その他の悪意のある第三者からの攻撃にさらされないことについて一切保証しないものとします。
      3. ユーザーが当社から直接又は間接に、NFTに関する何らかの情報を得た場合であっても、当社はユーザーに対し本規約で明示的に規定される当社の義務の内容を超えて如何なる保証も行うものではありません。
      4. 当社は、NFTの信頼性、最新性や無瑕性、その他のユーザーが期待する条件を充足することを一切保証しないものとします。
      5. 当社は、NFTにシステム上何らかの欠陥が存在した場合に、かかる欠陥が将来是正されることを一切保証しないものとします。
      6. 当社は、当社の裁量でNFTを追加で発行することができるものとし、かかる発行に関してユーザーに生じ得る損害について一切の責任を負わないものとします。

      第18条（権利帰属）
      NFT及び本サービスに関する創作物（NFTに関連付けられた画像及び映像等を含みますがこれらに限られません。以下本条において同様です。）にかかる知的財産権（著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。）は全て当社又は当社にライセンスを許諾している者に帰属しており、個別契約に基づくNFTの付与は、NFT又は本サービスに関する創作物にかかる当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）を行わないものとします。

      第1９条（情報の取扱い）
      ユーザーが本サービスを利用することにより当社が取得する個人情報の取扱いは、別途当社が定めるプライバシーポリシーによるものとします。ユーザーは、本サービスを利用するにあたり、当該プライバシーポリシーに従って当社が個人情報を取扱うことについて同意するものとします。

      第２０条（補償）
      1. ユーザーが、利用契約等に関連して当社又は第三者に損害を及ぼした場合には、ユーザーはその損害（直接損害及び通常損害のみならず、逸失利益、事業機会の喪失、データの喪失、事業の中断、その他の間接損害、特別損害、派生的損害及び付随的損害を含む全ての損害を意味します。）を賠償する責任を負うものとします。
      2. 当社が、NFTに関するユーザーの行為により、第三者から請求その他何らかの権利の主張を受けた場合、ユーザーは、ユーザーの費用負担のもと、速やかに、当社が、かかる請求ないし権利の主張を直接に受けることがないようにするために必要な措置を採るものとします。

      第２１条（損害賠償）
      1. 当社は、本サービスおよびNFTに関連してユーザーが被った損害について、一切賠償の責任を負わないものとします。消費者契約法の適用その他の理由により当社がユーザーに対して損害賠償責任を負う場合においても当社の賠償責任は、当該損害に関連する個別契約に基づき、ユーザーから現実に受領したETHの基準ETHレートで日本円に換算した金額に相当する額を上限とします。
      2. 他の規定にかかわらず、NFTに関連してユーザーが被った損害について、当該損害が当社の債務不履行又は不法行為によるものであり、かつ、当社の故意又は重大な過失による場合、当社はその損害を賠償します。

      第2２条（不可抗力）
      当社は、当社の合理的な支配の及ばない状況（火事、停電、ハッキング、コンピューターウィルスの侵入、地震、洪水、戦争、疫病、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、又は政府当局による介入を含みますがこれらに限定されません。）により利用契約等上の義務の履行が遅延した場合、その状態が継続する期間中ユーザーに対し債務不履行責任を負わないものとします。

      第2３条（キャンセル）
      1. ユーザーは、いかなる場合でも、利用契約等を解除し、ユーザーがNFTの対価として当社に送付したETHの返還を求めることはできないものとします。
      2. 当社は、ユーザーにNFTを付与しないこととする場合には、当社の裁量により、当社が既に受領しているETHをユーザーに返却することがあるものとします。この場合、当社がユーザーから受領したETHと同数のETHをユーザーに対して送付し、又は基準ETHレートで日本円に換算した金額に相当する金銭若しくは同等の価値を有すると当社が判断したNFTをユーザーに対して付与する方法により返却を行うことができるものとします。
      3. 当社は、ユーザーが利用契約等に違反した場合には、利用契約等に基づき当社がユーザーに付与したNFTの機能を喪失させることその他当社の裁量により適切と考える措置を実行することができるものとします。

      第2４条（利用契約の解除（登録取消）等）
      1. 当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて本サービスの利用を一時的に停止し、又はユーザーとしての登録を取り消し、利用契約等を解除することができます。 
      (1) 本規約のいずれかの条項に違反した場合 
      (2) 登録情報に虚偽の事実があることが判明した場合 
      (3) 当社、他のユーザーその他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合 
      (4) 手段の如何を問わず、本サービスの運営を妨害した場合 
      (5) 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合 
      (6) 自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合 
      (7) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合 
      (8) 租税公課の滞納処分を受けた場合 
      (9) 死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合 
      (10) 6ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合 
      (11)その他、当社がユーザーとしての登録の継続を適当でないと判断した場合
      2. 前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      3. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負いません。
      4. 利用契約が終了する時点で既に成立している個別契約については、当該個別契約が別途解除されない限り、当該個別契約に基づく債務が全て履行されるまでの間、有効に存続するものとし、その範囲において本規約の適用を受けるものとします。

      第2５条（反社会的勢力の排除）
      1. 当社は、ユーザー又は利用契約（本条においては、利用契約に関連して締結した個別契約その他契約を含みます。）に関するユーザーの代理人又は利用契約締結を媒介した者が反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同様です。）であることが判明したときには、催告を要せずユーザーに通知することにより直ちに利用契約を将来に向かって解除することができます。
      2. 前項に定める場合を除き、当社は、ユーザーの取締役、監査役、従業員その他の構成員、株主、取引先、若しくは顧問その他のアドバイザーが反社会的勢力であること、又はユーザーが資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていることが判明した場合、ユーザーに通知することにより直ちに利用契約を将来に向かって解除することができます。3. 当社は、本条に定める解除によりユーザーに生じた損害の賠償責任を負わないものとします。

      第2６条（秘密保持）
      1. 利用契約等において「秘密情報」とは、利用契約等に関連して、ユーザーが、当社より口頭、書面その他の記録媒体等により提供若しくは開示されたか又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項（NFT及び本サービスに関する事項を含みます。）に関する全ての情報を意味します。ただし、次のいずれかに該当するものは，秘密情報から除外されるものとします。
      (1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は、既に知得していた情報
      (2)当社から提供若しくは開示がなされた後又は知得した後、自己の責に帰せざる事由により刊行物その他により公知となった情報
      (3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得した情報
      (4)秘密情報によることなく単独で開発した情報
      (5)当社から秘密保持の必要なき旨書面で確認された情報
      2. ユーザーは、秘密情報をNFTの購入のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
      3. ユーザーは、利用契約の終了時又は当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体及びその全ての複製物を返却又は廃棄するものとします。

      第２７条（譲渡禁止）
      1. ユーザーは、当社の書面による事前の同意なくして、利用契約等上の地位又は利用契約等に基づく権利若しくは義務につき、第三者に対する譲渡、担保設定、その他の処分をしてはならないものとします。
      2. 当社はNFT又は本サービスに関する事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約等上の地位、利用契約等に基づく権利及び義務並びに、ユーザーに関する情報その他の情報を当該譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。

      第２８条（完全合意）
      利用契約等は、利用契約等に含まれる事項に関する利用契約等の当事者間の完全な合意を構成し、口頭又は書面によるとを問わず、当事者間の利用契約等に定める事項に関する事前の合意、表明及び了解に優先します。

      第2９条（分離可能性）
      本規約のいずれかの条項又はその一部が無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、本契約の当事者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。

      第３０条（存続規定）
      利用契約が解除その他の理由により終了した場合であっても、第６条、第７条、第１０条から第1２条まで、第1５条第3項、第1６条から第2３条まで、第2４条第2項から第4項まで、第2５条第２項、第2６条から第３１条までの規定は、利用契約終了後も有効に存続します。但し、第2６条については、利用契約終了後5年間に限り存続するものとします。

      第３１条（準拠法及び合意管轄）
      利用契約等の準拠法は日本法とし、利用契約等に関連して生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

      第３２条（協　議）
      本規約に定めのない事項及び解釈の疑義については、法令の規定並びに慣習に従うほか、両当事者誠意をもって協議解決を図るものとします。

      以上

`}`;
  return <WrapDivStyled>{text}</WrapDivStyled>;
}
