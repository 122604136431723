import { Component } from 'react'
import styled from 'styled-components'

import Modal from '@/components/modal'
import Media from '@/utils/media'

const StyledDiv = styled.div`
  background-color: rgb(247, 248, 250);
  
  ${Media.lessThan(Media.SIZE.MD)} {
  }
`

class UniswapInterfaceModal extends Component {
  state = {
    isOpen: false,
    price: 0
  }

  open = ({price}) => this.setState({ isOpen: true, price })

  close = () => this.setState({ isOpen: false })

  render() {
    const { isOpen, price } = this.state
    
    return (
      <Modal
        open={isOpen}
        onCancel={this.close}
        destroyOnClose
        padding={0}
      >
        <StyledDiv>
          <iframe
            title="uniswap-interface"
            src={`https://app.uniswap.org/#/swap?exactField=input&exactAmount=${price}&inputCurrency=ETH&outputCurrency=${process.env.REACT_APP_WETH_CONTRACT_ADDRESS}`}
            height="660px"
            width="100%"
            style={{
              border: 0,
              margin: '0 auto',
              paddingTop: '70px',
              display: 'block',
              maxWidth: '600px',
              minWidth: '300px'
            }}
            id="myId"
          />
        </StyledDiv>
      </Modal>
    )
  }
}

export default UniswapInterfaceModal
