import React, { Component } from 'react';
import { Modal } from 'antd';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import Typography from '../components/typography';
import Button from '../components/button';

const StyledModal = styled(Modal)`

  .ant-modal-content {
    background-color: #1b1e24;
    border-radius: 10px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;

      .modal-content {
        .body {
          padding: 30px;
          .typography {
            font-weight: 400;
            font-size: 16px;
            color: #fff;
          }

          .action-box {
            padding-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            .action-button {
              margin-right: 15px;
              height: 35px;
              background-color: #045afc;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
`;

let instance;

class Confirmable extends Component {
  static propTypes = {};

  state = {
    visible: false,
    content: null,
    acceptButtonText: null,
    cancelButtonText: null,
    hideCancelButton: false,
    hideOkButton: false,
    maskClosable: true,
    width: 400,
    otherFunctionHandle: null
  };

  static setInstance = ref => {
    instance = ref;
  };

  static open = (...params) => {
    if (instance) {
      return instance.open(...params);
    }

    return null;
  };

  open = ({ onOk, onClose, content, acceptButtonText, cancelButtonText, hideCancelButton, hideOkButton, maskClosable, width, otherFunctionHandle = null }) =>
    new Promise(resolve => {
      this._resolve = resolve;
      this.setState({
        visible: true,
        onOk,
        content,
        acceptButtonText,
        cancelButtonText,
        hideCancelButton,
        hideOkButton,
        maskClosable,
        width,
        otherFunctionHandle,
        onClose
      });
    });

  _onClose = () => {

    this.setState({
      visible: false,
    });
  };

  _onCancel = () => {
    const { onClose } = this.state;
    if (onClose) onClose();
    this._resolve(false);
    this._onClose();
  };

  _onAccept = () => {
    const { onOk } = this.state;
    if (onOk) onOk();
    this._onClose();

    setTimeout(() => {
      this._resolve(true);
      if (this.state.otherFunctionHandle) {
        this.state.otherFunctionHandle()
      }
    }, 300);
  };

  render() {
    const {
      visible,
      content,
      acceptButtonText,
      cancelButtonText,
      hideCancelButton,
      hideOkButton,
      maskClosable,
      width
    } = this.state;

    return (
      <StyledModal
        open={visible}
        centered
        onCancel={this._onCancel}
        footer={null}
        destroyOnClose
        maskClosable={maskClosable}
        width={width || 400}
      >
        <div className="modal-content">
          <Translation>
            {t => (
              <div className="body">
                <Typography center size="large" style={{ wordBreak: 'break-word' }}>
                  {content}
                </Typography>
                <div className="action-box">
                  {!hideCancelButton && (
                    <Button onClick={this._onCancel} className="action-button">
                      {cancelButtonText || t('common:close')}
                    </Button>
                  )}
                  {!hideOkButton && (
                    <Button type="primary" onClick={this._onAccept} className="action-button">
                      {acceptButtonText || t('common:ok')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Translation>
        </div>
      </StyledModal>
    );
  }
}

export default Confirmable;
