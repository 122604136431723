const SIZE = {
  XXXL: 1600, // DESKTOP L
  XXL: 1441, // DESKTOP L
  XL: 1200, // DESKTOP M
  LG: 992, // TABLET L
  MD: 769, // TABLET M
  SM: 576, // Mobile L
  XS: 423, // Mobile M
  XXS: 390, // Mobile S
  XXXS: 321 // Mobile
}

export default class Media {
  static SIZE = SIZE

  static greaterThan(windowSize) {
    return `@media only screen and (min-width: ${windowSize}px)`
  }

  static lessThan(windowSize) {
    return `@media only screen and (max-width: ${windowSize - 1}px)`
  }
}
