const CURRENCIES = {
  // fiat
  USD: 'USD',
  JPY: 'JPY',
  // crypto currencies
  USDT: 'USDT',
  ETH: 'ETH',
  WETH: 'WETH',
  MATIC: 'MATIC',
  WMATIC: 'WMATIC',
  POINT: 'POINT',
};

export const WRAPPED_CURRENCIES = [CURRENCIES.WETH, CURRENCIES.WMATIC];

export default CURRENCIES;
