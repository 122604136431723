import Request from '../utils/request';
// import Configs from '../configs';

// Edit the suffix base on the version of your server API
const endpoint = `${process.env.REACT_APP_API_URL}/api/v1`;

const endpointMGA = `${process.env.REACT_APP_API_URL_MCC}/api/v1`;
const endpointRakuza = `${process.env.REACT_APP_API_URL_RAKUZA}/api/v1`;

// Endpoint that using to access server APIs
const MainApi = Request.create({
  endpoint,
  handleToken: true,
});

const MainApiMGA = Request.create({
  endpoint: endpointMGA,
  handleToken: true,
});

const MainApiRakuza = Request.create({
  endpoint: endpointRakuza,
});

// Endpoint that using to access external APIs
const ExternalApi = Request.create({
  endpoint: '',
});

// Endpoint that using to download file which will response a Blob object
const BlobApi = Request.create({
  endpoint,
  handleToken: true,
  handleBlob: true,
});

export { MainApi, ExternalApi, BlobApi, MainApiMGA, MainApiRakuza };
