import React from 'react';

import './resources/styles';
import Store from './store';
import Theme from './theme';
import Init from './app/init';
import Routes from './app/routes';
import Confirmable from './components/confirmable';
// import MaskLoading from './components/mask-loading';
// import LoginModal from './app/login-modal';
import './translations/i18n';
import './services/firebase';

import './App.less';
import './assets.css';
import Providers from './Providers';
import MaskLoading from './components/mask-loading';
import LoginModal from './app/login-modal';

const App = () => (
  <Store>
    <Theme>
      <Providers>
        <Init>
          <Routes />
        </Init>
        <MaskLoading ref={ref => MaskLoading.setInstance(ref)} />
        <Confirmable ref={ref => Confirmable.setInstance(ref)} />
        <LoginModal ref={ref => LoginModal.setInstance(ref)} />
      </Providers>
    </Theme>
  </Store>
);

export default App;
