import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';

import {
  getNotificationsStatistic,
  getListNotifications,
  getNotificationDetail,
  getCommentsByNotiId,
  commentInNotification,
  updateCommentInNotification,
  deleteCommentInNotification,
  getListNotificationsPinned,
  getListLowComments,
  commentInCommentNotification,
} from '../api/notifications';

const TYPES = {
  GET_NOTIFICATIONS_STATISTIC: 1,
  GET_LIST_NOTIFICATIONS: 2,
  GET_NOTIFICATION_DETAIL: 3,
  GET_COMMENTS_BY_NOTI_ID: 4,
  COMMENT_IN_NOTIFICATION: 5,
  UPDATE_COMMENT_INNOTIFICATION: 6,
  DELETE_COMMENT_IN_NOTIFICATION: 7,
  GET_LIST_NOTIFICATIONS_PINNED: 8,
  GET_LIST_LOW_COMMENTS: 9,
  COMMENT_IN_COMMENT_NOTIFICATION: 10,
};

const Notification = types.model('Notification')
  .props({
    id: types.number,
    category: types.maybeNull(types.string),
    sender: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.number),
    hotFlg: types.maybeNull(types.boolean),
    pinnedFlg: types.maybeNull(types.boolean),
    imageUrl: types.maybeNull(types.string),
    commentCount: types.maybeNull(types.number)
  })

const Comment = types.model('Comment')
  .props({
    id: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    sendDate: types.maybeNull(types.number),
    sender: types.maybeNull(types.string)
  })
  .actions(self => ({
    setComment(payload) {
      self.content = payload.content
    }

  }))

const PostNoti = types.model('PostNoti')
  .props({
    id: types.maybeNull(types.number),
    category: types.maybeNull(types.string),
    sender: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    hotFlg: types.maybeNull(types.boolean),
    pinnedFlg: types.maybeNull(types.boolean),
    sendDate: types.maybeNull(types.number),
    content: types.maybeNull(types.string),
    commentCount: types.maybeNull(types.number)
  })

const NotificationStore = Model.named('NotificationStore')
  .props({
    countNoti: types.model({
      unread: types.maybeNull(types.number)
    }),
    notifications: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Notification)
    }),
    notificationDetail: PostNoti,

    commentsById: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),

    listLowComments: types.model({
      page: types.number,
      total: types.number,
      items: types.array(Comment),
      pages: types.number
    }),
  })
  .actions(self => ({
    getNotificationsStatistic() {
      return self.request({
        type: TYPES.GET_NOTIFICATIONS_STATISTIC,
        api: getNotificationsStatistic,
        onSuccess: result => {
          self.countNoti = result
        },
      })
    },

    getListNotifications (payload) {
      return self.request({
        type: TYPES.GET_LIST_NOTIFICATIONS,
        api: getListNotifications,
        payload,
        onSuccess: (result) => {
          self.notifications.page = result.page
          self.notifications.total = result.total
          self.notifications.items = result.result
        },
      })
    },

    getListNotificationsPinned() {
      return self.request({
        type: TYPES.GET_LIST_NOTIFICATIONS_PINNED,
        api: getListNotificationsPinned
      })
    },


    getNotificationDetail(payload) {
      return self.request({
        type: TYPES.GET_NOTIFICATION_DETAIL,
        api: getNotificationDetail,
        payload,
        onSuccess: (result) => {
          self.notificationDetail = result
        }
      })
    },

    getCommentsByNotiId(payload, concat) {
      return self.request({
        type: TYPES.GET_COMMENTS_BY_NOTI_ID,
        api: getCommentsByNotiId,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },

    commentInNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_NOTIFICATION,
        api: commentInNotification,
        payload,
        onSuccess: (result) => {
          self.commentsById.items = self.commentsById.items.concat(result)
        }
      })
    },

    getListLowComments(payload, concat) {
      return self.request({
        type: TYPES.GET_LIST_LOW_COMMENTS,
        api: getListLowComments,
        payload,
        onSuccess: (result) => {
          self.commentsById.page = result.page
          self.commentsById.total = result.total
          self.commentsById.items = concat ? self.commentsById.items.concat(result.result) : result.result
          self.commentsById.pages = result.pages
        }
      })
    },

    commentInCommentNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_COMMENT_NOTIFICATION,
        api: commentInCommentNotification,
        payload,
      })
    },

    updateCommentInNotification(payload) {
      return self.request({
        type: TYPES.COMMENT_IN_NOTIFICATION,
        api: updateCommentInNotification,
        payload,
      })
    },

    deleteCommentInNotification(payload) {
      return self.request({
        type: TYPES.DELETE_COMMENT_IN_NOTIFICATION,
        api: deleteCommentInNotification,
        payload,
        onSuccess: () => {
          const objEdit = self.commentsById.items.filter(item => item.id !== payload.commentId)

          self.commentsById.items = objEdit
        }
      })
    },

  }))

export { TYPES };

export default NotificationStore.create({
  countNoti: {
    unread: 0
  },
  notifications: {
    page: 0,
    total: 0,
    items: []
  },
  notificationDetail: {
    id: null,
    category: null,
    sender: null,
    title: null,
    imageUrl: null,
    hotFlg: null,
    pinnedFlg: null,
    sendDate: null,
    content: null,
    commentCount: null
  },

  commentsById: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },

  listLowComments: {
    page: 0,
    total: 0,
    items: [],
    pages: 0
  },
});