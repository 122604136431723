export const WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link'
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url='
  }
]

export const _generateDappUrl = ({ link, name }) => {
  if (link) {
    if (name === 'Metamask') {
      const dappUrl = `${window.location.href}`.trim();
      if (dappUrl.search('https://') !== -1) {
        const pageUrl = `${link}/dapp/${dappUrl.replace('https://', '')}`;
        window.location.href = pageUrl;
      }
    }
  }
};