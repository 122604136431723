import { Model } from "../utils/mobx-model-helper"
import { getEvents, getEventDetails } from "../api/events"

const TYPES = {
  GET_EVENTS: 1,
  GET_EVENT_DETAILS: 2,
}

const EventsStore = Model.named("EventsStore").actions((self) => ({
  getEvents(payload) {
    return self.request({
      type: TYPES.GET_EVENTS,
      api: getEvents,
      payload,
    })
  },

  getEventDetails(payload) {
    return self.request({
      type: TYPES.GET_EVENT_DETAILS,
      api: getEventDetails,
      payload,
    })
  },
}))

export { TYPES }
export default EventsStore.create()

