import { types } from "mobx-state-tree"

import { Model } from "../utils/mobx-model-helper"
import { getMembershipStatistics } from '@/api/my-page'

const TYPES = {
  GET_STATISTICS: 1,
}

const statistic = types.model("statistic").props({
  ethTotal: types.maybeNull(types.number),
})

const Statistics = Model.named("Statistics")
  .props({
    statistics: statistic,
  })
  .actions((self) => ({
    getMembershipStatistics(payload) {
      return self.request({
        type: TYPES.GET_STATISTICS,
        api: getMembershipStatistics,
        payload,
        onSuccess: (result) => {
          self.statistics = result
        },
      })
    },
  }))
export { TYPES }
export default Statistics.create({
  statistics: {
    ethTotal: 0
  },
})

