export const tokens = {
  wrappedNative: {
    1: {
      symbol: 'WETH',
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      decimals: 18,
      fractionDigits: 8,
    },
    11155111: {
      symbol: 'WETH',
      address: '0x178af04497F5A5eD9dF3f211023402Be436bB850',
      decimals: 18,
      fractionDigits: 8,
    },
    137: {
      symbol: 'WMATIC',
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      decimals: 18,
      fractionDigits: 8,
    },
    80001: {
      symbol: 'WMATIC',
      address: '0x735AA00Bb409a6960C2Fff24617D5DBA3be66ab3',
      decimals: 18,
      fractionDigits: 8,
    },
  },
  usdt: {
    1: {
      symbol: 'USDT',
      address: '',
      decimals: 6,
      fractionDigits: 6,
    },
    11155111: {
      symbol: 'USDT',
      address: '0xbBd6682f3E964E6a4d4ac1b451091575Ad20AA6a',
      decimals: 6,
      fractionDigits: 6,
    },
    137: {
      symbol: 'USDT',
      address: '',
      decimals: 6,
      fractionDigits: 6,
    },
    80001: {
      symbol: 'USDT',
      address: '0xc92EF7b7B5214e73b45A8a2ED7022eBD5fd35A26',
      decimals: 6,
      fractionDigits: 6,
    },
  },
};
