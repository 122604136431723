import { types } from 'mobx-state-tree'
import moment from 'moment'

import { Model } from '../utils/mobx-model-helper'

import {
  getProducts,
  getEventProducts,
  getMyProducts,
  getMyProductsWithNoPaging,
  getMyBidProducts,
  getProductDetails,
  getProductCategories,
  getProductFilterData,
  getPriceRate,
  getPriceChartData,
  getTransactionHistories,
  createInteraction,
  getProductChildItem,
  requestParentToken,
  validateProductChild,
  getHistoriesProductChild,
  getProductsWish,
  getMyTransactionHistories,
  approveForAllProduct,
  exchangeRegister,
  exchangeConfirm,
  getProductPrice,
  getAuthorProductPrice,
  getListImageType,
  getProductsCreated,
  createProduct,
  getProductCategoriesType,
  getListColor,
  getProductCreateDetail,
  updateProduct,
  openSaleNft,
  updateToken,
  getListProductsRakuza
} from '../api/products'

import {
  getProductErc1155Details,
  getListProductOwner1155,
  getProductIdRemain
} from '../api/product1155'

const TYPES = {
  'GET_PRODUCTS': 1,
  'GET_EVENT_PRODUCTS': 2,
  'GET_MY_PRODUCTS': 3,
  'GET_MY_BID_PRODUCTS': 4,
  'GET_PRODUCT_DETAILS': 5,
  'GET_PRODUCT_CATEGORIES': 6,
  'GET_PRODUCT_FILTER_DATA': 7,
  'GET_PRICE_RATE': 8,
  'GET_MY_SELL_PRODUCTS': 9,
  'GET_MY_SOLD_PRODUCTS': 10,
  'GET_MY_OFFER_PRODUCTS': 11,
  'GET_PRICE_CHART_DATA': 12,
  'GET_TRANSACTION_HISTORIES': 13,
  'CREATE_INTERACTION': 14,
  'GET_PRODUCT_CHILD_ITEM': 15,
  'REQUEST_PARENT_TOKEN': 16,
  'VALIDATE_PRODUCT_CHILD': 17,
  'GET_PRODUCTS_WISH': 18,
  'GET_MY_TRANSACTION_HISTORIES': 19,
  'GET_CHILD_PRODUCT_HISTORIES': 20,
  'APPROVE_FOR_ALL': 21,
  'EXCHANGE_REGISTER': 22,
  'EXCHANGE_CONFIRM': 23,
  'GET_PRODUCT_PRICE': 24,
  'GET_AUTHOR_PRODUCT_PRICE': 25,
  'GET_LIST_IMAGE_TYPE': 27,
  'GET_PRODUCTS_CREATED': 28,
  'CREATE_PRODUCT': 29,
  'GET_PRODUCT_CATEGORIES_TYPE': 30,
  'GET_LIST_COLOR': 31,
  'GET_PRODUCT_CREATE_DETAIL': 32,
  'UPDATE_PRODUCT': 33,
  'OPEN_SALE_NFT': 34,
  'UPDATE_TOKEN': 35,
  'GET_PRODUCTS_ERC1155_DETAILS': 36,
  'GET_LIST_PRODUCT_OWNER_1155': 37,
  'GET_LIST_PRODUCT_RAKUZA': 38,
  'GET_PRODUCT_ID_REMAIN': 39
}

const NEW_RANGE_HOURS = 24

const ChildProductHistory = types.model('ChildProductHistory')
  .props({
    childIndex: types.number,
    currency: types.string,
    price: types.number,
    productId: types.number,
    productCreatedAt: types.maybeNull(types.number),
    userImage: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    userPublicAddress: types.maybeNull(types.string)
  })

const Category = types.model('Category')
  .props({
    id: types.identifierNumber,
    name: types.string,
    value: types.maybeNull(types.string)
  })

const ProductBid = types.model('ProductBid')
  .props({
    approvePublicKey: types.maybeNull(types.string),
    biggestBidPrice: types.maybeNull(types.number),
    biggestBidPriceYen: types.maybeNull(types.number),
    checkUserBid: types.boolean,
    endTime: types.number,
    expectPrice: types.maybeNull(types.number),
    expectPriceDisplay: types.maybeNull(types.number),
    id: types.number,
    otherUserBidProduct: types.boolean,
    startPrice: types.number,
    yenStartPrice: types.number,
    startTime: types.number,
    status: types.string,
    transferNBNGStatus: types.maybeNull(types.string),
    transferTokenStatus: types.maybeNull(types.string),
    currency: types.maybeNull(types.string)
  })
  .actions((self) => ({
    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    }
  }))

const ProductSell = types.model('ProductSell')
  .props({
    approvePublicAddress: types.maybeNull(types.string),
    resellCancelable: types.boolean,
    confirmResell: types.boolean,
    reselling: types.boolean
  })
  .actions((self) => ({
    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    }
  }))

const ProductDetails = types.model('ProductDetails')
  .props({
    id: types.identifierNumber,
    bidFlag: types.boolean,
    buying: types.boolean,
    bigSizeHeight: types.maybeNull(types.number),
    bigSizeWidth: types.maybeNull(types.number),
    character: types.maybeNull(types.string),
    contractAddress: types.maybeNull(types.string),
    copyrightOwner: types.maybeNull(types.string),
    countView: types.number,
    currency: types.string,
    currentTime: types.number,
    description: types.maybeNull(types.string),
    introduce: types.maybeNull(types.string),
    outstanding: types.maybeNull(types.string),
    characteristic: types.maybeNull(types.string),
    displayStatus: types.maybeNull(types.string),
    endTime: types.number,
    holding: types.boolean,
    imageUrl: types.maybeNull(types.string),
    license: types.boolean,
    licenseId: types.maybeNull(types.number),
    manufactureYear: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    original: types.maybeNull(types.string),
    originalStudio: types.maybeNull(types.string),
    owned: types.boolean,
    ownerAddress: types.maybeNull(types.string),
    ownerAvatar: types.maybeNull(types.string),
    ownerName: types.maybeNull(types.string),
    paymentAddress: types.maybeNull(types.string),
    price: types.number,
    rareFlg: types.boolean,
    newAuctionFlag: types.boolean,
    smallSizeHeight: types.maybeNull(types.number),
    smallSizeWidth: types.maybeNull(types.number),
    startTime: types.number,
    status: types.string,
    title: types.maybeNull(types.string),
    tokenCharacter: types.maybeNull(types.string),
    tokenCreator: types.maybeNull(types.string),
    tokenId: types.maybeNull(types.number),
    transactionId: types.maybeNull(types.string),
    typeSale: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    yenPrice: types.maybeNull(types.number),
    categories: types.array(types.model({
      name: types.maybeNull(types.string),
      value: types.maybeNull(types.string)
    })),
    backgroundColor: types.maybeNull(types.string),
    imageType: types.maybeNull(types.string),
    productBid: types.maybeNull(ProductBid),
    productResell: types.maybeNull(ProductSell),
    heatCount: types.maybeNull(types.number),
    canOfferFlag: types.boolean,
    isAllowOffer: types.maybeNull(types.boolean),
    tokenType: types.maybeNull(types.string),
    offerNftTransactionStatus: types.maybeNull(types.string),
    productBuying: types.boolean,
    workType: types.maybeNull(types.string),
    isUserWished: types.boolean,
    wishCount: types.maybeNull(types.number),
    childIndex: types.maybeNull(types.number),
    parentProductId: types.maybeNull(types.number),
    parentProductInfo: types.maybeNull(types.model({
      countChild: types.maybeNull(types.number),
      imageUrl: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      remainingChildProductNumber: types.maybeNull(types.number)
    })),
    countChild: types.maybeNull(types.number),
    remainingChildProductNumber: types.maybeNull(types.number),
    remainingChildProductNumberCanPurchase: types.maybeNull(types.number),
    childPrice: types.maybeNull(types.number),
    yenChildPrice: types.maybeNull(types.number),
    imageUrls: types.array(types.maybeNull(types.string)),
    videoUrls: types.array(types.maybeNull(types.string)),
    displaySize: types.maybeNull(types.string),
    reselling: types.maybeNull(types.boolean),
    user: types.model({
      userId: types.maybeNull(types.number),
      authenMetamask: types.maybeNull(types.string),
      bio: types.maybeNull(types.string),
      coverImage: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.number),
      email: types.maybeNull(types.string),
      heatCount: types.maybeNull(types.number),
      infoReqFlg: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      nonce: types.maybeNull(types.number),
      publicAddress: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      userImage: types.maybeNull(types.string),
      wishCount: types.maybeNull(types.number)
    }),
    artist: types.maybeNull(types.model({
      address: types.maybeNull(types.string),
      commissionRate: types.maybeNull(types.number),
      cover: types.maybeNull(types.string),
      icon: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      introduce: types.maybeNull(types.string),
      linkInstagram: types.maybeNull(types.string),
      linkTwister: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      nickname: types.maybeNull(types.string),
      item: types.maybeNull(types.number)
    })),
    supporter: types.maybeNull(types.model({
      backgroundImage: types.maybeNull(types.string),
      bio: types.maybeNull(types.string),
      comment: types.maybeNull(types.string),
      icon: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      name: types.maybeNull(types.string),
      position: types.maybeNull(types.string),
      publicAddress: types.maybeNull(types.string),
      returnRate: types.maybeNull(types.number),
      supported: types.maybeNull(types.number)
    })),
    returnRate: types.maybeNull(types.number),
    comment: types.maybeNull(types.string)
  })
  .views((self) => ({
    get isCheckoutValid() {
      return self.status === 'SALE'
        && !self.holding
    },

    get isBidValid() {
      return self.productBid.status === 'NEW'
        && self.bidFlag
        && self.status === 'SALE'
        && self.displayStatus === 'PUBLIC'
        && self.typeSale === 'LIMITED'
    },

    get isCancelBidTimeValid() {
      const currentTime = moment(self.currentTime)
      const startTime = moment(self.productBid.startTime)
      const endTime = moment(self.productBid.endTime)

      const isTimeValid = currentTime.diff(startTime, 's') >= 0
        && endTime.diff(currentTime, 's') > 600

      return isTimeValid
    },

    get isParent() {
      return self.tokenType === 'MULTI'
    },

    get isMulti() {
      return self.parentProductId || self.tokenType === 'MULTI'
    }
  }))
  .actions((self) => ({
    setHolding(value) {
      self.holding = value
    },

    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    },

    updateHeatCount() {
      self.heatCount += 1
    }
  }))


const ProductDetails1155 = types.model('ProductDetails1155')
  .props({
    attributes: types.array(types.string),
    id: types.identifierNumber,
    // bidFlag: types.boolean,
    buying: types.boolean,
    bigSizeHeight: types.maybeNull(types.number),
    bigSizeWidth: types.maybeNull(types.number),
    caption: types.maybeNull(types.string),
    character: types.maybeNull(types.string),
    characteristic: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    contractAddress: types.maybeNull(types.string),
    copyrightOwner: types.maybeNull(types.string),
    countView: types.number,
    currency: types.string,
    currentTime: types.number,
    description: types.maybeNull(types.string),
    displaySize: types.maybeNull(types.string),
    displayStatus: types.maybeNull(types.string),
    featureFlg: types.maybeNull(types.string),

    heatCount: types.maybeNull(types.number),
    hashTagNames: types.maybeNull(types.string),
    holding: types.boolean,
    imageUrl: types.maybeNull(types.string),
    imageUrls: types.array(types.maybeNull(types.string)),
    introduce: types.maybeNull(types.string),
    isUserWished: types.boolean,
    manufactureYear: types.maybeNull(types.string),
    metaMaskDescription: types.maybeNull(types.string),
    metaMaskImageUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    original: types.maybeNull(types.string),
    originalStudio: types.maybeNull(types.string),
    outstanding: types.maybeNull(types.string),
    owned: types.boolean,
    ownerAddress: types.maybeNull(types.string),
    ownerAvatar: types.maybeNull(types.string),
    ownerName: types.maybeNull(types.string),
    paymentAddress: types.maybeNull(types.string),
    price: types.number,
    productBuying: types.boolean,
    productAttributes: types.array(types.model({
      artistGroupId: types.maybeNull(types.number),
      artistGroupName: types.maybeNull(types.string),
      artistId: types.maybeNull(types.number),
      artistName: types.maybeNull(types.string),
      createAt: types.maybeNull(types.number),
      id: types.maybeNull(types.number),
      mainBackground: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      originalFlg: types.boolean,
      priority: types.maybeNull(types.number),
      productAttributesMultiLang: types.array(types.string),
      storeBackground: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
      value: types.maybeNull(types.string)
    })),
    productMultiLanguages: types.array(types.model({
      copyrightOwner: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      langKey: types.maybeNull(types.string),
      manufactureYear: types.maybeNull(types.number),
      name: types.maybeNull(types.string),
      original: types.maybeNull(types.string),
      originalStudio: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
      tokenCharacter: types.maybeNull(types.string),
    })),
    // properties
    quantityOwned: types.maybeNull(types.number),
    returnRate: types.maybeNull(types.number),
    smallSizeHeight: types.maybeNull(types.number),
    smallSizeWidth: types.maybeNull(types.number),
    soldTokens: types.maybeNull(types.number),
    status: types.string,
    title: types.maybeNull(types.string),
    tokenCharacter: types.maybeNull(types.string),
    tokenCreator: types.maybeNull(types.string),
    tokenId: types.maybeNull(types.number),
    tokensAmount: types.maybeNull(types.number),
    totalETHPriceSale: types.maybeNull(types.number),
    transactionId: types.maybeNull(types.string),
    user: types.model({
      userId: types.maybeNull(types.number),
      authenMetamask: types.maybeNull(types.string),
      bio: types.maybeNull(types.string),
      coverImage: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.number),
      email: types.maybeNull(types.string),
      heatCount: types.maybeNull(types.number),
      infoReqFlg: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      nonce: types.maybeNull(types.number),
      publicAddress: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      userImage: types.maybeNull(types.string),
      wishCount: types.maybeNull(types.number)
    }),
    userId: types.maybeNull(types.number),
    videoUrls: types.array(types.maybeNull(types.string)),
    wishCount: types.maybeNull(types.number),
    workType: types.maybeNull(types.string),
    yenPrice: types.maybeNull(types.number),

  })
  .views((self) => ({
    get isCheckoutValid() {
      return self.status === 'SALE'
        && !self.holding
    },

    get isBidValid() {
      return self.productBid.status === 'NEW'
        && self.bidFlag
        && self.status === 'SALE'
        && self.displayStatus === 'PUBLIC'
        && self.typeSale === 'LIMITED'
    },

    get isCancelBidTimeValid() {
      const currentTime = moment(self.currentTime)
      const startTime = moment(self.productBid.startTime)
      const endTime = moment(self.productBid.endTime)

      const isTimeValid = currentTime.diff(startTime, 's') >= 0
        && endTime.diff(currentTime, 's') > 600

      return isTimeValid
    },

    get isParent() {
      return self.tokenType === 'MULTI'
    },

    get isMulti() {
      return self.parentProductId || self.tokenType === 'MULTI'
    }
  }))
  .actions((self) => ({
    setHolding(value) {
      self.holding = value
    },

    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    },

    updateHeatCount() {
      self.heatCount += 1
    }
  }))

const Product = types.model('Product')
  .props({
    id: types.identifierNumber,
    licenseId: types.maybeNull(types.number),
    licenseAddress: types.maybeNull(types.string),
    licenseFee: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    displayStatus: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    currentTime: types.maybeNull(types.number),
    yenPrice: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    backgroundColor: types.maybeNull(types.string),
    imageType: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    padding: types.maybeNull(types.boolean),
    holding: types.maybeNull(types.boolean),
    rareFlg: types.maybeNull(types.boolean),
    newAuctionFlag: types.maybeNull(types.boolean),
    isNew: types.maybeNull(types.boolean),
    buying: types.maybeNull(types.boolean),
    productBid: types.maybeNull(types.model({
      status: types.maybeNull(types.string),
      startTime: types.number,
      endTime: types.number,
      biggestBidPrice: types.maybeNull(types.number),
      biggestBidPriceYen: types.maybeNull(types.number)
    })),
    reselling: types.maybeNull(types.boolean),
    canOfferFlag: types.maybeNull(types.boolean),
    tokenType: types.maybeNull(types.string),
    tokenId: types.maybeNull(types.number),
    offerNftTransactionStatus: types.maybeNull(types.string),
    offerHighestPrice: types.maybeNull(types.number),
    offerHighestPriceCurrency: types.maybeNull(types.string),
    offerHighestYenPrice: types.maybeNull(types.number),
    isUserWished: types.maybeNull(types.boolean),
    wishCount: types.maybeNull(types.number),
    childIndex: types.maybeNull(types.number),
    typeSale: types.maybeNull(types.string),
    parentProductInfo: types.maybeNull(types.model({
      countChild: types.number,
      imageUrl: types.string,
      name: types.maybeNull(types.string),
      remainingChildProductNumber: types.maybeNull(types.number)
    })),
    countChild: types.maybeNull(types.number),
    remainingChildProductNumber: types.maybeNull(types.number),
    childPrice: types.maybeNull(types.number),
    yenChildPrice: types.maybeNull(types.number),
    displaySize: types.maybeNull(types.string),
    eventTypeFlag: types.maybeNull(types.boolean),
    artistNickname: types.maybeNull(types.string),
    tokensAmount: types.maybeNull(types.number),
    soldTokens: types.maybeNull(types.number),
    quantityOwned: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number)
  })
  .actions((self) => ({
    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    }
  }))

const Products = types.model('Products')
  .props({
    items: types.array(Product),
    page: types.number,
    total: types.number,
    loaded: types.maybeNull(types.boolean)
  })
  .actions((self) => ({
    reverseLoaded() {
      self.loaded = false
    }
  }))

const ImageType = types.model('ImageType')
  .props({
    id: types.maybeNull(types.identifierNumber),
    name: types.maybeNull(types.string)
  })

const ProductsStore = Model.named('ProductsStore')
  .props({
    categories: types.array(Category),
    homePageLatestOffset: types.maybeNull(types.number),
    randomNumber: types.number,
    newProducts: Products,
    hightlightEventProduct: types.maybeNull(Product),
    eventProducts: Products,
    myProducts: Products,
    mySellProducts: types.array(Product),
    mySoldProducts: types.array(Product),
    myOfferProducts: types.array(Product),
    myProductsWish: Products,
    myBidProducts: Products,
    currentProductDetails: types.maybeNull(ProductDetails),
    currentChildProductDetails: types.maybeNull(ProductDetails),
    childProductHistories: types.model({
      items: types.array(ChildProductHistory),
      page: types.number,
      total: types.number
    }),
    myProductsCreated: Products,
    listImageType: types.array(ImageType),
    listProductAttributesCategroy: types.model({
      items: types.array(ImageType),
      page: types.number,
      total: types.number
    }),
    productDetails1155: types.maybeNull(ProductDetails1155),
    myProductsErc1155: Products
  })
  .actions((self) => ({
    setHomePageLatestOffset(offset) {
      self.homePageLatestOffset = offset
    },

    regenerateRandomNumber() {
      self.randomNumber = Math.floor(Math.random() * 999)
    },

    getProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCTS,
        api: getProducts,
        payload,
        onSuccess: (result) => {
          const items = result.result?.userProductsInfo.map((item, index) => ({
            ...item,
            isNew: moment(item.currentTime).diff(moment(item.createdAt), 'h') <= NEW_RANGE_HOURS,
            size: index === 0 && result.page === 1 ? 'double' : null,
            // padding: !item.licenseId,
            padding: false,
            backgroundColor: item.productAttributes.find((attribute) => attribute.type === 'COLOR')?.value,
            imageType: item.productAttributes.find((attribute) => attribute.type === 'IMAGE_TYPE')?.name
          }))
          self.newProducts = {
            items: concat ? self.newProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
            loaded: true
          }
        }
      })
    },

    getEventProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_EVENT_PRODUCTS,
        api: getEventProducts,
        payload,
        onSuccess: (result) => {
          let items = self.parseProducts(result.result)

          if (result.page === 1 && items[0]) {
            // eslint-disable-next-line prefer-destructuring
            self.hightlightEventProduct = {
              ...items[0],
              description: null
            }
          }
          if (result.page === 1) {
            items = items.slice(1, items.length)
          }

          self.eventProducts = {
            items: concat ? self.eventProducts.items.concat(items) : items,
            page: result.page,
            total: result.total
          }
        }
      })
    },

    getMyProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_PRODUCTS,
        api: getMyProducts,
        payload: {
          ...payload,
          findType: 'OWNED'
        },
        onSuccess: (result) => {
          // const items = self.parseProducts(result.result)
          self.myProducts = {
            // items: concat ? self.myProducts.items.concat(items) : items,
            items: result.result,
            page: result.page,
            total: result.total
          }
        }
      })
    },

    getListProductOwner1155(payload, { concat }) {
      return self.request({
        type: TYPES.GET_LIST_PRODUCT_OWNER_1155,
        api: getListProductOwner1155,
        payload: {
          ...payload,
          findType: 'OWNED',
          isErc1155: true
        },
        onSuccess: (result) => {
          const items = self.parseProducts(result.result)

          self.myProductsErc1155 = {
            items: concat ? self.myProductsErc1155.items.concat(items) : items,
            page: result.page,
            total: result.total
          }
        }
      })
    },

    getMySellProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_SELL_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'RESELL'
        },
        onSuccess: (result) => {
          const items = self.parseProducts(result)

          self.mySellProducts = items
        }
      })
    },

    getMySoldProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_SOLD_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'SOLD'
        },
        onSuccess: (result) => {
          const items = self.parseProducts(result)

          self.mySoldProducts = items
        }
      })
    },

    getMyOfferProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_OFFER_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'OFFER'
        },
        onSuccess: (result) => {
          const items = self.parseProducts(result)

          self.myOfferProducts = items
        }
      })
    },

    getMyBidProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_MY_BID_PRODUCTS,
        api: getMyBidProducts,
        payload,
        onSuccess: (result) => {
          const items = self.parseProducts(result.result)

          self.myBidProducts = {
            items: concat ? self.myBidProducts.items.concat(items) : items,
            page: result.page,
            total: result.total
          }
        }
      })
    },

    getProductDetails(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_DETAILS,
        api: getProductDetails,
        payload,
        onSuccess: (result) => {
          if (!result) return null

          self.currentProductDetails = {
            ...result,
            license: !!result.license,
            categories: result.productAttributes
              .filter((attribute) => attribute.type === 'CATEGORY'),
            backgroundColor: result.productAttributes
              .find((attribute) => attribute.type === 'COLOR')?.value,
            imageType: result.productAttributes
              .find((attribute) => attribute.type === 'IMAGE_TYPE')?.name
          }

          return null
        }
      })
    },

    getProductErc1155Details(payload) {
      return self.request({
        type: TYPES.GET_PRODUCTS_ERC1155_DETAILS,
        api: getProductErc1155Details,
        payload,
        onSuccess: (result) => {
          self.productDetails1155 = result
        }
      })
    },

    setDataProductDetails(payload) {
      self.productDetails1155.setData({
        ...payload
      })
    },

    getProductCategories(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_CATEGORIES,
        api: getProductCategories,
        payload,
        onSuccess: (result) => {
          self.categories = result
        }
      })
    },

    getProductFilterData(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_FILTER_DATA,
        api: getProductFilterData,
        payload
      })
    },

    getPriceRate(payload) {
      return self.request({
        type: TYPES.GET_PRICE_RATE,
        api: getPriceRate,
        payload
      })
    },

    getPriceChartData(payload) {
      return self.request({
        type: TYPES.GET_PRICE_CHART_DATA,
        api: getPriceChartData,
        payload
      })
    },

    getTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_TRANSACTION_HISTORIES,
        api: getTransactionHistories,
        payload
      })
    },

    parseProducts(items) {
      return items.map((item) => ({
        ...item,
        isNew: moment(item.currentTime).diff(moment(item.createdAt), 'h') <= NEW_RANGE_HOURS,
        padding: false,
        backgroundColor: item.productAttributes.find((attribute) => attribute.type === 'COLOR')?.value,
        imageType: item.productAttributes.find((attribute) => attribute.type === 'IMAGE_TYPE')?.name
      }))
    },

    createInteraction(payload) {
      return self.request({
        type: TYPES.CREATE_INTERACTION,
        api: createInteraction,
        payload,
        disabledErrorMessage: true,
        onSuccess: (result) => {
          if (payload?.actionCode === 'WISH') {
            self.currentProductDetails.isUserWished = !self.currentProductDetails.isUserWished
            self.currentProductDetails.wishCount = result
          }
          return null
        }
      })
    },

    createInteraction1155(payload) {
      return self.request({
        type: TYPES.CREATE_INTERACTION,
        api: createInteraction,
        payload,
        disabledErrorMessage: true,
        onSuccess: (result) => {
          if (payload?.actionCode === 'WISH') {
            self.productDetails1155.isUserWished = !self.productDetails1155.isUserWished
            self.productDetails1155.wishCount = result
          }
          return null
        }
      })
    },

    getProductChildItem(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_CHILD_ITEM,
        api: getProductChildItem,
        payload,
        onSuccess: (result) => {
          if (!result) self.currentChildProductDetails = null
          else {
            self.currentChildProductDetails = {
              ...result,
              license: !!result.license,
              categories: result.productAttributes
                .filter((attribute) => attribute.type === 'CATEGORY'),
              backgroundColor: result.productAttributes
                .find((attribute) => attribute.type === 'COLOR')?.value,
              imageType: result.productAttributes
                .find((attribute) => attribute.type === 'IMAGE_TYPE')?.name
            }
          }
        }
      })
    },

    getChildProductHistories(payload, concat) {
      return self.request({
        type: TYPES.GET_CHILD_PRODUCT_HISTORIES,
        api: getHistoriesProductChild,
        payload,
        onSuccess: (result) => {
          self.childProductHistories = {
            items: concat ? self.childProductHistories.items.concat(result.result) : result.result,
            page: result.page,
            total: result.total
          }
        }
      })
    },

    requestParentToken(payload) {
      return self.request({
        type: TYPES.REQUEST_PARENT_TOKEN,
        api: requestParentToken,
        payload
      })
    },

    validateProductChild(payload) {
      return self.request({
        type: TYPES.VALIDATE_PRODUCT_CHILD,
        api: validateProductChild,
        payload
      })
    },

    getProductsWish(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCTS_WISH,
        api: getProductsWish,
        payload,
        onSuccess: (result) => {
          const items = self.parseProducts(result?.result)

          self.myProductsWish = {
            items: concat ? self.myProductsWish?.items.concat(items) : items,
            page: result?.page,
            total: result?.total
          }
        }
      })
    },

    getMyTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_MY_TRANSACTION_HISTORIES,
        api: getMyTransactionHistories,
        payload
      })
    },

    approveForAllProduct(payload) {
      return self.request({
        type: TYPES.APPROVE_FOR_ALL,
        api: approveForAllProduct,
        payload
      })
    },

    exchangeRegister(payload) {
      return self.request({
        type: TYPES.EXCHANGE_REGISTER,
        api: exchangeRegister,
        payload
      })
    },

    exchangeConfirm(payload) {
      return self.request({
        type: TYPES.EXCHANGE_CONFIRM,
        api: exchangeConfirm,
        payload
      })
    },

    getProductPrice(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_PRICE,
        api: getProductPrice,
        payload
      })
    },

    getAuthorProductPrice(payload) {
      return self.request({
        type: TYPES.GET_AUTHOR_PRODUCT_PRICE,
        api: getAuthorProductPrice,
        payload
      })
    },
    getListImageType(payload) {
      return self.request({
        type: TYPES.GET_LIST_IMAGE_TYPE,
        api: getListImageType,
        payload,
        onSuccess: (result) => {
          self.listImageType = result.result
        }
      })
    },

    getProductsCreated(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCTS_CREATED,
        api: getProductsCreated,
        payload,
        onSuccess: (result) => {
          const items = self.parseProducts(result?.result)

          self.myProductsCreated = {
            items: concat ? self.myProductsCreated?.items.concat(items) : items,
            page: result?.page,
            total: result?.total
          }
        }
      })
    },
    createProduct(payload) {
      return self.request({
        type: TYPES.CREATE_PRODUCT,
        api: createProduct,
        payload
      })
    },

    updateProduct(payload) {
      return self.request({
        type: TYPES.UPDATE_PRODUCT,
        api: updateProduct,
        payload
      })
    },

    openSaleNft(payload) {
      return self.request({
        type: TYPES.OPEN_SALE_NFT,
        api: openSaleNft,
        payload
      })
    },

    getListProductAttributesCategory(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCT_CATEGORIES_TYPE,
        api: getProductCategoriesType,
        payload,
        onSuccess: (data) => {
          const items = data?.result
          self.listProductAttributesCategroy = {
            items: concat ? [...self.listProductAttributesCategroy?.items, items] : items,
            page: data.page,
            total: data.total
          }
        }
      })
    },

    getListColor(payload) {
      return self.request({
        type: TYPES.GET_LIST_COLOR,
        api: getListColor,
        payload,
        onSuccess: (result) => {
          self.listColor = result.result
        }
      })
    },

    getProductCreateDetail(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_CREATE_DETAIL,
        api: getProductCreateDetail,
        payload
      })
    },

    updateToken(payload) {
      return self.request({
        type: TYPES.UPDATE_TOKEN,
        api: updateToken,
        payload
      })
    },

    getListProductsRakuza(payload) {
      return self.request({
        type: TYPES.GET_LIST_PRODUCT_RAKUZA,
        api: getListProductsRakuza,
        payload
      })
    },
    
    getProductIdRemain(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_ID_REMAIN,
        api: getProductIdRemain,
        payload
      })
    }
  }))

export {
  TYPES
}
export default ProductsStore.create({
  newProducts: {
    items: [],
    page: 1,
    total: 0
  },
  randomNumber: Math.floor(Math.random() * 999),
  eventProducts: {
    items: [],
    page: 1,
    total: 0
  },
  myProducts: {
    items: [],
    page: 1,
    total: 0
  },
  mySellProducts: [],
  mySoldProducts: [],
  myOfferProducts: [],
  myBidProducts: {
    items: [],
    page: 1,
    total: 0
  },
  currentProductDetails: null,
  productDetails1155: null,
  currentChildProductDetails: null,
  myProductsWish: {
    items: [],
    page: 1,
    total: 0
  },
  childProductHistories: {
    items: [],
    page: 1,
    total: 0
  },
  myProductsCreated: {
    items: [],
    page: 1,
    total: 0
  },
  listImageType: [],
  listProductAttributesCategroy: {
    items: [],
    page: 0,
    total: 0
  },
  listColors: [],
  myProductsErc1155: {
    items: [],
    page: 1,
    total: 0
  }
})
