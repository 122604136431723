import { MainApi } from './endpoint'

export function registerBid(payload) {
  return MainApi.post('/user/purchase/product/register-bid', payload)
}

export function bid(payload) {
  return MainApi.post('/user/purchase/product/bid', payload)
}

export function cancelBid({ id, productId, ...payload }) {
  return MainApi.put(`/user/purchase/product/${productId}/cancel-bid/${id}`, payload)
}

export function cancelAllBid(productId) {
  return MainApi.put(`/user/purchase/product/${productId}/cancel-bid`)
}
