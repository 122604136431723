import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Misc from '../utils/misc';
import { Images } from '../theme';

const Div = styled.div`
  // overflow-y: auto;
  // overflow-x: hidden;
  // -webkit-overflow-scrolling: touch;
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #fff;
  /* padding-top: 152px; */

  &.gacha-page {
    background-image: url(${`${Images.NO_IMAGE}`});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: 50%;
  }
`;

class Page extends Component {
  static propTypes = {
    onEndReached: PropTypes.func,
    onScroll: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
  };

  scrollTo = offset => {
    this._div.scrollTop = offset;
  };

  getScrollOffset = () => this._div.scrollTop;

  scrollTop = () => {
    this._div.scrollTop = 0;
  };

  _onScroll = e => {
    const { onEndReached } = this.props;
    const { scrollHeight, scrollTop } = e.target;
    const offset = scrollHeight - (scrollTop + window.innerHeight + (Misc.isMobile ? 800 : 200));

    if (offset < 0 && !this._meeted) {
      this._meeted = true;

      onEndReached();
    } else if (offset >= 0) {
      this._meeted = false;
    }
  };

  componentDidMount() {
    const { onEndReached, onScroll } = this.props;

    const docs = document.getElementById('web-body');

    if (docs) {
      docs.onscroll = e => {
        const applicationHeight = document.getElementById('application');
        const { scrollHeight } = applicationHeight;

        const offset = scrollHeight - (e.currentTarget.scrollY + window.innerHeight + (Misc.isMobile ? 800 : 200));

        if (offset < 0 && !this._meeted) {
          this._meeted = true;
          if (onEndReached) {
            onEndReached();
          }
        } else if (offset >= 0) {
          this._meeted = false;
        }

        if (onScroll) {
          onScroll(offset);
        }
      };
    }
  }

  componentWillUnmount() {
    const docs = document.getElementById('web-body');

    if (docs) {
      docs.onscroll = null;
    }
  }

  render() {
    const { children, className, onEndReached, ...props } = this.props;

    return (
      <>
        <Div
          {...props}
          onScroll={onEndReached && this._onScroll}
          ref={ref => {
            this._div = ref;
          }}
          className={`${className ?? ''} l-wrap`}
        >
          <div className="sc-kv">
            <div className="kv-box">
              <h1 className="kv-head">
                <img src={Images.DAOSITE_TOP_KV_TOP} alt="マーケットを開放し クリエイターと共に新たな経済圏へ" />
              </h1>
            </div>
            <div className="scroll">
              <p className="txt --bold">SCROLL</p>
            </div>
            <div className="information">
              <p className="info-ttl">INFORMATION</p>
              <div className="info-box">
                <div className="info-logo">
                  <img src={Images.DAOSITE_LOGO_COLOR} alt="RAKUZA DAO" />
                </div>
                <div className="txtbox">
                  <p className="info">
                    <span>RAKUZA DAO</span>近日のロードマップを公開
                  </p>
                  <div className="txtbtn">
                    <a href="#roadmap" target="_blank">
                      ROAD MAP
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {children}
        </Div>
      </>
    );
  }
}

export default Page;
