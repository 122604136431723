import { MainApi } from './endpoint';

export function fetchNews(payload) {
  return MainApi.get('/user/news', payload);
}

export function fetchCategories(payload) {
  return MainApi.get('/user/news-category', payload);
}

export function fetchDetails({ newsId, ...payload }) {
  return MainApi.get(`/user/news/${newsId}`, payload);
}
