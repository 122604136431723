import { MainApi, MainApiRakuza } from './endpoint'

export function getProducts(payload) {
  return MainApi.get('/user/product/discover', payload)
}

export function getEventProducts(payload) {
  return MainApi.get('/user/product/event', payload)
}

export function getMyProducts(payload) {
  return MainApi.get('/user/product/owned', payload)
}

export function getMyProductsWithNoPaging(payload) {
  return MainApi.get('/user/product/list-owned', payload)
}

export function getMyBidProducts(payload) {
  return MainApi.get('/user/product/bid', payload)
}

export function getProductDetails({ id, ...payload }) {
  return MainApi.get(`/user/product/${id}`, payload)
}

export function getProductCategories(payload) {
  return MainApi.get('/product/product-attributes', { ...payload, type: 'CATEGORY' })
}

export function getProductFilterData(payload) {
  return MainApi.get('/user/product/filter', payload)
}

export function getPriceRate(payload) {
  return MainApi.get('/user/product/price-rate', payload)
}

export function getPriceChartData({ productId, ...payload }) {
  return MainApi.get(`/user/product/price-chart/${productId}`, payload)
}

export function getTransactionHistories({ productId, ...payload }) {
  return MainApi.get(`/user/product/history-order/${productId}`, payload)
}

export function createInteraction({ productId, ...payload }) {
  return MainApi.post(`/user/product/interaction/${productId}`, payload)
}

export function getProductChildItem({ productId, ...payload }) {
  return MainApi.get(`/user/product/${productId}/child/item`, payload)
}

export function validateProductChild({ productId, ...payload }) {
  return MainApi.get(`/user/product/${productId}/child/validate`, payload)
}

export function requestParentToken({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/parent-token`, payload)
}

export function getHistoriesProductChild({ productId, ...payload }) {
  return MainApi.get(`/user/product/${productId}/child`, payload)
}

export function getProductsWish({ ...payload }) {
  return MainApi.get('/user/product/interaction', payload)
}

export function getMyTransactionHistories({ ...payload }) {
  return MainApi.get('/user/product/transaction-history', payload)
}

export function approveForAllProduct({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/approval-for-all`, payload)
}

export function exchangeRegister({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/exchange-owner/register`, payload)
}

export function exchangeConfirm({ productId, productExchangeOwnerId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/exchange-owner/${productExchangeOwnerId}/confirm`, payload)
}

export function getAuthorProductPrice({ productId, ...nickname }) {
  return MainApi.get(`/user/product/price/${productId}`, nickname)
}

export function getProductPrice({ productId }) {
  return MainApi.get(`/user/product/price/${productId}`)
}

export function getListImageType(payload) {
  return MainApi.get('/user/product-attributes', { ...payload, type: 'IMAGE_TYPE' })
}

export function getProductCategoriesType(payload) {
  return MainApi.get('/user/product-attributes', { ...payload, type: 'CATEGORY' })
}

export function getProductsCreated(payload) {
  return MainApi.get('/user/product/created', payload)
}

export function createProduct(payload) {
  return MainApi.post('/user/product', payload)
}

export function getListColor(payload) {
  return MainApi.get('/user/product-attributes', { ...payload, type: 'COLOR' })
}

export function getProductCreateDetail({ id, ...payload }) {
  return MainApi.get(`/user/product/${id}/for-update`, payload)
}

export function updateProduct(payload) {
  return MainApi.put(`/user/product/modify/${payload.tokenId}`, {
    ...payload.values,
    tokenId: payload.tokenId
  })
}

export function openSaleNft(id) {
  return MainApi.put(`/user/product/issuring/${id}`)
}

export function updateToken(payload) {
  return MainApi.put(`/user/product/${payload.token}/auction`, payload.values)
}

export function getListProductsRakuza(payload) {
  return MainApiRakuza.get('/user/product/discover', payload)
}
