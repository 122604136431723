import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import { getListCategory, getCategoryDetail } from '../api/categories';

const TYPES = {
  GET_LIST_CATEGORY: 1,
  GET_CATEGORY_DETAIL: 2,
};

const Category = types.model('Category').props({
  id: types.identifierNumber,
  headerUrl: types.maybeNull(types.string),
  logoUrl: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  productCount: types.maybeNull(types.number),
});

const CategorysStore = Model.named('CategorysStore')
  .props({
    listCategory: types.array(Category),
  })
  .actions(self => ({
    getListCategory(payload) {
      return self.request({
        type: TYPES.GET_LIST_CATEGORY,
        api: getListCategory,
        payload,
        onSuccess: result => {
          self.listCategory = result?.result;
        },
      });
    },

    getCategoryDetail(payload) {
      return self.request({
        type: TYPES.GET_CATEGORY_DETAIL,
        api: getCategoryDetail,
        payload,
      });
    },
  }));

export { TYPES };
export default CategorysStore.create({
  listCategory: [],
});
