import { Model } from "../utils/mobx-model-helper"
import { types } from "mobx-state-tree"
import {
  registerSell,
  sell,
  cancelSell,
  getSellLisings,
  sell1155,
  registerSell1155,
  cancelSell1155,
} from "../api/sell"

const TYPES = {
  REGISTER_SELL: 1,
  SELL: 2,
  GET_SELL_LISTING: 3,
  SELL_1155: 4,
  REGISTER_SELL_1155: 5,
  CANCEL_SELL: 6,
  CANCEL_SELL_1155: 7,
}

const SellItem = types.model("SellItem").props({
  createdAt: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  sellerAvatar: types.maybeNull(types.string),
  sellerId: types.maybeNull(types.number),
  sellerName: types.maybeNull(types.string),
  sellerPublicAddress: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  sellerQuantity: types.maybeNull(types.number),
  resellPrice: types.maybeNull(types.number),
})

const SellStore = Model.named("SellStore")
  .props({
    sellListings: types.model({
      items: types.array(SellItem),
      page: types.number,
      total: types.number,
    }),
  })
  .actions((self) => ({
    registerSell(payload) {
      return self.request({
        type: TYPES.REGISTER_SELL,
        api: registerSell,
        payload,
        disabledErrorMessage: true,
      })
    },

    sell(payload) {
      return self.request({
        type: TYPES.SELL,
        api: sell,
        payload,
        disabledErrorMessage: true,
      })
    },

    cancelSell(payload) {
      return self.request({
        type: TYPES.CANCEL_SELL,
        api: cancelSell,
        payload,
      })
    },

    getSellLisings(payload) {
      return self.request({
        type: TYPES.GET_SELL_LISTING,
        api: getSellLisings,
        payload,
        onSuccess: (result) => {
          self.sellListings = {
            items: result.result,
            page: result.page,
            total: result.total,
          }
        },
      })
    },

    sell1155(payload) {
      return self.request({
        type: TYPES.SELL_1155,
        api: sell1155,
        payload,
        disabledErrorMessage: true,
      })
    },

    registerSell1155(payload) {
      return self.request({
        type: TYPES.REGISTER_SELL_1155,
        api: registerSell1155,
        payload,
        disabledErrorMessage: true,
      })
    },

    cancelSell1155(payload) {
      return self.request({
        type: TYPES.CANCEL_SELL_1155,
        api: cancelSell1155,
        payload,
      })
    },
  }))

export { TYPES }
export default SellStore.create({
  sellListings: {
    items: [],
    page: 0,
    total: 0,
  },
})

