/* eslint-disable no-console */

import React from 'react';
import {
  BlockSetting,
  BlockSettingMobile,
  ButtonSwapOnMoble,
  DescriptionStyled,
  ItemButton,
  ItemImage,
  ItemText,
  StatusDiv,
  Step3Styled,
  Step3WrapItemsStyled,
  StepItemsStyled,
} from './styled';
import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import MaskLoading from '@/components/mask-loading';
import Confirmable from '@/components/confirmable';
import { onCheckNetwork, onCheckPrice, onError } from './utils/checkout-normal';
import CURRENCIES from '@/constants/currencies';
import Web3 from '@/utils/web3other'
import UniswapInterfaceModal from './uniswap-interface-modal';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import i18n from '@/translations/i18n';
import { withRouter } from 'react-router-dom';
import Format from '@/utils/format';
import Storage from '@/utils/storage';
import { useWeb3Auth } from '@/contexts/web3auth/web3auth';
import { logout as logoutSystem } from '@/utils/auth';
import { Images } from '@/theme';

const Step3 = ({ formik, paymentStore, productsStore, authStore, product, closeModal, isMyPage, buyingProduct }) => {

  const {
    provider
  } = useWeb3Auth();

  const { initialData } = authStore;
  const { balanceTokenIdPartner, balanceTokenIdRkzDao } = initialData
  const { t } = i18n;


  const [amount, setAmount] = React.useState(1);
  const ref = React.useRef(null)

  const [productCurrentId, setProductCurrentId] = React.useState('')

  React.useEffect(() => {
    (async () => {
      const { data, success } = await productsStore.getProductIdRemain({
        limit: 1
      })

      if (success) {
        const productCurrent = data.result[0]
        if (productCurrent) {
          setProductCurrentId(productCurrent.id)
          await productsStore.getProductErc1155Details({
            id: productCurrent.id,
            langKey: i18n.language.toUpperCase()
          })
        } else {
          return
        }

      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openUniswapt = async () => {
    ref.current.open({
      price: product.price * amount
    })
  }

  const _onValidate = async (product, _onCheckoutAfterValidate) => {
    const loginBy = Storage.get('LONGIN_BY')

    const { usdtBalance, wethBalance } = authStore.initialData
    let isEnoughBalance = true

    if (product.currency === CURRENCIES.ETH) {
      if (product.price * amount > wethBalance) isEnoughBalance = false
    } else if (product.currency === CURRENCIES.USDT) {
      if (product.price > usdtBalance) isEnoughBalance = false
    }

    if (!isEnoughBalance) {
      throw new Error('BALANCE_NOT_ENOUGH')
    }
    if (!(!window.ethereum && loginBy === 'SOCIAL')) {
      await onCheckNetwork()
    }

    const result = await onCheckPrice(product, productsStore, t, _onCheckoutAfterValidate)


    if (!result) {
      throw new Error('ERROR_RETRY_PURCHASE')
    }
  }

  const _getProductErc1155AfterBuyOrSwap = async () => {
    if (isMyPage) {

      await authStore.getInitialData()
      await authStore.getBalanceNftByTokenId()
      await productsStore.getProductErc1155Details({
        id: productCurrentId,
        langKey: i18n.language.toUpperCase()
      })
      setProductCurrentId('')
    } else {
      const { success } = await authStore.registerMember({
        ...formik.values
      })

      if (success) {
        await authStore.getInitialData()
        await authStore.getBalanceNftByTokenId()
        await productsStore.getProductErc1155Details({
          id: productCurrentId,
          langKey: i18n.language.toUpperCase()
        })
        setProductCurrentId('')
      }
    }
  }

  const resetInitalData = async () => {
    await authStore.getInitialData()
    await authStore.getBalanceNftByTokenId()
    await productsStore.getProductErc1155Details({
      id: productCurrentId,
      langKey: i18n.language.toUpperCase()
    })
    setProductCurrentId('')
  }


  const _onCheckoutAfterValidate = async (product) => {
    console.log("loadd ing");
    try {
      MaskLoading.open({
        message: (
          <>
            {t('inprocessing', { ns: "common" })}
            <br />
            {t('pls_waiting', { ns: "common" })}
          </>
        )
      })
      const { publicAddress } = authStore.initialData

      const paymentAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155

      const Instance = await Web3.getWeb3Instance()

      const blockNumber = await Instance.web3.eth.getBlockNumber() - 1

      const amountMax = process.env.REACT_APP_AMOUNT_MAX
      const gasPrice = await Instance.web3.eth.getGasPrice()

      const contractErc20 = Instance[product.currency === 'USDT'
        ? 'usdtContract' : 'wethContract']

      const allowance = await Web3[product.currency === CURRENCIES.USDT
        ? 'allowanceUsdt' : 'allowanceWeth'](
          publicAddress,
          paymentAddress
        )

      if (allowance >= product.price) {

        const { success, data } = await paymentStore.purchareProduct1155({
          productId: product.id,
          amountPay: amount * product.price,
          amountBuy: amount,
          typeExchange: 'BUY_NORMAL'
        })

        if (success) {
          MaskLoading.close()
          !isMyPage && await _getProductErc1155AfterBuyOrSwap()
          if (isMyPage) {
            closeModal()
          }
          Confirmable.open({
            content: t("buy_nft_success", { ns: "common" }),
            hideCancelButton: true,
            onOk: () => isMyPage ? resetInitalData() : null,
            onClose: () => isMyPage ? resetInitalData() : null
          })
        } else {
          Confirmable.open({
            content: data.error,
            hideCancelButton: true,
            otherFunctionHandle: () => { window.location.reload() }
          })
        }
      } else {
        if (allowance !== 0) {
          const firstNonce = await Instance.web3.eth.getTransactionCount(publicAddress)

          await contractErc20.methods.approve(paymentAddress, 0)
            .send({
              from: publicAddress,
              gasPrice: Math.floor(gasPrice * 1.2)
            }, async (error, hash) => {
              if (error) {
                return error
              }
              let startTime = new Date().getTime()
              let approveHash
              let hashResult
              let limitTimeFlg = true

              do {
                hashResult = await Web3.checkTransactionIsConfirming(
                  hash,
                  firstNonce,
                  product.currency,
                  publicAddress,
                  null,
                  null,
                  blockNumber
                )

                if (hashResult.cancelFlg) {
                  limitTimeFlg = new Date().getTime() <= (startTime + (45 * 1000))
                }
                approveHash = await hashResult.hash
              }
              while (!approveHash && limitTimeFlg)

              if (!approveHash) {
                Confirmable.open({
                  content: t('error-messages:SOMETHING_WENT_WRONG'),
                  hideCancelButton: true
                })
                throw new Error('SOMETHING_WENT_WRONG')
              }

              const nonce = await Instance.web3.eth.getTransactionCount(publicAddress)
              await contractErc20.methods.approve(paymentAddress, amountMax)
                .send({
                  from: publicAddress,
                  gasPrice: Math.floor(gasPrice * 1.2)
                }, async (newError, newHash) => {
                  if (newError) {
                    return newError
                  }
                  let approveHashNew

                  let startTimeNew = new Date().getTime()
                  let hashResultNew
                  let limitTimeFlgNew = true
                  do {
                    hashResultNew = await Web3.checkTransactionIsConfirming(
                      newHash,
                      nonce,
                      product.currency,
                      publicAddress,
                      null,
                      null,
                      blockNumber
                    )

                    if (hashResultNew.cancelFlg) {
                      limitTimeFlgNew = new Date().getTime() <= (startTimeNew + (45 * 1000))
                    }

                    approveHashNew = await hashResultNew.hash
                  }
                  while (!approveHashNew && limitTimeFlgNew)

                  const newAllowance = await Web3[product.currency === CURRENCIES.USDT
                    ? 'allowanceUsdt' : 'allowanceWeth'](
                      publicAddress,
                      paymentAddress
                    )
                  if (!approveHash) {
                    MaskLoading.close()
                    Confirmable.open({
                      content: (
                        <>
                          {t('TRANSACTION_CANCEL', { ns: "error-messages" })}
                        </>
                      ),
                      hideCancelButton: true
                    })
                    throw new Error('TRANSACTION_CANCEL')
                  } else if (newAllowance < product.price && approveHash) {
                    MaskLoading.close()
                    Confirmable.open({
                      content: (
                        <>
                          {t('error-messages:NOT_ENOUGHT_MONEY_APPROVAL')}
                        </>
                      ),
                      hideCancelButton: true
                    })
                    throw new Error('NOT_ENOUGHT_MONEY_APPROVAL')
                  } else if (approveHash) {
                    MaskLoading.open({
                      message: (
                        <>
                          {t('inprocess_confirm', { ns: "common" })}
                          <br />
                          {t('pls_wait', { ns: "common" })}
                        </>
                      )
                    })
                    const { success, data } = await paymentStore.purchareProduct1155({
                      productId: product.id,
                      amountPay: amount * product.price,
                      amountBuy: amount,
                      typeExchange: 'BUY_NORMAL'
                    })
                    if (success) {
                      MaskLoading.close()
                      !isMyPage && await _getProductErc1155AfterBuyOrSwap()
                      if (isMyPage) {
                        closeModal()
                      }
                      Confirmable.open({
                        content: (
                          <>
                            {t('Buy success')}
                          </>
                        ),
                        hideCancelButton: true,
                        onOk: () => isMyPage ? resetInitalData() : null,
                        onClose: () => isMyPage ? resetInitalData() : null
                      })
                    } else {
                      Confirmable.open({
                        content: data.error,
                        hideCancelButton: true,
                        otherFunctionHandle: () => { window.location.reload() }
                      })
                    }
                    MaskLoading.close()
                  }
                })
            })

          return
        }

        if (allowance === 0) {
          const nonce = await Instance.web3.eth.getTransactionCount(publicAddress)

          await contractErc20.methods.approve(paymentAddress, amountMax)
            .send({
              from: publicAddress,
              gasPrice: Math.floor(gasPrice * 1.2)
            }, async (error, hash) => {
              if (error) {
                return error
              }
              let approveHash
              let startTime = new Date().getTime()
              let hashResult
              let limitTimeFlg = true
              do {
                hashResult = await Web3.checkTransactionIsConfirming(
                  hash,
                  nonce,
                  product.currency,
                  publicAddress,
                  null,
                  null,
                  blockNumber
                )

                if (hashResult.cancelFlg) {
                  limitTimeFlg = new Date().getTime() <= (startTime + (40 * 1000))
                }

                approveHash = await hashResult.hash
              }
              while (!approveHash && limitTimeFlg)

              const newAllowance = await Web3[product.currency === CURRENCIES.USDT
                ? 'allowanceUsdt' : 'allowanceWeth'](
                  publicAddress,
                  paymentAddress
                )
              if (!approveHash) {
                MaskLoading.close()
                Confirmable.open({
                  content: (
                    <>
                      {t('TRANSACTION_CANCEL', { ns: "error-messages" })}
                    </>
                  ),
                  hideCancelButton: true
                })
                throw new Error('TRANSACTION_CANCEL')
              } else if (newAllowance < (product.price * amount) && approveHash) {
                MaskLoading.close()
                Confirmable.open({
                  content: (
                    <>
                      {t('error-messages:NOT_ENOUGHT_MONEY_APPROVAL')}
                    </>
                  ),
                  hideCancelButton: true
                })
                throw new Error('NOT_ENOUGHT_MONEY_APPROVAL')
              } else if (newAllowance >= (product.price * amount) && approveHash) {
                MaskLoading.open({
                  message: (
                    <>
                      {t('inprocess_confirm', { ns: "common" })}
                      <br />
                      {t('pls_wait', { ns: "common" })}
                    </>
                  )
                })
                const { success, data } = await paymentStore.purchareProduct1155({
                  productId: product.id,
                  amountPay: amount * product.price,
                  amountBuy: amount,
                  typeExchange: 'BUY_NORMAL'
                })

                if (success) {
                  !isMyPage && await _getProductErc1155AfterBuyOrSwap()
                  if (isMyPage) {
                    closeModal()
                  }
                  Confirmable.open({
                    content: t("buy_nft_success", { ns: "common" }),
                    hideCancelButton: true,
                    onOk: () => isMyPage ? resetInitalData() : null,
                    onClose: () => isMyPage ? resetInitalData() : null
                  })
                } else {
                  Confirmable.open({
                    content: data.error,
                    hideCancelButton: true
                  })
                }
                MaskLoading.close()
              }
            })
        }
      }
    } catch (e) {

      if (product.currency === CURRENCIES.ETH && e.message === 'BALANCE_NOT_ENOUGH') {
        Confirmable.open({
          content: t("convert_eth_to_weth", { ns: "common" }),
          hideCancelButton: true
        })
        openUniswapt()

      } else {
        onError(e, t, closeModal)
      }
      // eslint-disable-next-line no-console
      console.error(e)
    }

    MaskLoading.close()
  }

  const _onCheckout = async (product) => {
    MaskLoading.open({
      message: (
        <>
          {t('inprocessing', { ns: "common" })}
          <br />
          {t('pls_waiting', { ns: "common" })}
        </>
      )
    })
    try {
      await _onValidate(product, _onCheckoutAfterValidate)

      const { publicAddress } = authStore.initialData

      const paymentAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155

      const Instance = await Web3.getWeb3Instance()

      const amountMax = process.env.REACT_APP_AMOUNT_MAX
      const gasPrice = await Instance.web3.eth.getGasPrice()

      const blockNumber = await Instance.web3.eth.getBlockNumber() - 1
      console.log("blockNumber", blockNumber);
      const contractErc20 = Instance[product.currency === 'USDT'
        ? 'usdtContract' : 'wethContract']

      const allowance = await Web3[product.currency === CURRENCIES.USDT
        ? 'allowanceUsdt' : 'allowanceWeth'](
          publicAddress,
          paymentAddress
        )
      console.log("allowance current", allowance);
      if (allowance >= product.price) {

        const { success, data } = await paymentStore.purchareProduct1155({
          productId: product.id,
          amountPay: amount * product.price,
          amountBuy: amount,
          typeExchange: 'BUY_NORMAL'
        })

        if (success) {
          MaskLoading.close()
          !isMyPage && await _getProductErc1155AfterBuyOrSwap()
          if (isMyPage) {
            closeModal()
          }
          Confirmable.open({
            content: t("buy_nft_success", { ns: "common" }),
            hideCancelButton: true,
            onOk: () => isMyPage ? resetInitalData() : null,
            onClose: () => isMyPage ? resetInitalData() : null
          })
        } else {
          Confirmable.open({
            content: data.error,
            hideCancelButton: true,
            otherFunctionHandle: () => { window.location.reload() }
          })
        }
      } else {
        if (allowance !== 0) {
          const firstNonce = await Instance.web3.eth.getTransactionCount(publicAddress)

          await contractErc20.methods.approve(paymentAddress, 0)
            .send({
              from: publicAddress,
              gasPrice: Math.floor(gasPrice * 1.2)
            }, async (error, hash) => {
              if (error) {
                return error
              }

              let startTime = new Date().getTime()
              let approveHash
              let hashResult
              let limitTimeFlg = true

              do {
                hashResult = await Web3.checkTransactionIsConfirming(
                  hash,
                  firstNonce,
                  product.currency,
                  publicAddress,
                  null,
                  null,
                  blockNumber
                )

                if (hashResult.cancelFlg) {
                  limitTimeFlg = new Date().getTime() <= (startTime + (45 * 1000))
                }
                approveHash = await hashResult.hash
              }
              while (!approveHash && limitTimeFlg)

              if (!approveHash) {
                Confirmable.open({
                  content: t('error-messages:SOMETHING_WENT_WRONG'),
                  hideCancelButton: true
                })
                throw new Error('SOMETHING_WENT_WRONG')
              }

              const nonce = await Instance.web3.eth.getTransactionCount(publicAddress)
              await contractErc20.methods.approve(paymentAddress, amountMax)
                .send({
                  from: publicAddress,
                  gasPrice: Math.floor(gasPrice * 1.2)
                }, async (newError, newHash) => {
                  if (newError) {
                    return newError
                  }
                  console.log("newHash", newHash);
                  let approveHashNew

                  let startTimeNew = new Date().getTime()
                  let hashResultNew
                  let limitTimeFlgNew = true
                  do {
                    hashResultNew = await Web3.checkTransactionIsConfirming(
                      newHash,
                      nonce,
                      product.currency,
                      publicAddress,
                      null,
                      null,
                      blockNumber
                    )

                    if (hashResultNew.cancelFlg) {
                      limitTimeFlgNew = new Date().getTime() <= (startTimeNew + (45 * 1000))
                    }

                    approveHashNew = await hashResultNew.hash
                  }
                  while (!approveHashNew && limitTimeFlgNew)

                  const newAllowance = await Web3[product.currency === CURRENCIES.USDT
                    ? 'allowanceUsdt' : 'allowanceWeth'](
                      publicAddress,
                      paymentAddress
                    )
                  console.log("newAllowance", newAllowance);
                  if (!approveHash) {
                    MaskLoading.close()
                    Confirmable.open({
                      content: (
                        <>
                          {t('TRANSACTION_CANCEL', { ns: "error-messages" })}
                        </>
                      ),
                      hideCancelButton: true
                    })
                    throw new Error('TRANSACTION_CANCEL')
                  } else if (newAllowance < product.price && approveHash) {
                    MaskLoading.close()
                    Confirmable.open({
                      content: (
                        <>
                          {t('error-messages:NOT_ENOUGHT_MONEY_APPROVAL')}
                        </>
                      ),
                      hideCancelButton: true
                    })
                    throw new Error('NOT_ENOUGHT_MONEY_APPROVAL')
                  } else if (approveHash) {
                    MaskLoading.open({
                      message: (
                        <>
                          {t('inprocess_confirm', { ns: "common" })}
                          <br />
                          {t('pls_wait', { ns: "common" })}
                        </>
                      )
                    })
                    const { success, data } = await paymentStore.purchareProduct1155({
                      productId: product.id,
                      amountPay: amount * product.price,
                      amountBuy: amount,
                      typeExchange: 'BUY_NORMAL'
                    })
                    if (success) {
                      MaskLoading.close()

                      !isMyPage && await _getProductErc1155AfterBuyOrSwap()
                      if (isMyPage) {
                        closeModal()
                      }
                      Confirmable.open({
                        content: (
                          <>
                            {t('Buy success')}
                          </>
                        ),
                        hideCancelButton: true,
                        onOk: () => isMyPage ? resetInitalData() : null,
                        onClose: () => isMyPage ? resetInitalData() : null
                      })
                    } else {
                      Confirmable.open({
                        content: data.error,
                        hideCancelButton: true,
                        otherFunctionHandle: () => { window.location.reload() }
                      })
                    }
                    MaskLoading.close()
                  }
                })
            })

          return
        }

        if (allowance === 0) {
          const nonce = await Instance.web3.eth.getTransactionCount(publicAddress)

          await contractErc20.methods.approve(paymentAddress, amountMax)
            .send({
              from: publicAddress,
              gasPrice: Math.floor(gasPrice * 1.2)
            }, async (error, hash) => {
              if (error) {
                return error
              }
              console.log("hash", hash);
              let approveHash
              let startTime = new Date().getTime()
              let hashResult
              let limitTimeFlg = true
              do {
                hashResult = await Web3.checkTransactionIsConfirming(
                  hash,
                  nonce,
                  product.currency,
                  publicAddress,
                  null,
                  null,
                  blockNumber
                )

                if (hashResult.cancelFlg) {
                  limitTimeFlg = new Date().getTime() <= (startTime + (40 * 1000))
                }

                approveHash = await hashResult.hash
                console.log("approveHash", approveHash);
              }
              while (!approveHash && limitTimeFlg)

              const newAllowance = await Web3[product.currency === CURRENCIES.USDT
                ? 'allowanceUsdt' : 'allowanceWeth'](
                  publicAddress,
                  paymentAddress
                )
              console.log("newAllowance", newAllowance);


              if (!approveHash) {
                MaskLoading.close()
                Confirmable.open({
                  content: (
                    <>
                      {t('TRANSACTION_CANCEL', { ns: "error-messages" })}
                    </>
                  ),
                  hideCancelButton: true
                })
                throw new Error('TRANSACTION_CANCEL')
              } else if (newAllowance < (product.price * amount) && approveHash) {
                MaskLoading.close()
                Confirmable.open({
                  content: (
                    <>
                      {t('error-messages:NOT_ENOUGHT_MONEY_APPROVAL')}
                    </>
                  ),
                  hideCancelButton: true
                })
                throw new Error('NOT_ENOUGHT_MONEY_APPROVAL')
              } else if (newAllowance >= (product.price * amount) && approveHash) {
                MaskLoading.open({
                  message: (
                    <>
                      {t('inprocess_confirm', { ns: "common" })}
                      <br />
                      {t('pls_wait', { ns: "common" })}
                    </>
                  )
                })
                const { success, data } = await paymentStore.purchareProduct1155({
                  productId: product.id,
                  amountPay: amount * product.price,
                  amountBuy: amount,
                  typeExchange: 'BUY_NORMAL'
                })

                if (success) {
                  !isMyPage && await _getProductErc1155AfterBuyOrSwap()
                  if (isMyPage) {
                    closeModal()
                  }
                  Confirmable.open({
                    content: t("buy_nft_success", { ns: "common" }),
                    hideCancelButton: true,
                    onOk: () => isMyPage ? resetInitalData() : null,
                    onClose: () => isMyPage ? resetInitalData() : null
                  })
                } else {
                  Confirmable.open({
                    content: data.error,
                    hideCancelButton: true
                  })
                }
                MaskLoading.close()
              }
            })
        }
      }
    } catch (e) {

      if (product.currency === CURRENCIES.ETH && e.message === 'BALANCE_NOT_ENOUGH') {
        Confirmable.open({
          content: t("convert_eth_to_weth", { ns: "common" }),
          hideCancelButton: true,
        })
        openUniswapt()
        MaskLoading.close()

      } if (e.message === 'ERROR_RETRY_PURCHASE') {
        console.log("Retry purchase");
      } else {
        MaskLoading.close()
        onError(e, t, closeModal)
      }
      // eslint-disable-next-line no-console
      // console.error(e)
    }
  }

  const _onValidateTokenOutSide = async (typeExchange) => {

    const { publicAddress } = authStore.initialData
    const Instance = await Web3.getWeb3Instance()
    const { rakuzaDaoContract, nft1155OutSideContract } = Instance
    let balanceOfTokenId;
    const balanceRkz = await rakuzaDaoContract.methods.balanceOf(publicAddress, process.env.REACT_APP_TOKENID_DAO).call()


    const balanceRkzPartner = await nft1155OutSideContract.methods.balanceOf(publicAddress, process.env.REACT_APP_TOKENID_PARTNER).call()
    if (typeExchange === 'SWAP_RAKUZA_DAO') {
      balanceOfTokenId = balanceRkz
    } else {
      balanceOfTokenId = balanceRkzPartner
    }

    if (balanceOfTokenId <= 0 || balanceOfTokenId < amount) {
      throw new Error(t('AMOUNT_NFT1155_NOT_ENOUGHT', { ns: 'error-messages' }))
    }
  }

  const _onSwapNftToMembership = async (product, typeExchange) => {

    try {

      await _onValidateTokenOutSide(typeExchange)
      MaskLoading.open({
        message: (
          <>
            {t('inprocessing', { ns: 'common' })}
            <br />
            {t('transaction_confirm', { ns: 'common' })}
          </>
        ),
      });

      const { publicAddress } = authStore.initialData
      const paymentAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155

      const Instance = await Web3.getWeb3Instance()

      const { rakuzaDaoContract, nft1155OutSideContract } = Instance

      const gasPrice = await Instance.web3.eth.getGasPrice()
      const blockNumber = await Instance.web3.eth.getBlockNumber()

      const balanceOfRakuzaDao = await rakuzaDaoContract.methods.balanceOf(publicAddress, process.env.REACT_APP_TOKENID_DAO).call()

      const balanceOfRakuzaPartner = await nft1155OutSideContract.methods.balanceOf(publicAddress, process.env.REACT_APP_TOKENID_PARTNER).call()


      const balanceOfTokenId = typeExchange === 'SWAP_RAKUZA_DAO'
        ? balanceOfRakuzaDao
        : balanceOfRakuzaPartner
      const isApprovedRakuzaDao = await rakuzaDaoContract.methods.isApprovedForAll(publicAddress, paymentAddress).call()

      const isApprovedRakuzaPartner = await nft1155OutSideContract.methods.isApprovedForAll(publicAddress, paymentAddress).call()
      const isApproveForAll = typeExchange === 'SWAP_RAKUZA_DAO'
        ? isApprovedRakuzaDao
        : isApprovedRakuzaPartner

      if (isApproveForAll) {
        if (balanceOfTokenId >= amount) {

          const { success, data } = await paymentStore.purchareProduct1155({
            productId: product.id,
            amountPay: amount * product.price,
            amountBuy: amount,
            typeExchange
          })

          if (success) {
            !isMyPage && await _getProductErc1155AfterBuyOrSwap()
            if (isMyPage) {
              closeModal()
            }
            MaskLoading.close()
            Confirmable.open({
              content: t("swap_membership_success", { ns: "common" }),
              hideCancelButton: true,
              onOk: () => isMyPage ? resetInitalData() : null,
              onClose: () => isMyPage ? resetInitalData() : null
            })

          } else {
            Confirmable.open({
              content: data.error,
              hideCancelButton: true,
              otherFunctionHandle: () => { window.location.reload() }
            })
          }

        } else {
          Confirmable.open({
            content: t("NOT_ENOUGH_NFT_SWAP", { ns: "error-messages" }),
            hideCancelButton: true,
            otherFunctionHandle: () => { window.location.reload() }
          })
        }

      } else {
        const contractSwap = typeExchange === 'SWAP_RAKUZA_DAO'
          ? rakuzaDaoContract
          : nft1155OutSideContract

        const nonce = await Instance.web3.eth.getTransactionCount(publicAddress)

        await contractSwap.methods.setApprovalForAll(
          paymentAddress,
          true
        ).send({
          from: publicAddress,
          gasPrice: Math.floor(gasPrice * 1.2)
        }, async (err, hash) => {
          if (err) {
            return err.message
          }

          let startTime = new Date().getTime()
          let approveHash
          let hashResult
          let limitTimeFlg = true

          do {
            hashResult = await Web3.checkTransactionIsConfirming(
              hash,
              nonce,
              product.currency,
              publicAddress,
              "ApprovalForAll",
              paymentAddress,
              blockNumber
            )

            if (hashResult.cancelFlg) {
              limitTimeFlg = new Date().getTime() <= (startTime + (45 * 1000))
            }
            approveHash = await hashResult.hash
          }
          while (!approveHash && limitTimeFlg)

          if (!approveHash) {
            Confirmable.open({
              content: t('error-messages:SOMETHING_WENT_WRONG'),
              hideCancelButton: true
            })
            throw new Error('SOMETHING_WENT_WRONG')
          }
          if (approveHash) {
            MaskLoading.open({
              message: (
                <>
                  {t('inprocess_confirm', { ns: "common" })}
                  <br />
                  {t('pls_wait', { ns: "common" })}
                </>
              )
            })
            if (balanceOfTokenId >= amount) {
              const { success, data } = await paymentStore.purchareProduct1155({
                productId: product.id,
                amountPay: amount * product.price,
                amountBuy: amount,
                typeExchange
              })
              if (success) {
                !isMyPage && await _getProductErc1155AfterBuyOrSwap()
                if (isMyPage) {
                  closeModal()
                }
                MaskLoading.close()
                Confirmable.open({
                  content: t('swap_success', { ns: "common" }),
                  hideCancelButton: true,
                  onOk: () => isMyPage ? resetInitalData() : null,
                  onClose: () => isMyPage ? resetInitalData() : null
                })
              } else {
                Confirmable.open({
                  content: data.error,
                  hideCancelButton: true,
                  otherFunctionHandle: () => { window.location.reload() }
                })
              }
            } else {
              Confirmable.open({
                content: t("NOT_ENOUGH_NFT_SWAP", { ns: "error-messages" }),
                hideCancelButton: true,
                otherFunctionHandle: () => { window.location.reload() }
              })
            }

          }
        })
      }
    } catch (error) {
      console.log("start catch");
      if (error?.code === 4001) {
        Confirmable.open({
          content: t("METAMASK_DENIED_SIGNATURE", { ns: "error-messages" }),
          hideCancelButton: true
        })
      } else {
        console.log("final");
        await authStore.getBalanceNftByTokenId()
        Confirmable.open({
          content: error.message,
          hideCancelButton: true,
          otherFunctionHandle: async () => {
            closeModal()
            const { data, success } = await authStore.getBalanceNftByTokenId()
            if (success) {
              await initialData.setDataBalance({
                balanceTokenIdRkzDao: data.balance.SWAP_RAKUZA_DAO,
                balanceTokenIdPartner: data.balance.SWAP_RAKUZA_OTHER,
              })
            }
          }
        })
      }

      MaskLoading.close()
    }
  }


  const _showInforAfterCallApiPurchase = async (status, data) => {
    closeModal()
    MaskLoading.close()
    if (status) {
      await _getProductErc1155AfterBuyOrSwap()
      Confirmable.open({
        content: t("buy_nft_success", { ns: "common" }),
        hideCancelButton: true
      })
    } else {
      Confirmable.open({
        content: data?.message,
        hideCancelButton: true,
        otherFunctionHandle: () => { window.location.reload() }
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  const _onCheckOutByWeb3auth = async (product) => {
    const { publicAddress } = authStore.initialData

    const currency = product.currency

    const allowance = await provider.checkAllowance(publicAddress, currency)



    try {
      MaskLoading.open({
        message: (
          <>
            {t('Inprocessing checking approval')}
            <br />
            {t('pls_waiting', { ns: "common" })}
          </>
        )
      })

      if (+allowance.toString() >= product.price) {
        await _onValidate(product)

        const { success, data } = await paymentStore.purchareProduct1155({
          productId: product.id,
          amountPay: amount * product.price,
          amountBuy: amount,
          typeExchange: 'BUY_NORMAL'
        })

        await _showInforAfterCallApiPurchase(success, data)

      } else {

        if (+allowance.toString() !== 0 && currency !== CURRENCIES.ETH) {

          // approve to 0
          const tx = await provider.approveMoneyForExchange(publicAddress, currency)
          const { status } = tx;
          MaskLoading.open({
            message: (
              <>
                {t(`Inprocessing approval with: ${product.price * amount} ${currency}`)}
                <br />
                {t('pls_waiting', { ns: "common" })}
                <br />
                {t('Please do not reload page!')}
              </>
            )
          })

          if (status) {
            const txApr = await provider.approveMoneyForExchange(publicAddress, currency, product.price * amount)
            const { status } = txApr

            if (status) {
              const { success, data } = await paymentStore.purchareProduct1155({
                productId: product.id,
                amountPay: amount * product.price,
                amountBuy: amount,
                typeExchange: 'BUY_NORMAL'
              })
              _showInforAfterCallApiPurchase(success, data)
            } else {
              throw new Error('Transaction has been revert or other reason')
            }

          } else {
            throw new Error('Transaction has been reverted')
          }

        } else if (currency === CURRENCIES.ETH) {


          const txApr = await provider.approveMoneyForExchange(publicAddress, currency, product.price * amount)

          await _onValidate(product)
          const { status } = txApr

          if (status) {
            const { success, data } = await paymentStore.purchareProduct1155({
              productId: product.id,
              amountPay: amount * product.price,
              amountBuy: amount,
              typeExchange: 'BUY_NORMAL'
            })

            await _showInforAfterCallApiPurchase(success, data)
          } else {
            throw new Error('Transaction has been reverted')
          }
        }
      }

    } catch (error) {
      MaskLoading.close()
      const messageDefault = t('error-messages:SOMETHING_WENT_WRONG')
      Confirmable.open({
        content: error.message ? t(error.message) : messageDefault,
        hideCancelButton: true
      })

    }

  }


  const _handleBuyOrSwapToMemberShip = async (productCurrent, typeExchange) => {
    const Web3Instance = await Web3.getWeb3Instance();
    const { web3 } = Web3Instance
    const accounts = await web3?.eth?.getAccounts();
    const loginBy = Storage.get('LONGIN_BY')
    const addressLogginSocial = Storage.get('PUBLIC_ADDRESS')

    if (accounts && accounts?.length && addressLogginSocial === accounts[0]) {
      await web3?.currentProvider?.enable();
    } else {
      Confirmable.open({
        content: <>
          <div>
            You is logging by Social. Below is your private key.
          </div>
          <div>
            You can import to Metamask to loggin and continue swap member ship
          </div>
          <div>
            <span>Privatekey:</span>
            <span style={{ color: 'red' }}>
              {
                await provider.getPrivateKey()
              }
            </span>
          </div>
        </>,
        hideCancelButton: true,
        otherFunctionHandle: () => { logoutSystem() }
      })
    }

    if (loginBy === 'SOCIAL' && addressLogginSocial !== accounts[0]) {
      Confirmable.open({
        content: <>
          <div>
            You is logging by Social. Below is your private key.
          </div>
          <div>
            You can import to Metamask to loggin and active account to continue swap member ship
          </div>
          <div>
            <span>Privatekey:</span>
            <span style={{ color: 'red' }}>
              {
                await provider.getPrivateKey()
              }
            </span>
          </div>
        </>,
        hideCancelButton: true,
        // otherFunctionHandle: () => { logoutSystem() }
      })
    } else if (
      (accounts && accounts?.length && loginBy === 'SOCIAL' && addressLogginSocial === accounts[0])
      || (loginBy === 'METAMASK' && accounts && accounts?.length)) {

      if (typeExchange) {
        await _onSwapNftToMembership(productCurrent, typeExchange)
      } else {
        await _onCheckout(productCurrent)
      }
    }

  }

  return (
    <>
      {<Step3Styled>
        {productCurrentId ? <>
          <DescriptionStyled>
            メンバーシップNFTを購入します。
            <br />
            引換券をお持ちの方は引換券の交換で入手できます。
            <br />
            引換券をお持ちでない方は、お手数ですが、
            <a
              href={(process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging')
                ? `${process.env.REACT_APP_RAKUZA_NEW}/ja/category/rakuzadao_exchange/800`
                : `${process.env.REACT_APP_RAKUZA_NEW}/ja/category/rakuzadao_exchange/1857`
              }
              target="_blank"
              rel="noreferrer"
            >こちら</a>
            から先に引換券を購入下さい。
          </DescriptionStyled>

          <BlockSettingMobile>
            <div className='block_remain_token'>
              {t('remain', { ns: "common" })}: {product ?
                `${Format.price(product.soldTokens)} / ${Format.price(product.tokensAmount)}`
                : ''
              }
            </div>
            {
              product ?
                <div className="block_setting">
                  <Button
                    className="decrease_btn"
                    onClick={() => {
                      if (amount <= 1) {
                        return
                      }
                      setAmount(amount - 1)
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                  <div className="block_input">
                    <input
                      type="number"
                      min="1"
                      value={amount}
                      onChange={(e) => {
                        const { value } = e.target
                        const integerValue = value.replace(/[^0-9-]/g, '')
                        if (integerValue < 1) {
                          return
                        }
                        if (integerValue >= (product.tokensAmount - product.soldTokens)) {
                          setAmount(product.tokensAmount - product.soldTokens)
                          return
                        }
                        setAmount(integerValue)
                      }}
                      className="_input_number"
                    />
                  </div>
                  <Button
                    className="increase_btn"
                    onClick={() => {
                      setAmount(+amount + 1)
                    }}
                    disabled={(product.tokensAmount - product.soldTokens) <= amount}
                  >
                    <PlusOutlined />
                  </Button>
                </div>
                : ''
            }

          </BlockSettingMobile>

          <Step3WrapItemsStyled>
            <StepItemsStyled>
              <ItemImage><img alt="img" src={Images?.DAOSITE_TOP_LOGO_JOIN} /></ItemImage>

              <ItemText>
                初期の引換NFTを
                <br />
                お持ちの方はこちら
              </ItemText>

              <ButtonSwapOnMoble>
                <Button
                  disabled={
                    balanceTokenIdPartner <= 0
                    || balanceTokenIdPartner < amount
                    || product.soldTokens >= product.tokensAmount
                    || buyingProduct?.type === 'SWAP_RAKUZA_OTHER'
                  }
                  onClick={async () => {
                    // const loginBy = Storage.get('LONGIN_BY')
                    // if ( !window.ethereum && loginBy === 'SOCIAL') {
                    //   _onCheckOutByWeb3auth(product)
                    // } else {
                    //   _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_OTHER')
                    // }
                    await _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_OTHER')
                  }}
                >{t('exchange', { ns: "common" })}</Button>
                {
                  buyingProduct?.type === 'SWAP_RAKUZA_OTHER' &&
                  <StatusDiv>
                    {t('during_trading', { ns: 'common' })}
                  </StatusDiv>
                }
              </ButtonSwapOnMoble>
            </StepItemsStyled>

            <StepItemsStyled>
              <ItemImage>
                <img alt="img" src={Images?.DAOSITE_TOP_LOGO_JOIN} />
              </ItemImage>
              <ItemText>
                楽座販売の引換NFTを
                <br />
                お持ちの方はこちら
              </ItemText>
              <StepItemsStyled>

                <ButtonSwapOnMoble>
                  <Button
                    disabled={balanceTokenIdRkzDao <= 0
                      || balanceTokenIdRkzDao < amount
                      || product.soldTokens >= product.tokensAmount
                      || buyingProduct?.type === 'SWAP_RAKUZA_DAO'
                    }
                    onClick={async () => {
                      // const loginBy = Storage.get('LONGIN_BY')
                      // if ( !window.ethereum && loginBy === 'SOCIAL') {
                      //   _onCheckOutByWeb3auth(product)
                      // } else {
                      //   _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_DAO')
                      // }
                      await _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_DAO')
                    }}
                  >
                    {t('exchange', { ns: "common" })}</Button>
                  {
                    buyingProduct?.type === 'SWAP_RAKUZA_DAO' &&
                    <StatusDiv>
                      {t('during_trading', { ns: 'common' })}
                    </StatusDiv>
                  }
                </ButtonSwapOnMoble>

              </StepItemsStyled>
            </StepItemsStyled>

            {/* <StepItemsStyled>
          <ItemImage>
            <img alt="img" src={product?.imageUrl} />
            <div className='block_price'>
              <div className='currency_price'>
                {product.price} {product.currency}
              </div>
              <div className='price_to_jpy'>
                ~ {product.yenPrice} JPY
              </div>
            </div>
          </ItemImage>
          <ItemText>
            引換NFTをお持ち
            <br />
            でない方はこちら
          </ItemText>

          <StepItemsStyled>
          <ButtonSwapOnMoble>
            <Button
              disabled={
                product.soldTokens >= product.tokensAmount
                || buyingProduct?.type === 'BUY_NORMAL'
              }
              onClick={async () => {
                // const loginBy = Storage.get('LONGIN_BY')
                // if ( !window.ethereum && loginBy === 'SOCIAL') {
                //   _onCheckOutByWeb3auth(product)
                // } else {
                //   _handleBuyOrSwapToMemberShip(product)
                // }
                await _handleBuyOrSwapToMemberShip(product)
              }}
            >購入する</Button>
            {
                buyingProduct?.type === 'BUY_NORMAL' &&
                <StatusDiv>
                  {t('during_trading', {ns: 'common'})}
                </StatusDiv>
              }
          </ButtonSwapOnMoble>
        </StepItemsStyled>
        </StepItemsStyled> */}
          </Step3WrapItemsStyled>

          <BlockSetting>
            <div className='block_remain_token'>
              {t('remain', { ns: "common" })}: {product ?
                `${Format.price(product.soldTokens)} / ${Format.price(product.tokensAmount)}`
                : ''
              }
            </div>
            {
              product ?
                <div className="block_setting">
                  <Button
                    className="decrease_btn"
                    onClick={() => {
                      if (amount <= 1) {
                        return
                      }
                      setAmount(amount - 1)
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                  <div className="block_input">
                    <input
                      type="number"
                      min="1"
                      value={amount}
                      onChange={(e) => {
                        const { value } = e.target
                        const integerValue = value.replace(/[^0-9-]/g, '')
                        if (integerValue < 1) {
                          return
                        }
                        if (integerValue >= (product.tokensAmount - product.soldTokens)) {
                          setAmount(product.tokensAmount - product.soldTokens)
                          return
                        }
                        setAmount(integerValue)
                      }}
                      className="_input_number"
                    />
                  </div>
                  <Button
                    className="increase_btn"
                    onClick={() => {
                      setAmount(+amount + 1)
                    }}
                    disabled={(product.tokensAmount - product.soldTokens) <= amount}
                  >
                    <PlusOutlined />
                  </Button>
                </div>
                : ''
            }

          </BlockSetting>

          <Step3WrapItemsStyled>
            <StepItemsStyled>
              <ItemButton>
                <Button
                  disabled={
                    balanceTokenIdPartner <= 0
                    || balanceTokenIdPartner < amount
                    || product.soldTokens >= product.tokensAmount
                    || buyingProduct?.type === 'SWAP_RAKUZA_OTHER'
                  }
                  onClick={async () => {
                    // const loginBy = Storage.get('LONGIN_BY')
                    // if ( !window.ethereum && loginBy === 'SOCIAL') {
                    //   _onCheckOutByWeb3auth(product)
                    // } else {
                    //   _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_OTHER')
                    // }
                    await _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_OTHER')
                  }}
                >交換する</Button>
              </ItemButton>
              {
                buyingProduct?.type === 'SWAP_RAKUZA_OTHER' &&
                <StatusDiv>
                  {t('during_trading', { ns: 'common' })}
                </StatusDiv>
              }
            </StepItemsStyled>

            <StepItemsStyled>
              <ItemButton>
                <Button
                  disabled={balanceTokenIdRkzDao <= 0
                    || balanceTokenIdRkzDao < amount
                    || product.soldTokens >= product.tokensAmount
                    || buyingProduct?.type === 'SWAP_RAKUZA_DAO'
                  }
                  onClick={async () => {
                    // const loginBy = Storage.get('LONGIN_BY')
                    // if ( !window.ethereum && loginBy === 'SOCIAL') {
                    //   _onCheckOutByWeb3auth(product)
                    // } else {
                    //   _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_DAO')
                    // }
                    await _handleBuyOrSwapToMemberShip(product, 'SWAP_RAKUZA_DAO')
                  }}
                >
                  交換する</Button>
              </ItemButton>
              {
                buyingProduct?.type === 'SWAP_RAKUZA_DAO' &&
                <StatusDiv>
                  {t('during_trading', { ns: 'common' })}
                </StatusDiv>
              }
            </StepItemsStyled>

            {/* <StepItemsStyled>
          <ItemButton>
            <Button
              disabled={product.soldTokens >= product.tokensAmount ||buyingProduct?.type === 'BUY_NORMAL'}
              onClick={async () => {
                // const loginBy = Storage.get('LONGIN_BY')
                // if ( !window.ethereum && loginBy === 'SOCIAL') {
                //   await _onCheckOutByWeb3auth(product)
                // } else {
                //   await _handleBuyOrSwapToMemberShip(product)
                // }
                await _handleBuyOrSwapToMemberShip(product)
              }}
            >{t('buy', {ns: "common"})}</Button>
          </ItemButton>
          {
            buyingProduct?.type === 'BUY_NORMAL' &&
            <StatusDiv>
              {t('during_trading', {ns: 'common'})}
            </StatusDiv>
          }
        </StepItemsStyled> */}
          </Step3WrapItemsStyled>

        </> : <div
          style={{
            textAlign: 'center',
            margin: 30
          }}
        >
          {t('step3.not_have_membership', { ns: "common" })}
        </div>}

        <UniswapInterfaceModal ref={ref} />
      </Step3Styled>
      }

    </>
  );
}

export default withRouter(inject(stores => ({
  productsStore: stores.products,
  paymentStore: stores.payment,
  authStore: stores.auth,
  product: stores.products.productDetails1155,
  initialData: stores.auth.initialData,
}))(
  observer(({ productsStore, paymentStore, authStore, product, initialData, ...props }) => {

    return (
      <Step3
        {...props}
        productsStore={productsStore}
        paymentStore={paymentStore}
        authStore={authStore}
        product={{ ...product }}
        buyingProduct={initialData.buyingProduct && initialData.buyingProduct.toJSON()}
      />
    );
  }),
))
