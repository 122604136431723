/* eslint-disable no-console */
import Web3 from 'web3';

import usdtConfigs from '../../configs/dao-site/usdt_ABI.json'
import wethConfigs from '../../configs/dao-site/weth_ABI.json'

// import exchange_abi from '../../configs/dao-site/DAO_SITE_EXCHANGE.json'
import BigNumber from "bignumber.js";


const ethProvider = (provider) => {

  const contractCurrency = (currency) => {
    const web3 = new Web3(provider);

    const contractInstance = currency === 'USDT' 
    ? new web3.eth.Contract(usdtConfigs, process.env.REACT_APP_USDT_CONTRACT_ADDRESS)
    : new web3.eth.Contract(wethConfigs, process.env.REACT_APP_WETH_CONTRACT_ADDRESS)
    
    return contractInstance
  }


  const getAccounts = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();

      return accounts;
    } catch (error) {
      console.error('Error', error);
    }
  };
  
  const getCurrentChainId = async () => {
    try {
      const web3 = new Web3(provider);
      
      const chainId = await web3.eth.getChainId();

      return chainId;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getBalance = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      
      const balance = await web3.eth.getBalance(accounts[0]);
      return Number(balance) * Math.pow(10, -18);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getBalanceUsdt = async () => {
    try {
      const web3 = new Web3(provider);

      const usdtContract = new web3.eth.Contract(usdtConfigs, process.env.REACT_APP_USDT_CONTRACT_ADDRESS)
      const accounts = await web3.eth.getAccounts();

      const usdtBalance = await usdtContract.methods.balanceOf(accounts[0]).call()
    
      return Number(usdtBalance) * Math.pow(10, -6);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getBalanceWeth = async () => {
    try {
      const web3 = new Web3(provider);

      const wethContract = new web3.eth.Contract(wethConfigs, process.env.REACT_APP_WETH_CONTRACT_ADDRESS)
      const accounts = await web3.eth.getAccounts();

      const wethBalance = await wethContract.methods.balanceOf(accounts[0]).call()
    
      return Number(wethBalance) * Math.pow(10, -18);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const signMessage = async () => {
    try {
      const pubKey = await provider.request({ method: 'eth_accounts' });
      const web3 = new Web3(provider);
      const message = '0x47173285a8d7341e5e972fc677286384f802f8ef42a5ec5f03bbfa254cb01fad';
      web3.currentProvider?.send(
        {
          method: 'eth_sign',
          params: [pubKey[0], message],
          from: pubKey[0],
        },
        (err, result) => {
          if (err) {
            return err;
          }
          console.log('Eth sign message => true', result);
        },
      );
    } catch (error) {
      console.log('error', error);
      return error;
    }
  };

  const getPrivateKey = async () => {
    return await provider?.request({ method: 'eth_private_key' });
  };

  const getWalletPrivateKey = async () => {
    const web3 = new Web3(provider);

    return (await web3.eth.accounts.create())?.privateKey || '';
  };

  const approveMoneyForExchange = async (publicAddress, currency, amount = 0) => {

    const web3 = new Web3(provider);

    const ContractCurrency = currency === 'USDT' 
    ? new web3.eth.Contract(usdtConfigs, process.env.REACT_APP_USDT_CONTRACT_ADDRESS)
    : new web3.eth.Contract(wethConfigs, process.env.REACT_APP_WETH_CONTRACT_ADDRESS)
    

    const paymentAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155

    const amountCurrency = currency === 'ETH' ? BigNumber(amount * 10**18) : BigNumber(amount * 10**6)
  
    const response = await ContractCurrency
    .methods
    .approve(paymentAddress, amountCurrency.toString())
    .send({
      from: publicAddress,
    },
      function (error, transactionHash) {
        if (transactionHash) {
          console.log('transactionHash', transactionHash);
        } else {
          console.log(error);
        }
    })
    .on("receipt", async function (receipt) {
      console.log('receipt', receipt);
      return receipt
    })
    .on("error", async function (error) {
      console.log(error);
    });
    return response
  }

  const checkAllowance = async (publicAddress, currency) => {
    const ContractCurrency = contractCurrency(currency)

    const paymentAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155

    const allowanceAmount = await ContractCurrency
    .methods
    .allowance(publicAddress, paymentAddress)
    .call()
    
    const allowanceByCurreny = currency === 'ETH' 
      ? +allowanceAmount / (10**18)
      : +allowanceAmount / (10**6)

    return allowanceByCurreny
  }

  return { getAccounts, getBalance, signMessage, getPrivateKey, getWalletPrivateKey, getCurrentChainId, approveMoneyForExchange, checkAllowance, getBalanceUsdt, getBalanceWeth };
};

export default ethProvider;
