import { types } from "mobx-state-tree"

import { Model } from "../utils/mobx-model-helper"
import moment from "moment"
import { getListCreator, getCreatorDetail, getListCreatorsRakuza } from "../api/creators"

const TYPES = {
  GET_LIST_CREATOR: 1,
  GET_CREATOR_DETAIL: 2,
  GET_LIST_CREATOR_RAKUZA: 3
}

const NEW_RANGE_HOURS = 3

const Creator = types.model("Creator").props({
  id: types.identifierNumber,
  backgroundUrl: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})

const Creators = types.model("Creators").props({
  items: types.array(Creator),
  page: types.number,
  total: types.number,
})

const CreatorsStore = Model.named("CreatorsStore")
  .props({
    listCreatorTop: types.array(Creator),
    listCreator: Creators,
  })
  .actions((self) => ({
    parseProducts(items) {
      return items.map((item) => ({
        ...item,
        isNew:
          moment(item.currentTime).diff(moment(item.createdAt), "h") <=
          NEW_RANGE_HOURS,
      }))
    },

    getListCreatorTop(payload) {
      return self.request({
        type: TYPES.GET_LIST_CREATOR,
        api: getListCreator,
        payload,
        onSuccess: (result) => {
          self.listCreatorTop = result?.result
        },
      })
    },

    getCreatorDetail(payload) {
      return self.request({
        type: TYPES.GET_CREATOR_DETAIL,
        api: getCreatorDetail,
        payload,
      })
    },

    getListCreator(payload, { concat }) {
      return self.request({
        type: TYPES.GET_LIST_CREATOR,
        api: getListCreator,
        payload,
        onSuccess: (result) => {
          const items = self.parseProducts(result.result)

          self.listCreator = {
            items: concat ? self.listCreator.items.concat(items) : items,
            page: result.page,
            total: result.total,
          }
        },
      })
    },

    getListCreatorsRakuza(payload) {
      return self.request({
        type: TYPES.GET_LIST_CREATOR_RAKUZA,
        api: getListCreatorsRakuza,
        payload
      })
    },
  }))

export { TYPES }
export default CreatorsStore.create({
  listCreatorTop: [],
  listCreator: {
    items: [],
    page: 1,
    total: 0,
  },
})

