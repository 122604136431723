import { Model } from "../utils/mobx-model-helper"
import { registerBid, bid, cancelBid, cancelAllBid } from "../api/bid"

const TYPES = {
  REGISTER_BID: 1,
  BID: 2,
  CANCEL_BID: 3,
  CANCEL_ALL_BID: 4,
}

const BidStore = Model.named("BidStore").actions((self) => ({
  registerBid(payload) {
    return self.request({
      type: TYPES.REGISTER_BID,
      api: registerBid,
      payload,
      disabledErrorMessage: true,
    })
  },

  bid(payload) {
    return self.request({
      type: TYPES.BID,
      api: bid,
      payload,
      disabledErrorMessage: true,
    })
  },

  cancelBid(payload) {
    return self.request({
      type: TYPES.CANCEL_BID,
      api: cancelBid,
      payload,
      disabledErrorMessage: true,
    })
  },

  cancelAllBid(payload) {
    return self.request({
      type: TYPES.CANCEL_ALL_BID,
      api: cancelAllBid,
      payload,
    })
  },
}))

export { TYPES }
export default BidStore.create()

