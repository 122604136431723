import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'

import Confirmable from '../components/confirmable'
import abiAddressPartner from '../configs/abi-partner/ADDRESS_CONTRACT_NFT1155_PARTNER.json'
import usdtConfigs from '../configs/dao-site/usdt_ABI.json'
import wethConfigs from '../configs/dao-site/weth_ABI.json'
import rakuzaDaoErc1155Abi from '../configs/rakuza-dao/RAKUZA_DAO_ERC1155_ABI.json'
import Configs from '../configs'
import Misc from '../utils/misc'
import CURRENCIES from '../constants/currencies'

let nft1155OutSideContract
let rakuzaDaoContract
let web3Instance
let usdtContract
let wethContract
let regetContract = true
class Web3Class {
  static removeWeb3Instance = () => {
    if (web3Instance) {
      web3Instance.currentProvider?.disconnect()
      // web3Instance.currentProvider?._handleDisconnect()
      web3Instance = null
    }
  }
  
  static getWeb3Instance = async () => {
    if (Misc.isMobile && !window.ethereum) {
      if (!web3Instance) {
        try {
          const walletConnectProvider = new WalletConnectProvider({
            infuraId: process.env.REACT_APP_INFURA_ID,
            qrcode: true,
            pollingInterval: 15000,
            qrcodeModalOptions: {
              mobileLinks: [
                'metamask',
                'trust'
              ]
            }
          })

          web3Instance = new Web3(walletConnectProvider)
        } catch (error) {
          return Confirmable.open({
            // content: 'You need to allow MetaMask.',
            content: 'MetaMaskを許可する必要があります。',
            hideCancelButton: true
          })
        }

        regetContract = true
      }
    }  else {
      if (!window.ethereum) {
        return Confirmable.open({
          // content: 'Please install MetaMask first.',
          content: (
            <>
              PCからアクセスの場合：ブラウザのMetaMaskの拡張機能をインストールしてください。
              <br />
              <br />
              モバイルからのアクセスの場合：MetaMaskアプリ内のブラウザからアクセスしてください。
            </>
          ),
          hideCancelButton: true
        })
      }

      if (!web3Instance) {
        try {
          web3Instance = new Web3(window.ethereum)
        } catch (error) {
          return Confirmable.open({
            content: 'MetaMaskを許可する必要があります。',
            hideCancelButton: true
          })
        }

        regetContract = true
      }
    }
    
    if (regetContract && web3Instance) {
      usdtContract = new web3Instance.eth.Contract(
        usdtConfigs,
        process.env.REACT_APP_USDT_CONTRACT_ADDRESS
      )
      wethContract = new web3Instance.eth.Contract(
        wethConfigs,
        process.env.REACT_APP_WETH_CONTRACT_ADDRESS
      )
      rakuzaDaoContract = new web3Instance.eth.Contract(
        rakuzaDaoErc1155Abi,
        process.env.REACT_APP_RAKUZA_DAO_CONTRACT_ADDRESS_1155
      )
      nft1155OutSideContract= new web3Instance.eth.Contract(
        abiAddressPartner,
        process.env.REACT_APP_ADDRESS_CONTRACT_NFT1155_PARTNER
      )

      regetContract = false
    }

    return {
      web3: web3Instance,
      wethContract,
      usdtContract,
      rakuzaDaoContract,
      nft1155OutSideContract
    }
  }

  static getBalance = async (publicAddress) => {
    const instance = await Web3Class.getWeb3Instance()
    try {
      const ethBalance = await instance.web3.eth.getBalance(publicAddress)
      const wethBalance = await instance.wethContract.methods.balanceOf(publicAddress).call()
      const usdtBalance = await instance.usdtContract.methods.balanceOf(publicAddress).call()
      
      return {
        eth: +instance.web3.utils.fromWei(ethBalance, 'ether') || 0,
        weth: +instance.web3.utils.fromWei(wethBalance, 'ether') || 0,
        usdt: +instance.web3.utils.fromWei(usdtBalance, 'mwei') || 0
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    return {}
  }

  static checkEnoughBalance = async (currency, price, publicAddress) => {
    const { nbng, goku, eth, weth, usdt } = await Web3Class.getBalance(publicAddress)

    if (currency === CURRENCIES.NBNG) {
      if (price > nbng) return false
    } else if (currency === CURRENCIES.GOKU) {
      if (price > goku) return false
    } else if (currency === CURRENCIES.ETH) {
      if (price > eth) return false
    } else if (currency === CURRENCIES.WETH) {
      if (price > weth) return false
    } else if (currency === CURRENCIES.USDT) {
      if (price > usdt) return false
    }

    return true
  }

  static setupNetwork = async () => {
    const { web3 } = await Web3Class.getWeb3Instance()

    if (!web3) return false
    const currentChainId = await web3.eth.getChainId()
    const chainId = parseInt(`${process.env.REACT_APP_CHAIN || '11155111'}`, 10)
    if (currentChainId !== chainId) {
      try {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(chainId) }]
        })
        return true
      } catch (switchError) {
        if (switchError.code === 4001) {
          // notify({
          //   type: "error",
          //   title: switchError.message,
          //   content: '',
          //   duration: 4
          // })
        }

        if (switchError.code === 4902) {
          try {
            await web3.currentProvider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(chainId),
                  chainName: process.env.NAME_CHAIN,
                  nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'ETH',
                    decimals: 18
                  },
                  rpcUrls: [process.env.RPC_URL],
                  blockExplorerUrls: [process.env.ETHERSCAN_DOMAIN]
                }
              ]
            })
            return true
          } catch (error) {
            return false
          }
        }
        return false
      }
    }
    return true
  }

  static checkValidNetwork = async () => {
    const { web3 } = await Web3Class.getWeb3Instance()
    const chainId = await web3.eth.getChainId()
    // eslint-disable-next-line no-console
    console.log('chainId', chainId , process.env.REACT_APP_ENV);
    if (
      (['development', 'local', 'staging'].includes(process.env.REACT_APP_ENV) && +chainId === 11155111)
      || (['production'].includes(process.env.REACT_APP_ENV) && +chainId === 1)
    ) {
      return true
    }

    return false
  }

  static sign = async (nonce, publicAddress) => {
    const { web3 } = await Web3Class.getWeb3Instance()

    const message = `I am signing my one-time nonce: ${nonce}`
    const signature = await web3.eth.personal.sign(
      message,
      publicAddress,
      ''
    )

    return signature
  }

  static requireApproveOnMobile = async (t) => {
    if (Misc.isMobile) {
      await Confirmable.open({
        content: t('product_details:you_signed'),
        hideCancelButton: true,
        acceptButtonText: t('common:ok')
      })
    }
  }

  static setApprovalForAll = async (publicAddress, paymentAddress, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.erc721contract.methods.setApprovalForAll(paymentAddress, true)
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return approveHash
  }

  static approveErc721 = async (publicAddress, paymentAddress, tokenID, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.erc721contract.methods.approve(paymentAddress, tokenID.toString())
        .send({
          from: publicAddress,
          to: '0xBE610e80749587D0c4f90B11611c69Eb04f5E184'
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })
    
    return approveHash
  }

  static approveNbng = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.contract.methods.approve(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'ether'))
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return approveHash
  }

  static approveGoku = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.gokuContract.methods.approve(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'mwei'))
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return approveHash
  }

  static approveUSDT = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.usdtContract.methods.approve(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'mwei'))
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return approveHash
  }

  static approveWeth = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const approveHash = await new Promise((resolve, reject) => {
      instance.wethContract.methods.approve(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'ether'))
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return approveHash
  }

  static allowanceNbng = async (publicAddress, paymentAddress) => {
    const instance = await Web3Class.getWeb3Instance()

    const allowance = await instance.contract.methods.allowance(publicAddress, paymentAddress).call()

    return +allowance / (10 ** 18)
  }

  static allowanceUsdt = async (publicAddress, paymentAddress) => {
    const instance = await Web3Class.getWeb3Instance()

    const allowance = await instance.usdtContract.methods.allowance(publicAddress, paymentAddress).call()
    return +allowance / (10 ** 6)
  }

  static allowanceGoku = async (publicAddress, paymentAddress) => {
    const instance = await Web3Class.getWeb3Instance()

    const allowance = await instance.gokuContract.methods.allowance(publicAddress, paymentAddress).call()

    return +allowance / (10 ** 6)
  }

  static allowanceWeth = async (publicAddress, paymentAddress) => {
    const instance = await Web3Class.getWeb3Instance()

    const allowance = await instance.wethContract.methods.allowance(publicAddress, paymentAddress).call()

    return +allowance / (10 ** 18)
  }

  static transferEth = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const transactionHash = await new Promise((resolve, reject) => {
      instance.web3.eth.sendTransaction({
        from: publicAddress,
        to: paymentAddress,
        value: instance.web3.utils.toWei(price.toString(), 'ether')
      }, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })

    return {
      transactionHash
    }
  }

  static transferNbng = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const transactionHash = await new Promise((resolve, reject) => {
      instance.contract.methods.transfer(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'ether'))
        .send({
          from: publicAddress,
          gas: 200000
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return {
      transactionHash,
      contract: instance.contract
    }
  }

  static transferGoku = async (publicAddress, paymentAddress, price, t) => {
    const instance = await Web3Class.getWeb3Instance()

    await Web3Class.requireApproveOnMobile(t)

    const transactionHash = await new Promise((resolve, reject) => {
      instance.gokuContract.methods.transfer(paymentAddress, instance.web3.utils
        .toWei(price.toString(), 'mwei'))
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return {
      transactionHash,
      contract: instance.gokuContract
    }
  }

  static approveMax = async (publicAddress, t, currency) => {
    const instance = await Web3Class.getWeb3Instance()
    const contractExchangeAddress = Configs.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155
    const amountMax = Configs.AMOUNT_MAX
    await Web3Class.requireApproveOnMobile(t)

    const contract = instance[currency === 'USDT' ? 'usdtContract' : 'wethContract']

    const transactionId = await new Promise((resolve, reject) => {
      contract.methods.approve(contractExchangeAddress, amountMax)
        .send({
          from: publicAddress
        }, (error, result) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }
        })
    })

    return transactionId
  }

  static sendTransactionERC20 = async (publicAddress, data, gasLimit) => {
    const instance = await Web3Class.getWeb3Instance()
    const contractExchangeAddress = Configs.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155
    const transactionHash = await new Promise((resolve, reject) => {
      instance.web3.eth.sendTransaction({
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit
      }, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })
    return transactionHash
  }

  static sendTransactionETH = async (publicAddress, data, gasLimit, amount) => {
    const instance = await Web3Class.getWeb3Instance()
    const contractExchangeAddress = Configs.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155
    const transactionHash = await new Promise((resolve, reject) => {
      instance.web3.eth.sendTransaction({
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit,
        value: amount * 10 ** 18
      }, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })

    return transactionHash
  }

  static excuteTransaction = async (publicAddress, t, data, gasLimit = 300000, currency, amount) => {
    await Web3Class.requireApproveOnMobile(t)
    let transactionHash

    currency === 'ETH'
      ? transactionHash = await Web3Class.sendTransactionETH(publicAddress, data, gasLimit, amount)
      : transactionHash = await Web3Class.sendTransactionERC20(publicAddress, data, gasLimit)
    return transactionHash
  }

  static returnHashNew = (newHash) => new Promise((resolve) => setTimeout(() => resolve(newHash), newHash ? 0 : 4000))

  static checkTransactionIsConfirming = async (
    hash,
    nonce,
    currency,
    publicAddress,
    typeEvent = null,
    addressExchange = null,
    blockNumber
  ) => {
    const instance = await Web3Class.getWeb3Instance()

    const contractErc20 = instance[currency === 'USDT'
      ? 'usdtContract' : 'wethContract']

    const addressErc20 = currency === 'USDT' 
      ? process.env.REACT_APP_USDT_CONTRACT_ADDRESS
      : process.env.REACT_APP_WETH_CONTRACT_ADDRESS

    const txx = await instance.web3.eth.getTransaction(hash)
    // cancel or speed up
    if (txx == null) {
      // check case speed up
      let hashIfSpeedUp = null
      let events
      if (addressExchange === process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS_1155) {
        events = await instance.rakuzaContract.getPastEvents(typeEvent || 'ApprovalForAll', {
          filter: { from: publicAddress },
          fromBlock: blockNumber || 0,
          toBlock: 'latest',
          address: addressExchange
        })
      } else {
        events = await contractErc20.getPastEvents(typeEvent || 'Approval', {
          filter: { from: publicAddress },
          fromBlock: blockNumber || 0,
          toBlock: 'latest',
          address: addressErc20
        })
      }
      const eventsReverse = events.reverse()
      let i = 0
      for (i; i < eventsReverse.length; i++) {
        const tx = await instance.web3.eth.getTransaction(eventsReverse[i].transactionHash)
        if (+tx.nonce === +nonce) {
          hashIfSpeedUp = tx.hash
          break
        } else if (+tx.nonce < +nonce) {
          break
        }
      }

      return { hash: Web3Class.returnHashNew(hashIfSpeedUp), cancelFlg: true }
    }
    let hashResult = null
    await instance.web3.eth.getTransactionReceipt(hash)
      .then(async (data) => {
        if (data) {
          hashResult = Web3Class.returnHashNew(data.transactionHash)
        }
        return hashResult
      })
    return { hash: Web3Class.returnHashNew(hashResult), cancelFlg: false }
  }
}

export default Web3Class
