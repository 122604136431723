import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import React from 'react';

import { Colors } from '../theme';
import Media from '../utils/media';

const StyledButton = styled(AntButton)`
  height: 42px;
  border-radius: 9999px;
  padding: 0 33px;
  border: none;
  background-color: ${props => props.background || Colors.PRIMARY};
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: ${props => Colors.lighten(props.background || Colors.PRIMARY, 10)};
    color: #212330;
  }

  &:active {
    background-color: ${props => Colors.darken(props.background || Colors.PRIMARY, 10)};
  }

  &:focus {
    background-color: ${props => props.background || Colors.PRIMARY};
    color: #212330;
  }

  &.blue {
    background-color: ${Colors.BLUE_1};
    border-color: ${Colors.BLUE_1};
    color: #ffffff;
    &:hover,
    &:focus,
    &:active {
      background-color: ${Colors.lighten(Colors.BLUE_1, 10)};
      border-color: ${Colors.lighten(Colors.BLUE_1, 10)};
      color: #ffffff;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: #cccccc !important;
    background-color: #7a7a7a !important;
    span {
      color: #cccccc !important;
    }
  }

  &.large {
    height: 57px;
    font-size: 16px;
    padding: 0 46px;
  }

  &.white-red {
    color: #DA1D31;
    border: 1px solid #DA1D31;
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    font-weight: bold;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    height: 38px;
    padding: 0 26px;
    font-size: 12px;

    &.large {
      height: 47px;
      font-size: 14px;
    }
  }
`;

const Button = ({ children, size, className, ...props }) => (
  <StyledButton {...props} className={classnames(size, className, 'bold')}>
    {children}
  </StyledButton>
);
Button.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Button;
