import { MainApi } from './endpoint'

export function purchareProduct(payload) {
  return MainApi.post('/user/purchase', payload)
}

export function checkAllowPurchase({ productId, ...payload }) {
  return MainApi.put(`/user/purchase/check-product/${productId}`, payload)
}

export function confirmPurchaseProduct(payload) {
  return MainApi.post('/user/purchase/confirm', payload)
}

export function cancelPurchaseProduct({ productId, orderId }) {
  return MainApi.put(`/user/product/${productId}/disable/${orderId}`)
}

export function cancelPurchaseProductNormal(payload) {
  return MainApi.post(`/user/purchase/cancel`, payload)
}

export function purchareProduct1155(payload) {
  return MainApi.post('/user/purchase', payload)
}

export function creditCardPayment({ productId, langKey }) {
  return MainApi.post(`/user/product/${productId}/checkout/credit?langKey=${langKey}`)
}

export function paymentWithSlash({ productId }) {
  return MainApi.post(`/user/product/${productId}/checkout/crypto`)
}

export function paymentPoint(payload) {
  return MainApi.post(`/user/product/${payload?.productId}/checkout/point`, payload)
}
