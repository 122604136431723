import React, { Component } from 'react';
import styled from 'styled-components';

import Typography from '../components/typography';
import Loading from '../components/loading';

const StyledDiv = styled.div`
  /* position: absolute; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000069;
  /* z-index: 2; */
  z-index: ${ ({zIndex}) => zIndex ? 1000 : 9000};
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);

  .content {
    padding: 50px;
    border-radius: 10px;

    .message {
      color: white;
    }
  }
`;

let instance;

class MaskLoading extends Component {
  state = {
    isOpen: false,
    message: null,
    zIndex: null
  };

  static setInstance = ref => {
    instance = ref;
  };

  static open = (...params) => {
    if (instance) {
      return instance.open(...params);
    }

    return null;
  };

  static close = () => {
    if (instance) instance.close();
  };

  open = ({ message,zIndex }) => {
    this.setState({
      isOpen: true,
      message,
      zIndex
    });
  };

  close = () => this.setState({ isOpen: false });

  render() {
    const { isOpen, message, zIndex } = this.state;

    if (!isOpen) return null;

    return (
      <StyledDiv zIndex={zIndex}>
        <div className="content">
          <Loading color="white" />
          {message && (
            <Typography className="message" size="big" bold center>
              {message}
            </Typography>
          )}
        </div>
      </StyledDiv>
    );
  }
}

export default MaskLoading;
