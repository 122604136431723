import Media from '@/utils/media';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  &.ant-modal {
    top: 50px;
    .ant-modal-content {
      border-radius: 20px;
      overflow: hidden;
      padding: 24px 20px 0px 20px;

      .ant-modal-header {
        border-bottom: none;
      }
    }

    .ant-modal-body {
      /* min-height: 200px; */
    }
  }
`;

export const ModalStyledChild = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-body {
    padding-top: 40px;
  }
  .description_1 {
    font-size: 18px;
    max-width: 440px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
  }
  .description_2 {
    font-size: 18px;
    max-width: 540px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
  }

  .block_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;
    .btn_cancel {
      background-color: transparent;
      border-radius: 30px;
      color: black;
      border: 1px solid black;
      height: 42px;
      padding: 10px;
      min-width: 110px;
      text-align: center;
    }
    .btn_go {
      background-color: black;
      border-radius: 30px;
      color: #fff;
      height: 42px;
      padding: 10px;
      min-width: 110px;
      text-align: center;
      margin-left: 20px;
    }
  }
`

export const TitleStyled = styled.div`
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  padding-top: 30px;
  ${Media.lessThan(Media.SIZE.MD)} {
    line-height: 2.5rem;
    padding-top: 0px;
    font-size: 1.5rem;
  }
`;

export const TopTextStyled = styled.div`
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
  padding-top: 40px;
  ${Media.lessThan(Media.SIZE.MD)} {
    padding-top: 0px;
  }

  &.normal {
    font-size: 1.1rem;
    margin-top: 25px;
    margin-bottom: 8px;
  }
`;

export const WrapStepStyled = styled.div`
  display: flex;
  justify-content: space-between;

  ${Media.lessThan(Media.SIZE.SM)} {
    justify-content: center;
  }
`;

export const StepItemStyled = styled.div`
  &.active {
    background-color: #ff6262;
  }

  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 105px;
  width: 105px;
  border-radius: 100%;
  background-color: #666666;
  color: #fff;
  font-size: 14px;

  &:not(:first-child) {
    position: relative;
    &::after {
      position: absolute;
      content: '>>>';
      font-size: 1.8rem;
      font-weight: 800;
      color: black;
      left: calc(0px - (88px / 2) - 12.5px);
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    display: none;
    font-size: 12px;

    &.active {
      display: flex;
    }

    &:not(:first-child) {
      &::after {
        display: none;
      }
    }
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  margin-top: 15px;
  border: 1px solid #f0f0f0;
  padding: 10px;
`;

export const WrapDivStyled = styled.div`
  text-align: center;
  margin-top: 15px;

  &.btn-step2 {
    margin-top: 30px;
  }

  &.btn-step3 {
    margin-top: 30px;
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    .ant-btn {
      font-size: 18px;
    }
    &.btn-step2 {
      display: flex;
      margin-top: 30px;
      flex-direction: column;

      .ant-btn {
        font-size: 18px;
        &:last-child {
          margin-left: 0px;
        }
      }
    }
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  font-weight: bold;
  .ant-checkbox {
    top: 2px
  }
  span {
    font-size: 1.2rem;
  }
`;

export const ButtonStyled = styled(Button)`
  font-size: 1.4rem;
  background-color: black;
  color: #ffffff;
  border-radius: 50px;
  height: auto;
  padding: 5px 30px 8px;
  margin-bottom: 20px;
  ${Media.lessThan(Media.SIZE.MD)} {
    font-size: 1rem;
  }
  &:last-child {
    margin-left: 20px;
  }

  &:disabled {
    opacity: 0.9;
  }
`;

export const FormStep2Styled = styled.div`
  margin-top: 15px;
  width: 100%;

  .ant-form-item {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;

      label {
        font-size: 0.9rem;
        &::after {
          display: none;
        }
      }
    }
  }
`;

export const InputStyled = styled(Input)`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;

  &:hover {
    border-color: unset;
    box-shadow: none;
  }

  &.ant-input-disabled {
    color: black;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ErrorStyled = styled.div`
  color: red;
`;

export const SelectStyled = styled(Select)`
  &.ant-select {
    border-bottom: 1px solid black;
  }

  .ant-select-selector .ant-select-selection-item {
    color: black;
  }

  .ant-select-item-option-content {
    color: black;
  }

  .ant-select-dropdown {
    background-color: #fff !important;
    z-index: 999;

    .ant-select-item {
      &::before {
        display: none;
      }
    }
  }

  &.ant-select-disabled {
    background-color: #f5f5f5;
    border-color: #d9d9d9;

    .ant-select-selector {
      /* opacity: 0.35; */

      .ant-select-selection-item {
        color: black;
      }
    }
  }
`;

export const DescriptionStyled = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
  ${Media.lessThan(Media.SIZE.SM)} {
    font-size: 15px;
  }
  & > a {
    text-decoration: underline;
  }
`;

export const Step3WrapItemsStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8px;

  ${Media.lessThan(Media.SIZE.SM)} {
    flex-direction: column;
  }
`;

export const BlockSetting = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .block_remain_token {
    text-align: center;
    margin: 6px;
  }
  .block_setting {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    flex: 1;
    justify-content: center;
    ._quantity {
      font-size: 14px;
      margin-right: 10px;
    }
    .decrease_btn, .increase_btn {
      border-radius: 5px 0px 0px 5px;
      color: black;
      border: 1px solid rgba(0,0,0,.2);
      transition: 0.2s;
      &:hover {
        border: 1px solid rgba(0,0,0, .6);
      }
    }
    .increase_btn {
      border-radius: 0px 5px 5px 0px;
    }
    .block_input {
      position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-50%);
          width: 16px;
          height: 30px;
          background: #fff;
          z-index: 1;
        }
    }
    ._input_number {
      text-align: center;
      height: 32px;
      width: 200px;
      border: 1px solid rgba(0,0,0,.2);
      position: relative;

      &:focus {
        outline: none;
        border: 1px solid rgba(0,0,0, .6);
      }
      &::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 10px;
        background-color: red;
      }
    }
    ._quantity_available {
      margin-left: 20px;
      font-size: 14px;
    }
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    display: none;
  }
`
export const BlockSettingMobile = styled.div`
  display: none;
  flex: 1;
  flex-direction: column;
  .block_remain_token {
    text-align: center;
    margin: 10px;
  }
  .block_setting {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    flex: 1;
    justify-content: center;
    ._quantity {
      font-size: 14px;
      margin-right: 10px;
    }
    .decrease_btn, .increase_btn {
      border-radius: 5px 0px 0px 5px;
      color: black;
      border: 1px solid rgba(0,0,0,.2);
      transition: 0.2s;
      &:hover {
        border: 1px solid rgba(0,0,0, .6);
      }
    }
    .increase_btn {
      border-radius: 0px 5px 5px 0px;
    }
    .block_input {
      position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-50%);
          width: 16px;
          height: 30px;
          background: #fff;
          z-index: 1;
        }
    }
    ._input_number {
      text-align: center;
      height: 32px;
      width: 200px;
      border: 1px solid rgba(0,0,0,.2);
      position: relative;

      &:focus {
        outline: none;
        border: 1px solid rgba(0,0,0, .6);
      }
      &::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 10px;
        background-color: red;
      }
    }
    ._quantity_available {
      margin-left: 20px;
      font-size: 14px;
    }
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    display: flex;
  }
`

export const StepItemsStyled = styled.div`
  width: calc((100% - 75px) / 3);
  margin-bottom: 4px;

  ${Media.lessThan(Media.SIZE.SM)} {
    width: 100%;
  }
`;

export const ButtonSwapOnMoble = styled.div`
  display: none;
  margin-top: 15px;
  .ant-btn {
    background-color: black;
    border-radius: 30px;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    &:disabled {
      background: rgba(0, 0, 0, 0.7);
      &:hover {
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
      }
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    display: block;
    width: 100%;
  }
`

export const ItemImage = styled.div`
  /* height: 215px;
  width: 215px; */
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: relative;

  .block_price {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .currency_price {
      font-size: 20px;
      font-weight: bold;
    }
    .price_to_jpy {
      font-size: 16px;
    }
  }

  ${Media.lessThan(Media.SIZE.SM)} {
    margin: 0px auto;
  }
`;

export const ItemText = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
`;

export const ItemButton = styled.div`
  margin-top: 15px;

  .ant-btn {
    background-color: black;
    border-radius: 30px;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    font-size: 1.2rem;
    &:disabled {
      background: rgba(0, 0, 0, 0.7);
      &:hover {
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
      }
    }
  }
  ${Media.lessThan(Media.SIZE.SM)} {
    display: none;
  }
`;

export const Step1Styled = styled.div`
  .item-description {
    .ant-form-item-row {
      width: 100%;
    }
  }
`;
export const Step2Styled = styled.div``;
export const Step3Styled = styled.div``;

export const PolicyStyled = styled.div`
  border: 1px solid;
  padding: 15px;
  font-weight: bold;
  max-height: 320px;
  overflow-y: scroll;
  white-space: pre-line;
`;

export const StatusDiv = styled.div`
  color: #9d59d4;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
`