import moment from 'moment';
import numeral from 'numeral';

export default class Format {
  static FORMATS = {
    DATE: 'YYYY-MM-DD',
    DATE_TIME: 'YYYY-MM-DD HH:mm',
  };

  static numeric = number => (number || 0).toLocaleString(undefined, { minimumFractionDigits: 0 });

  static date = (date, format) => (date ? moment(date).format(format || 'YYYY-MM-DD') : '');

  static time = (time, format) => (time ? moment(time, 'HH:mm:ss').format(format || 'HH:mm') : '');

  static abridgeNumber = (number = 0) => {
    if (number > 999999999) return `${(number / 1000000000).toFixed(1)}b`;
    if (number > 999999) return `${(number / 1000000).toFixed(1)}m`;
    if (number > 999) return `${(number / 1000).toFixed(1)}k`;
    return number.toString();
  };

  static price = (value, numFixed) => {
    if (value - parseInt(value, 10) > 0) {
      return numeral(value).format(numFixed ? `${numFixed}` : '0,0.0000');
    }

    return numeral(value).format('0,0');
  };
}

export const ArrayCards = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'JOKER'];

export const formatDataCard = result => {
  const _ = require('lodash');
  const ArrayCardsType = ['SPADES', 'HEART', 'CLUBS', 'DIAMONDS'];

  const dataFormat = _.chain(result)
    .groupBy('cardsValue')
    .map((value, key) => ({
      cardsValue: key,
      listCard: value,
    }))
    .value();

  const resultData = dataFormat.sort((a, b) => ArrayCards.indexOf(a.cardsValue) - ArrayCards.indexOf(b.cardsValue));

  const resultDataFormat = resultData.map(dt => ({
    ...dt,
    listCard: dt.listCard.sort((a, b) => ArrayCardsType.indexOf(a.cardsType) - ArrayCardsType.indexOf(b.cardsType)),
  }));

  return resultDataFormat;
};

export const FormatStatusCard = (statusCard, statusProduct, productOwnerRole) => {
  let result = {
    status: '',
    color: 'white',
  };
  if (statusCard === 'SALE') {
    if (statusProduct === 'SALE' && productOwnerRole === 'ADMIN') {
      result.status = '';
    }
    if (statusProduct === 'NEW' && productOwnerRole === 'ADMIN') {
      result.status = 'COMING SOON';
    }
    if (statusProduct === 'SOLD') {
      result.status = 'SOLD OUT';
      result.color = 'yellow';
    }
    if (statusProduct === 'SALE' && productOwnerRole === 'USER') {
      result.status = 'RESALE';
    }
  } else {
    if (statusProduct === 'SALE' && productOwnerRole === 'USER') {
      result.status = '';
    }
    if (statusProduct === 'SOLD') {
      result.status = 'UNLISTED';
      result.color = 'yellow';
    }
    if (statusProduct === 'SALE' && productOwnerRole === 'ADMIN') {
      result.status = 'IN STORE NOW';
    }
    if (statusProduct === 'NEW' && productOwnerRole === 'ADMIN') {
      result.status = 'COMING SOON';
    }
  }
  return result;
};

export const formatStatusRarityProduct = rarity => {
  let result = {
    status: 'N',
    value: 'NORMAL',
  };

  switch (rarity?.toLowerCase()) {
    case 'ur':
      result = {
        status: 'UR',
        value: 'ULTIMATE RARE',
      };
      break;

    case 'ssr':
      result = {
        status: 'SSR',
        value: 'SUPER SPECIAL RARE',
      };
      break;

    case 'sr':
      result = {
        status: 'SR',
        value: 'SUPER RARE',
      };
      break;
    case 'r':
      result = {
        status: 'R',
        value: 'RARE ',
      };
      break;
    default:
      result = {
        status: 'N',
        value: 'NORMAL',
      };
  }
  return result;
};
