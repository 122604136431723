import { MainApi } from './endpoint'

export function getOffers({ productId, ...payload }) {
  return MainApi.get(`/user/product/offer/${productId}`, payload)
}

export function registerOffer(payload) {
  return MainApi.post('/user/purchase/product/register-offer', payload)
}

export function createOffer({ productId, offerId, ...payload }) {
  return MainApi.put(`/user/purchase/product/${productId}/offer/${offerId}`, payload)
}

export function registerOfferFlag({ productId, ...payload }) {
  return MainApi.put(`/user/product/offer/register-offer-flag/${productId}`, payload)
}

export function resellOfferFlag({ productId, ...payload }) {
  return MainApi.put(`/user/product/resell-offer-flag/${productId}`, payload)
}

export function getOfferInfos({ productId, offerId, ...payload }) {
  return MainApi.put(`/user/purchase/product/${productId}/offer-information/${offerId}`, payload)
}

export function confirmOffer(payload) {
  return MainApi.put('/user/purchase/product/confirm-offer', payload)
}

export function cancelOffer({ productId, offerId, ...payload }) {
  return MainApi.put(`/user/purchase/product/${productId}/cancel-offer/${offerId}`, payload)
}


export function approvelOffer({ productId, ...payload }) {
  return MainApi.put(`/user/product/resell-offer-flag/${productId}`, payload)
}

export function updateOffer(payload) {
  return MainApi.post('/user/purchase/product/update-offer', payload)
}

export function cancelUpdateOffer(payload) {
  return MainApi.post('/user/purchase/product/cancel-update-offer', payload)
}

export function registerOffer1155(payload) {
  return MainApi.post('/user/purchase/product/register-offer', payload)
}

export function createOffer1155({ productId, offerId, ...payload }) {
  return MainApi.put(`/user/purchase/product/${productId}/offer-1155/${offerId}`, payload)
}

export function getOffers1155({ productId, ...payload }) {
  return MainApi.get(`/user/product/offer/erc1155/${productId}`, payload)
}

export function confirmOffer1155(payload) {
  return MainApi.put('/user/purchase/product/erc1155/confirm-offer', payload)
}

export function updateOffer1155(payload) {
  return MainApi.post('/user/purchase/product/erc1155/update-offer', payload)
}

export function offerPriceAvg({ productId, ...payload }) {
  return MainApi.get(`/user/product/offer/avg-price/${productId}`, payload)
}

