import Web3 from '@/utils/web3other'
import Confirmable from '@/components/confirmable'
import CURRENCIES from '@/constants/currencies'
import Format from '@/utils/format'
import MaskLoading from '@/components/mask-loading'

const onCheckNetwork = async () => {
  const isNetworkValid = await Web3.checkValidNetwork()

  if (!isNetworkValid) {
    throw new Error('NETWORK_INCORRECT')
  }
}

const onCheckPrice = async (product, productsStore, t, _onCheckoutAfterValidate) => {
  const getProductPriceResult = await productsStore.getProductPrice({ productId: product.id })
  

  if (!getProductPriceResult.success || !getProductPriceResult.data) throw new Error('ERROR_GET_PRODUCT_PRICE_NULL')

  if (product.price !== getProductPriceResult.data.price || product.currency !== getProductPriceResult.data.currency) {

    await productsStore.setDataProductDetails({
      price: getProductPriceResult.data.price,
      yenPrice: getProductPriceResult.data.yenPrice,
      currency: getProductPriceResult.data.currency,
    })

    MaskLoading.close()

    const ok = await Confirmable.open({
      content: <div dangerouslySetInnerHTML={{
        __html: t('product_details:change_price_message_eth', {
          currentPrice: Format.price(product.price),
          changedPrice: Format.price(getProductPriceResult.data.price),
          currentCurrency: product.currency,
          changeCurrency: getProductPriceResult.data.currency,
        })
      }}
      />,
      otherFunctionHandle: async () => {
        await _onCheckoutAfterValidate(getProductPriceResult.data)
      }
    })

    if (!ok) throw new Error('CANCELED')
    if ( ok) throw new Error('ERROR_RETRY_PURCHASE')
    // if ( ok ) {
    //   await productsStore.setDataProductDetails({
    //     price: getProductPriceResult.data.price,
    //     yenPrice: getProductPriceResult.data.yenPrice,
    //     currency: getProductPriceResult.data.currency,
    //   })
    //   return false
    // }
  }

  return true
}

const onCheckBalance = async (product, authStore, amount) => {
  const { weth, eth, usdt } = await Web3.getBalance(authStore.initialData.publicAddress)
  let isEnoughBalance = true

  if (product.currency === CURRENCIES.ETH) {
    if (product.price * amount > weth) isEnoughBalance = false
  } else if (product.currency === CURRENCIES.USDT) {
    if (product.price > usdt) isEnoughBalance = false
  }

  if (!isEnoughBalance) {
    throw new Error('BALANCE_NOT_ENOUGH')
  }

  return { weth, eth, usdt }
}

const onSign = async (authStore) => {
  const userNonceResult = await authStore.getUserNonce(authStore.initialData.userId)

  if (!userNonceResult.success) {
    throw userNonceResult.data
  }

  const signature = await Web3.sign(
    userNonceResult.data.nonce,
    authStore.initialData.publicAddress
  )

  return signature
}

const onSignByUser = async () => {
  const web3Info = await Web3?.getWeb3Instance();

  const web3s = web3Info?.web3;
  const accounts = await web3s?.eth?.getAccounts();

  const userAddress = '0x0ef303a549722d0DDe364c430512E10C907cD510';
  const seller = '0xbf1354C655C33819e9fcd0b90AEd3E6F2B830077';
  const tokenPay = '0xE06fBf0047390fBe9e3849B5995BaD65CbEA938C';
  const fee = '0x0000000000000000000000000000000000000000';
  const feeAdmin = '0x0000000000000000000000000000000000000000';

  const nonce = "5369"

  // const amountPay = "0.003"
  const amountBuy = "1"
  const tokenId = "6"
  const feePercent = "0"
  const feePercentAdmin = "0"

  const msgHash = await web3s.utils.soliditySha3(
    {
      type: 'address',
      value: userAddress
    },
    {
      type: 'uint256',
      value: +nonce,
    },
    {
      type: 'address[5]',
      value: [userAddress, seller, tokenPay, fee, feeAdmin],
    },
    {
      type: 'uint256[5]',
      value: [+3000000000000000, +amountBuy, +tokenId, +feePercent, +feePercentAdmin]
    }
  );

  // console.log('msghash', msgHash);
  const signature = await web3s.eth.personal.sign(
    msgHash,
    accounts[0],
    ''
  )
  // console.log("signature", signature);
  return signature;
};

const onTransfer = async (publicAddress, t, data, gasLimit, currency, price) => {
  const transactionHash = await Web3.excuteTransaction(
    publicAddress,
    t,
    data,
    gasLimit,
    currency,
    price
  )

  return transactionHash
}

const onCheckApproveMax = async (t, authStore, product) => {
  const publicAddress = authStore.initialData.publicAddress
  const data = await Web3.approveMax(publicAddress, t, product.currency)
  if (data) return true
  return false
}

const onError = (e, t, callBack) => {
  let errorMessage = t('messages.transaction_failed', {ns: 'product_details'})
  
  if (e?.message === 'NETWORK_INCORRECT') {
    errorMessage = t('messages.network_incorrect', {ns: 'product_details'})
  } else if (e?.message === 'BALANCE_NOT_ENOUGH') {
    errorMessage = t('messages.balance_not_enough', {ns: 'product_details'})
  } else if (e?.message === 'PRODUCT_HOLDING') {
    errorMessage = (
      <>
        {t('messages.product_holding_1', {ns: 'product_details'})}
        <br /><br />
        {t('messages.product_holding_2', {ns: 'product_details'})}
      </>
    )
  } else if (e?.message === 'CANNOT_CHECKOUT') {
    errorMessage = (
      <>
        {t('messages.cannot_checkout_1', {ns: 'product_details'})}
        <br /><br />
        {t('messages.cannot_checkout_2', {ns: 'product_details'})}
      </>
    )
  } else if (
    e?.message === 'OUT_OF_WALLET'
    || ['ERROR_MASTER_PUBLIC_KEY_NOT_FOUND', 'ERROR_MASTER_PUBLIC_KEY_IS_USING']
      .includes(e.error)
  ) {
    errorMessage = (
      <>
        {t('messages.out_of_wallet_1', {ns: 'product_details'})}
        <br />
        {t('messages.out_of_wallet_2', {ns: 'product_details'})}
      </>
    )
  } else if (e.message === 'ERROR_PRODUCT_NOT_FOUND') {
    errorMessage = (
      <>
        {t('messages.error_product_not_found_1', {ns: 'product_details'})}
        <br /><br />
        {t('messages.error_product_not_found_2', {ns: 'product_details'})}
      </>
    )
  } else if (e.message === 'ERROR_USER_CAN_BUY_ONE_PRODUCT') {
    errorMessage = (
      <>
        {t('messages.error_user_can_buy_one_product_1', {ns: 'product_details'})}
        <br />
        {t('messages.error_user_can_buy_one_product_2', {ns: 'product_details'})}
      </>
    )
  } else if (e.message === 'ERROR_USER_VERIFY_SIGNATURE_FAIL') {
    errorMessage = (
      <>
        {t('messages.error_user_verify_signature_fail_1', {ns: 'product_details'})}
        <br />
        {t('messages.error_user_verify_signature_fail_2', {ns: 'product_details'})}
      </>
    )
  } else if (e.message === 'ERROR_USER_NOT_OWN_TOKEN') {
    errorMessage = (
      <>
        {t('ERROR_USER_NOT_OWN_TOKEN', {ns: "error-messages"})}
      </>
    )
  } else if (e.message === 'ERROR_GET_PRODUCT_PRICE_NULL') {
    errorMessage = (
      <>
        {t('ERROR_GET_PRODUCT_PRICE_NULL', {ns: "error-messages"})}
      </>
    )
  } else if ( e.message === 'ERROR_RETRY_PURCHASE' ) {
      <>
        {t('ERROR_RETRY_PURCHASE', {ns: "error-messages"})}
      </>
  } else if (e.message === 'CANCELED') {
    Confirmable.open({
      content: errorMessage,
      hideCancelButton: true,
      otherFunctionHandle: function () {
        callBack()
      }
    })
  }

  if (e.message !== 'CANCELED') {
    Confirmable.open({
      content: errorMessage,
      hideCancelButton: true,
      otherFunctionHandle: function () {
      }
    })
  }
}


export {
  onCheckApproveMax,
  onTransfer,
  onSign,
  onError,
  onCheckNetwork,
  onCheckPrice,
  onCheckBalance,
  onSignByUser
}