import React from 'react';
import {
  ButtonStyled,
  ErrorStyled,
  FormStep2Styled,
  InputStyled,
  SelectStyled,
  Step2Styled,
  TopTextStyled,
  WrapDivStyled,
} from './styled';
import { Form } from 'antd';

export default function Step2({ formik, handleSubmit, auth }) {
  
  const [loading, setLoading ] = React.useState(false)

  
  const userProducts = auth?.initialData?.userProducts || [];
  const prefectureList = auth?.prefecture || [];
  
  
  const { values, setFieldValue, errors, touched, setFieldTouched, isValid } = formik;

  const handleChangeFormItem = async (fieldName, fieldValue, callback) => {
    if (!!callback) {
      const result = await callback(formik.values);
      if (!result) return
    }
    setFieldValue(fieldName, fieldValue ?? null);
  };

  const checkRegisterIsExist = async (params) => {
    setLoading(true)
    const { success } = await auth.registerMemberDraft({
      isDraft: true,
      ...params
    })
    if ( !success ) {
      setLoading(false)
      return false
    } else {
      setLoading(false)
      return true
    }
  }
  
  const isConfirmStep = values?.step === 3;

  return (
    <Step2Styled>
      {isConfirmStep ? (
        ''
      ) : (
        <TopTextStyled className="normal">楽座DAOのメンバー情報の登録をお願いします。</TopTextStyled>
      )}
      <FormStep2Styled>
        <Form.Item
        labelCol={{ span: 8 }}
          label="DAOメンバー名"
          validateStatus={errors?.nickname && touched?.nickname ? 'error' : ''}
          help={
            <ErrorStyled
              dangerouslySetInnerHTML={{ __html: touched?.nickname && errors?.nickname ? errors?.nickname : '' }}
            />
          }
          required
        >
          <InputStyled
            name="nickname"
            disabled={isConfirmStep}
            value={values?.nickname ?? ''}
            onChange={e => handleChangeFormItem('nickname', e.target.value)}
            onBlur={() => setFieldTouched('nickname', true)}
          />
        </Form.Item>

        <Form.Item
        labelCol={{ span: 8 }}
          label="メールアドレス"
          validateStatus={errors?.email && touched?.email ? 'error' : ''}
          help={
            <ErrorStyled
              dangerouslySetInnerHTML={{
                __html: touched?.email && errors?.email ? errors?.email : '',
              }}
            />
          }
          required
        >
          <InputStyled
            name="email"
            disabled={isConfirmStep}
            value={values?.email ?? ''}
            onChange={e => {
              handleChangeFormItem('email', e.target.value)
            }}
            onBlur={() => setFieldTouched('email', true)}
          />
        </Form.Item>

        <Form.Item
        labelCol={{ span: 8 }}
          label="お電話番号"
          validateStatus={errors?.phoneNumber && touched?.phoneNumber ? 'error' : ''}
          help={
            <ErrorStyled
              dangerouslySetInnerHTML={{
                __html: touched?.phoneNumber && errors?.phoneNumber ? errors?.phoneNumber : '',
              }}
            />
          }
          required
        >
          <InputStyled
            name="phoneNumber"
            disabled={isConfirmStep}
            value={values?.phoneNumber ?? ''}
            maxLength={15}
            onChange={e => handleChangeFormItem('phoneNumber', e.target.value)}
            onBlur={() => setFieldTouched('phoneNumber', true)}
            onKeyDown={e => {
              if (
                [
                  '0',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '+',
                  'Backspace',
                  'Enter',
                  'ArrowLeft',
                  'ArrowRight',
                ].includes(e.key) ||
                (['v', 'c', 'x', 'z', 'a'].includes(e.key) && (e.ctrlKey || e.metaKey))
              ) {
                return e;
              }

              e.preventDefault();
            }}
          />
        </Form.Item>

        <Form.Item
        labelCol={{ span: 8 }}
          label="ご年齢"
          validateStatus={errors?.age && touched?.age ? 'error' : ''}
          help={
            <ErrorStyled
              dangerouslySetInnerHTML={{
                __html: touched?.age && errors?.age ? errors?.age : '',
              }}
            />
          }
          required
        >
          <InputStyled
            name="age"
            disabled={isConfirmStep}
            type="number"
            value={values?.age ?? ''}
            onChange={e => handleChangeFormItem('age', e.target.value)}
            onBlur={() => setFieldTouched('age', true)}
            onKeyDown={e => {
              if (
                [
                  '0',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  'Backspace',
                  'Enter',
                  'ArrowLeft',
                  'ArrowRight',
                ].includes(e.key) ||
                (['v', 'c', 'x', 'z', 'a'].includes(e.key) && (e.ctrlKey || e.metaKey))
              ) {
                return e;
              }

              e.preventDefault();
            }}
          />
        </Form.Item>
        <Form.Item
        labelCol={{ span: 8 }}
          label="お住まいの都道府県"
          validateStatus={errors?.prefectureId && touched?.prefectureId ? 'error' : ''}
          help={
            <ErrorStyled
              dangerouslySetInnerHTML={{
                __html: touched?.prefectureId && errors?.prefectureId ? errors?.prefectureId : '',
              }}
            />
          }
          required
        >
          <SelectStyled
            name="prefectureId"
            disabled={isConfirmStep}
            bordered={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            allowClear
            options={
              prefectureList?.length
                ? prefectureList?.map(value => ({
                    value: value?.id ?? '',
                    label: value?.name ?? '',
                  }))
                : []
            }
            onChange={val => handleChangeFormItem('prefectureId', val)}
            onBlur={() => setFieldTouched('prefectureId', true)}
            value={values?.prefectureId ?? ''}
          />
        </Form.Item>
      </FormStep2Styled>

      {isConfirmStep ? (
        <TopTextStyled className='normal'>登録情報に間違いがなければ、メンバーシップNFT購入に進んでください。</TopTextStyled>
      ) : (
        ''
      )}

      <WrapDivStyled className="btn-step2">
        {values?.step > 1 ? (
          <ButtonStyled onClick={() => handleChangeFormItem('step', values?.step - 1)}>ひとつ戻る</ButtonStyled>
        ) : (
          ''
        )}
        <ButtonStyled
          disabled={!isValid && values?.step === 2}
          onClick={() => {
            if (!!userProducts?.length && values?.step === 3) {
              handleSubmit();
            } else {
              handleChangeFormItem('step', values?.step + 1, checkRegisterIsExist);
            }
          }}
          loading={loading}
        >
          {values?.step === 2 ? '登録情報の確認へ' : 'メンバーシップNFT購入へ'}
        </ButtonStyled>
      </WrapDivStyled>
    </Step2Styled>
  );
}
