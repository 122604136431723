import React from 'react';
import { Web3AuthProvider } from './contexts/web3auth/web3auth';
import { ModalVerifyEmailProvider } from './components/modal-verify-email';

function Providers({ children }) {
  return (
    <ModalVerifyEmailProvider>
      <Web3AuthProvider>{children}</Web3AuthProvider>
    </ModalVerifyEmailProvider>
  );
}

export default Providers;
