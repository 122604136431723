import { Model } from "../utils/mobx-model-helper"
import { getIdentity, registerIdentity } from "../api/identities"

const TYPES = {
  GET_IDENTITY: 1,
  REGISTER_IDENTITY: 2,
}

const IdentityStore = Model.named("IdentityStore").actions((self) => ({
  getIdentity(payload) {
    return self.request({
      type: TYPES.GET_IDENTITY,
      api: getIdentity,
      payload,
    })
  },

  registerIdentity(payload) {
    return self.request({
      type: TYPES.REGISTER_IDENTITY,
      api: registerIdentity,
      payload,
    })
  },
}))

export { TYPES }
export default IdentityStore.create()

