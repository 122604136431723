import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import { fetchNews, fetchDetails, fetchCategories } from '../api/news';

const TYPES = {
  FETCH_NEWS: 1,
  GET_NEWS: 2,
  FETCH_CATEGORIES: 3,
};

const CategoryItem = types.model('CategoryItem').props({
  categoryId: types.maybeNull(types.number),
  categoryName: types.maybeNull(types.string),
});

const NewsItem = types.model('NewsItem').props({
  bannerUrl: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  publicDate: types.maybeNull(types.number),
  readTime: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  thumbnailUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  newsCategoryDTO: types.maybeNull(types.array(CategoryItem)),
});

const NewsDetailLanguage = types.model('NewsDetail').props({
  content: types.maybeNull(types.string),
  langKey: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

const NewsDetail = types.model('NewsDetail').props({
  bannerUrl: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  publicDate: types.maybeNull(types.number),
  readTime: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  thumbnailUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  newsMultiLanguageDTOs: types.array(NewsDetailLanguage),
  newsCategoryDTO: types.maybeNull(types.array(CategoryItem)),
  slides: types.maybeNull(types.array(types.string)),
});

const NewsList = types.model('NewsList').props({
  result: types.array(NewsItem),
  page: types.number,
  pages: types.number,
  limit: types.number,
  total: types.number,
});

const Category = types.model('NewsList').props({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

const NewsStore = Model.named('NewsStore')
  .props({
    categories: types.array(Category),
    news: NewsList,
    newsMain1: types.maybeNull(NewsItem),
    newsMain2: types.maybeNull(NewsItem),
    newsDetail: types.maybeNull(NewsDetail),
  })
  .actions(self => ({
    fetchNews(payload) {
      let isHaveLimit = false;
      if (payload?.limit) {
        isHaveLimit = true;
        Object.assign(payload, { limit: payload?.limit });
      } else {
        Object.assign(payload, { limit: 14 });
      }

      return self.request({
        type: TYPES.FETCH_NEWS,
        api: fetchNews,
        payload,
        onSuccess: result => {
          if (!isHaveLimit) {
            const [item0, item1, ...items] = result?.result;

            self.newsMain1 = item0 || null;
            self.newsMain2 = item1 || null;

            self.news.result = items || [];
          } else {
            self.news.result = result?.result || [];
          }

          self.news.page = result?.page;
          self.news.pages = result?.pages;
          self.news.limit = result?.limit;
          self.news.total = result?.total;
        },
      });
    },

    fetchCategories(payload) {
      return self.request({
        type: TYPES.FETCH_CATEGORIES,
        api: fetchCategories,
        payload,
        onSuccess: result => {
          self.categories = result?.result;
        },
      });
    },

    fetchDetails(payload) {
      let oldNews = self.newsDetail;
      self.newsDetail = null;

      return self
        .request({
          type: TYPES.GET_NEWS,
          api: fetchDetails,
          payload,
          onSuccess: result => {
            self.newsDetail = result;
          },
        })
        .catch(error => {
          self.newsDetail = oldNews;
        });
    },
  }));

export { TYPES };
export default NewsStore.create({
  categories: [],
  news: {
    result: [],
    page: 0,
    pages: 0,
    limit: 0,
    total: 0,
  },
  newsMain1: null,
  newsMain2: null,
  newsDetail: null,
});
