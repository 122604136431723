import { MainApi } from './endpoint'

export async function getProductIdRemain(payload) {
  return MainApi.get('/user/product', payload)
}

export async function getProductErc1155Details(payload) {
  return MainApi.get(`/user/product/${payload.id}`, payload)
}

export function getListProductOwner1155(payload) {
  return MainApi.get('/user/product/owned', payload)
}
