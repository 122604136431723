import { MainApi } from './endpoint'

export function registerSell({ productId, ...payload }) {
  return MainApi.post(`/user/product/register-resell/${productId}`, payload)
}

export function sell({ productId, productResellId, ...payload }) {
  return MainApi.put(`/user/product/${productId}/resell/${productResellId}`, payload)
}

export function cancelSell({ productId, ...payload }) {
  return MainApi.put(`/user/product/${productId}/cancel-resell`, payload)
}

export function sell1155({ productId, productResellId, ...payload }) {
  return MainApi.put(`/user/product/${productId}/resell-1155/${productResellId}`, payload)
}

export function getSellLisings({ productId, ...payload }) {
  return MainApi.get(`/user/product/erc1155/resell/${productId}`, payload)
}

export function registerSell1155({ productId, ...payload }) {
  return MainApi.post(`/user/product/register-resell-1155/${productId}`, payload)
}

export function cancelSell1155({ productId, ...payload }) {
  return MainApi.put(`/user/product/${productId}/erc1155/cancel-resell`, payload)
}